import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../api/axios";
import endPoints from "../../api/endpoints";
import { decryption } from "../../Encrypt";
import { refreshToken } from "./refrshToken";

const initialState = { state: "check" };

/**Api for displaying claim details */
export const claimIndexDetail = createAsyncThunk(
  "claimIndexDe",
  async ({ setIsLoading, payload }, thunkApi) => {
    let encode = encodeURIComponent(payload?.claim_number);
    const response = axiosInstance
      .get(endPoints.indexDetails + "?claim_number=" + encode)
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          setIsLoading(false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
        if (response?.data?.code !== 200) {
          setIsLoading(false);
          return thunkApi.fulfillWithValue(response?.data?.response);
        } else {
          let decrypt = await decryption(response?.data?.response);
          let parsedData = JSON.parse(decrypt);
          setIsLoading(false);
          return thunkApi.fulfillWithValue(parsedData);
        }
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          setIsLoading(false);
          // localStorage.removeItem("accessToken");
          // localStorage.removeItem("refreshToken");
          // localStorage.removeItem("idToken");
          // localStorage.removeItem("expiresIn");
          // sessionStorage.clear();
          // window.location = "/";
          thunkApi.dispatch(refreshToken());
        }
        setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

const indexDetialSlice = createSlice({
  name: "indexDetialSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(claimIndexDetail.pending, (state, action) => {
    });
    builder.addCase(claimIndexDetail.fulfilled, (state, action) => {
      state.claimsDetails = action.payload;
    });
    builder.addCase(claimIndexDetail.rejected, (state, action) => {
    });
  },
});

export default indexDetialSlice.reducer;
