import React, { useState } from "react";
import "./MemberResource/MemberResource.scss";
import "./AccountSetting/AccountSetting.scss";
import ChangePassword from "./ChangePassword/ChangePassword";

const PasswordChange = ({ setTabData }) => {
  const [openPassword, setOpenPassword] = useState(true);

  return (
    <div className="member-resource-wrap account-setting">
      <div className="resource-head">
        <button
          className="back-btn"
          onClick={() => setTabData(false)}
          type="button"
        >
          <i className="icon-resouce"></i>
        </button>
        Change Password
      </div>
      {/* {openPassword ? ( */}
      {/* <AccountSettingForm setOpenPassword={setOpenPassword} /> */}
      {/* ) : ( */}
      <ChangePassword setOpenPassword={setOpenPassword} />
      {/* )} */}
    </div>
  );
};

export default PasswordChange;
