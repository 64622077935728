import React, { useEffect, useState } from "react";
import "./ContactUs.scss";
import Footer from "../../components/Footer/Footer";
import { encryptionObj } from "../../Encrypt";
import { useDispatch, useSelector } from "react-redux";
import { contactUs } from "../../store/slices/profile";
import { ThreeDots } from "react-loader-spinner";

const ContactUs = () => {
  const profileView = useSelector((state) => state.profileSlice?.profileInfo);

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorCorrection, setEmailErrorCorrection] = useState(false);

  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState(false);

  const [contactMsg, setContactMsg] = useState(false);

  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch();

  /** Functions for displaying information of input fields */
  useEffect(() => {
    if(profileView){
      setName(profileView?.first_name + " " + profileView?.last_name)
      setEmail(profileView?.email);
    }
  }, [profileView]);

  const handleName = (e) => {
    setName(e.target.value);
    setNameError(false);
  }

  const handleEmail = (e) => {
    setEmail(e.target.value.toLowerCase());
    setEmailError(false);
    setEmailErrorCorrection(false);
  };

  const handleMessage = (e) => {
    setMessage(e.target.value);
    setMessageError(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (name?.length === 0) {
      setNameError(true);
      return;
    } else if (email?.length === 0) {
      setEmailError(true);
      return;
    } else if (!emailRegex.test(email)) {
      setEmailErrorCorrection(true);
      return;
    } else if (message?.length === 0) {
      setMessageError(true);
      return;
    }

    const data = {
      name: name,
      email: email,
      message: message,
    };

    const finalData = await encryptionObj(data);
    setIsLoading(true)
    dispatch(
      contactUs({
        payload: { data: finalData },
        setContactMsg,
        setEmail,
        setMessage,
        setIsLoading,
        setName
      })
    );
  };

  return (
    <>
      {
        isLoading && <ThreeDots
        visible={true}
        height="80"
        width="80"
        color="#240151"
        wrapperClass="loading-spin"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
      />
      }
      <div className="member-screen cms-page contact-us-page">
        <div className="header-banner">
          <div className="container">
            <h1>Contact Us</h1>
          </div>
        </div>
        <div className="card-headings">
          <div className="container">
            <span className="back-heading">
              <i className="card-back-icon"></i>
            </span>
            <span className="card-head-text">Contact Us</span>
          </div>
        </div>
        <div className="container">
          <div className="member-content-card">
            <form onSubmit={handleSubmit}>
              <div className="signin-wrp">
                <div className="signin-form-wrp">
                  <h1>Connect with us</h1>
                  <p className="need-assit">Need assistance? Please reach out and send message here for any technical related questions. For benefits related questions please call the phone number on the back of your ID Card.</p>
                  <div className="form-wrap">
                    <div className="form-block">
                      <label htmlFor="name" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        placeholder="Name"
                        className="form-control contact-us-mail"
                        name="name"
                        onChange={handleName}
                        value={name}
                      />
                      {nameError && <p className="error">Please enter name</p>}
                    </div>
                    <div className="form-block">
                      <label htmlFor="email" className="form-label">
                        Email
                      </label>
                      <input
                        type="text"
                        placeholder="Email"
                        className="form-control contact-us-mail"
                        name="email"
                        onChange={handleEmail}
                        value={email}
                      />
                      {emailError && <p className="error">Please enter Email</p>}
                      {emailErrorCorrection && (
                        <p className="error">Please enter valid Email</p>
                      )}
                    </div>
                    <div className="form-block">
                      <label htmlFor="message" className="form-label">
                        Message
                      </label>
                      <div className="form-control contact-us-textarea">
                        <textarea
                          maxLength={500}
                          onChange={handleMessage}
                          value={message}
                          name="message"
                          placeholder="Type your message"
                        ></textarea>
                      </div>
                      {messageError && (
                        <p className="error">Please enter message</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bottom-wrp submit-btn contact-us-btn">
                  <button className="btn">
                    Submit
                    <i className="icon-arrow">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 33 33"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.9231 7.9575L20.0341 7.8288C20.4416 7.42147 21.0791 7.38449 21.5283 7.71786L21.657 7.82897L29.3017 15.4753C29.7088 15.8825 29.746 16.5195 29.4131 16.9687L29.3021 17.0974L21.6574 24.7527C21.2096 25.2011 20.483 25.2016 20.0346 24.7537C19.627 24.3466 19.5895 23.7092 19.9224 23.2597L20.0335 23.1309L25.7108 17.4438L4.78518 17.4452C4.20426 17.4452 3.72415 17.0136 3.64818 16.4534L3.6377 16.2977C3.6377 15.7169 4.06939 15.2368 4.62947 15.1608L4.78518 15.1502L25.7307 15.1488L20.034 9.4516C19.6267 9.04417 19.5897 8.40669 19.9231 7.9575Z"
                          fill="white"
                        />
                      </svg>
                    </i>
                  </button>
                </div>
                {contactMsg && (
                  <div className="thank-contact">
                    <h1>
                      Thank you for submission, Tres Health will revert back to you soon.
                    </h1>
                  </div>
                )}
              </div>
            </form>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
