import React from "react";
import { useSelector } from "react-redux";
import ClaimCard from "../ClaimCard/ClaimCard";
import ClaimExpand from "../ClaimExpand/ClaimExpand";
import "./ClaimIndex.scss";

const ClaimIndex = ({ setCliamExpand, claimExpand }) => {
  const claimesList = useSelector((state) => state.homepageSlice?.claims);

  return (
    <div className="claim-index-tab">
      <div className="claim-card-box">
        <ClaimCard setCliamExpand={setCliamExpand} claimExpand={claimExpand} />
      </div>
    </div>
  );
};

export default ClaimIndex;
