import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance, axiosInstanceAuth2 } from "../../api/axios";
import endPoints from "../../api/endpoints";
import { encryption, decryption } from "../../Encrypt";
import { notify } from "../../NotifyToast";
import { refreshToken } from "./refrshToken";

const initialState = { state: "check" };

/** API for send reset password link after forgot password */
export const resetLink = createAsyncThunk(
  "resetLink",
  async (payload, thunkApi) => {
    const response = axiosInstanceAuth2
      .post(endPoints.passLink + "?encryptedEmailId=" + payload)
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          notify.errorToast(response?.data?.response);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
          //   setIsLoading(false);
        }
        if (response?.data?.code !== 200) {
          notify.errorToast(response?.data?.response);
        } else {
          notify.successToast(response?.data?.response);
          return thunkApi.fulfillWithValue(response?.data);
        }
      })
      .catch((error) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          notify.errorToast(response?.data?.response);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
        notify.errorToast("Oops! something went wrong");
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

/**Api for reseting password from reset password link */
export const passReset = createAsyncThunk(
  "passReset",
  async ({ payload, setIsLoading }, thunkApi) => {
    const response = axiosInstanceAuth2
      .post(endPoints.pass_reset, payload)
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          setIsLoading(false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
        if (response?.data?.code !== 200) {
          setIsLoading(false);
          notify.errorToast(response?.data?.response);
        } else {
          setIsLoading(false);
          // setTimeout(() => (window.location = "/"), 3000);
          notify.successToast(response?.data?.response);
          return thunkApi.fulfillWithValue(response?.data);
        }
      })
      .catch((error) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          setIsLoading(false);
          localStorage.removeItem("customer_token");
          notify.errorToast(error?.data?.resonse);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
        setIsLoading(false);
        notify.errorToast("Oops! something went wrong");
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

/**Api for checking reset password link is valid or not */
export const checkLink = createAsyncThunk(
  "checkLinks",
  async ({ payload, setIsLoading }, thunkApi) => {
    let encode = encodeURIComponent(payload?.id);
    const response = axiosInstanceAuth2
      .get(
        endPoints.linkCheck +
          "?encryptedEmailId=" +
          encode +
          "&key=" +
          payload?.key
      )
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          setIsLoading(false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
        if (response?.data?.code !== 200) {
          setIsLoading(false);
          notify.errorToast(response?.data?.response);
          return thunkApi.fulfillWithValue(response?.data);
        } else {
          setIsLoading(false);
          return thunkApi.fulfillWithValue(response?.data);
        }
      })
      .catch((error) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          setIsLoading(false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
        setIsLoading(false);
        notify.errorToast("Oops! something went wrong");
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

/** API for change password from profile */
export const updatePass = createAsyncThunk(
  "passUpdate",
  async ({ payload, setOpenPassword, setIsLoading, logoutUser, isToggled }, thunkApi) => {
    const response = axiosInstanceAuth2
      .post(endPoints.changePass, payload)
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          setIsLoading(false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
        if (response?.data?.code !== 200) {
          setIsLoading(false);
          notify.errorToast(response?.data?.response);
        } else {
          setOpenPassword(true);
          setIsLoading(false);
          notify.successToast(response?.data?.response);
          if(isToggled){
            logoutUser()
          }
          return thunkApi.fulfillWithValue(response?.data);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          notify.errorToast(error?.data?.resonse);
          // localStorage.removeItem("accessToken");
          // localStorage.removeItem("refreshToken");
          // localStorage.removeItem("idToken");
          // localStorage.removeItem("expiresIn");
          // sessionStorage.clear();
          // window.location = "/";
          thunkApi.dispatch(refreshToken());
        }
        setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

const forgotPasswordSlice = createSlice({
  name: "forgotPasswordSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(checkLink.pending, (state, action) => {
    });
    builder.addCase(checkLink.fulfilled, (state, action) => {
      state.chckLink = action.payload;
    });
    builder.addCase(checkLink.rejected, (state, action) => {
    });
  },
});

export default forgotPasswordSlice.reducer;
