import React, { useEffect, useState } from "react";
import "./StaffTable.scss";
import ReactPaginate from "react-paginate";
import { benefitsList, benefitsSearch } from "../../store/slices/benefits";
import { useDispatch, useSelector } from "react-redux";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import { ThreeDots } from "react-loader-spinner";
import { encryption } from "../../Encrypt";

const StaffTable = ({ search, searchState, setApiPage, itemsPerPage, setItemsPerPage}) => {
  const dispatch = useDispatch();
  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  const [itemOffset, setItemOffset] = useState(0);
  const benefits = useSelector((state) => state.benefitSlice?.listBenefit);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [endOffset, setEndOffset] = useState(itemOffset + itemsPerPage);
  const [currentItems, setCurrentItems] = useState(
    items.slice(itemOffset, endOffset)
  );
  const [pageCount, setPageCount] = useState(
    Math.ceil(benefits?.totalRecords / itemsPerPage)
  );

  const result = useSelector((state) => state.benefitSlice?.searchResult);

  /** Function for displaying benefits list */
  useEffect(() => {
    setIsLoading(true);
    let payload = {
      pageNumber: 0,
      recordsPerPage: itemsPerPage,
    };
    dispatch(benefitsList({ payload, setIsLoading }));
  }, []);

  /** Function for handling pagination */
  useEffect(() => {
    setEndOffset(itemOffset + itemsPerPage);
    setCurrentItems(items.slice(itemOffset, endOffset));
    if(searchState){
      setPageCount(Math.ceil(result?.totalRecords / itemsPerPage));
    }
    else{
      setPageCount(Math.ceil(benefits?.totalRecords / itemsPerPage));
    }
  }, [itemsPerPage, benefits, search, result]);

  useEffect(()=>{
    setCurrentPage(0)
    setApiPage(0)
  },[search])

  /** Invoke when user click to request another page */
  const handlePageClick = async (event) => {
    setCurrentPage(event.selected);
    const newOffset = (event.selected * itemsPerPage) % benefits?.totalRecords;
    setIsLoading(true);
    setApiPage(event.selected);
    if(searchState){
      let data = {
        product_label: search,
      };
      let payload = await encryption(data?.product_label);
      dispatch(benefitsSearch({ payload, setIsLoading,apiPage: event.selected, recordsPerPage: itemsPerPage }));
    }
    else{
      let payload = {
        pageNumber: event.selected,
        recordsPerPage: itemsPerPage,
      };
      dispatch(benefitsList({ payload, setIsLoading }));
    }
    setItemOffset(newOffset);
  };

  /** Function for change page number */
  const pageChange = async (e) => {
    setItemsPerPage(e.target.value);
    setIsLoading(true);
    setApiPage(0);
    setCurrentPage(0)
    if(searchState){
      let data = {
        product_label: search,
      };
      let payload = await encryption(data?.product_label);
      dispatch(benefitsSearch({ payload, setIsLoading,apiPage: 0, recordsPerPage: e.target.value}));
    }
    else{
      let payload = {
        pageNumber: 0,
        recordsPerPage: e.target.value,
      };
      dispatch(benefitsList({ payload, setIsLoading }));
    }
  };


  return (
    <>
      {/* {isLoading ? <LoadingScreen /> : null} */}
      {searchState === false ? (
        <div className="staff-table">
          <div className="table">
            <div className="data main-head">
              <div className="value head serial-no">
                Product <span className="sub-text">Label</span>
              </div>
              <div className="value head name">Therapeutic Class</div>
              {/* <div className="value head email">Formulary</div> */}
              <div className="value head action">Tier</div>
            </div>
            <div className="value-wrp">
              {benefits?.parsedData?.map((item, index) => (
                <div className="data" key={index}>
                  <div className="value serial-no">{item?.product_label}</div>
                  <div className="value name">{item?.therapeutic_class}</div>
                  {/* <div className="value email">{item?.formulary_tier}</div> */}
                  <div className="value action">{item?.adv3t_tier}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="pagintion">
            {benefits?.totalRecords && benefits?.totalRecords > 10 ? (
              <div className="entry-wrp">
                <span className="entry-text">show entries</span>
                <div className="select-wrap">
                  <select value={itemsPerPage} onChange={(e) => pageChange(e)}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </select>
                  <i className="icon-arrow"></i>
                </div>
              </div>
            ) : null}
            {benefits?.totalRecords && benefits?.totalRecords > 10 ? (
              <ReactPaginate
                breakLabel="..."
                nextLabel=""
                onPageChange={handlePageClick}
                marginPagesDisplayed={1}
                pageRangeDisplayed={window.innerWidth<767 ? 1 : 2}
                pageCount={pageCount}
                previousLabel=""
                renderOnZeroPageCount={null}
                className="pagination-list"
                forcePage={currentPage}
              />
            ) : null}
          </div>
        </div>
      ) : (
        <div className="staff-table">
          <div className="table">
            <div className="data main-head">
              <div className="value head serial-no">
                Product <span className="sub-text">Label</span>
              </div>
              <div className="value head name">Therapeutic Class</div>
              {/* <div className="value head email">Formulary</div> */}
              <div className="value head action">Tier</div>
            </div>
            <div className="value-wrp">
              {result?.parsedData?.length > 0 ? (
                result?.parsedData?.map((item, index) => (
                  <div className="data" key={index}>
                    <div className="value serial-no">{item?.product_label}</div>
                    <div className="value name">{item?.therapeutic_class}</div>
                    {/* <div className="value email">{item?.formulary_tier}</div> */}
                    <div className="value action">{item?.adv3t_tier}</div>
                  </div>
                ))
              ) : (
                <h2>No data found</h2>
              )}
            </div>
          </div>
          <div className="pagintion">
            {result?.totalRecords && result?.totalRecords > 10 ? (
              <div className="entry-wrp">
                <span className="entry-text">show entries</span>
                <div className="select-wrap">
                  <select value={itemsPerPage} onChange={(e) => pageChange(e)}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </select>
                  <i className="icon-arrow"></i>
                </div>
              </div>
            ) : null}
            {result?.totalRecords && result?.totalRecords > 10 ? (
              <ReactPaginate
                breakLabel="..."
                nextLabel=""
                onPageChange={handlePageClick}
                marginPagesDisplayed={1}
                pageRangeDisplayed={window.innerWidth<767 ? 1 : 2}
                pageCount={pageCount}
                previousLabel=""
                renderOnZeroPageCount={null}
                className="pagination-list"
                forcePage={currentPage}
              />
            ) : null}
          </div>
        </div>
      )}
    </>
  );
};

export default StaffTable;
