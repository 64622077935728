import React, { useState, useEffect } from "react";
import "./NetworkSummary.scss";
import OutNetwork from "../OutNetwork/OutNetwork";
import InNetwork from "../InNetwork/InNetwork";
import { useDispatch, useSelector } from "react-redux";
import { benefitId, cardId } from "../../../store/slices/card";
import { ThreeDots } from "react-loader-spinner";
import Footer from "../../Footer/Footer";
import { Link } from "react-router-dom";
import { pdfFile } from "../../../store/slices/pdf";

const NetworkSummary = () => {
  const network = useSelector((state) => state.homepageSlice?.planInfo);
  const [inNet, setInNet] = useState(true);
  const [outNet, setOutNet] = useState(window.innerWidth<767 ? false:true);
  const dispatch = useDispatch();
  const cardDetails = useSelector((state) => state.cardSlice?.benefitCard);
  const pdfDetails = useSelector((state) => state.pdfSlice?.filesPdf);
  const [isLoading, setIsLoading] = useState(false);

  const handleInnet = () => {
    setInNet(true);
    setOutNet(false);
  };

  const handleOutnet = () => {
    setInNet(false);
    setOutNet(true);
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(benefitId(setIsLoading));
    dispatch(pdfFile(setIsLoading));
  }, []);


  return (
    <>
      {isLoading ? (
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#240151"
          wrapperClass="loading-spin"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          // wrapperClass=""
        />
      ) : (
        <div className="network-summary-page">
          <div className="network-summary">
            <div className="header-banner">
              <div className="container">
                <h1>Benefits Summary</h1>
              </div>
            </div>
            <div className="card-headings">
              <div className="container">
                <span className="back-heading" onClick={()=>window.history.back()}>
                  <i className="card-back-icon"></i>
                </span>
                <span className="card-head-text">Benefits</span>
              </div>
            </div>
            <div className="network-content">
              <div className="container">
                <div className="network-card">
                  <div className="network-content-wrp">
                    <div className="benifits-wrap">
                      <h3>Prescription Benefits</h3>
                      <div className="member-content">
                        <div className="head-wrp first-head">
                          <h3>
                            <i className="icon-sign">
                              <svg
                                width="9"
                                height="11"
                                viewBox="0 0 9 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  id="Vector"
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M0.361328 8.91861V8.04386C0.931354 7.8554 0.917796 7.8554 0.944912 7.69389L0.972086 1.85272C0.972086 1.5028 0.917796 1.4893 0.456351 1.39507V0.520264H3.67284C4.97571 0.520264 5.60003 0.574102 6.18361 0.950971C6.84866 1.38168 7.18791 2.12192 7.18791 2.79487C7.18791 3.95237 6.5093 4.51764 5.13858 4.98869C5.6407 5.39245 5.77646 5.54057 6.19717 6.06546L7.05215 4.9618L7.85296 5.56746L6.8351 6.95365C7.33722 7.78812 7.54077 7.94969 8.16509 8.03036V8.91873H6.18367C5.99362 8.91873 5.96901 8.89963 5.8463 8.79755C5.67893 8.66603 5.69574 8.66603 5.58647 8.58225L4.10717 10.5203L3.30642 9.92798L4.93498 7.69383C4.555 6.83241 4.25643 6.29409 3.70002 5.63458C3.36533 5.22895 3.2114 5.16359 2.83142 5.16359V7.38436C2.83142 7.88229 2.84498 7.97652 3.38783 8.00347V8.91861H0.361328ZM5.12668 2.83684C5.12668 1.80047 4.36661 1.55822 2.83302 1.55822V4.10196C3.07735 4.11541 3.30802 4.11541 3.45733 4.11541C4.54309 4.11546 5.12668 3.64436 5.12668 2.83684Z"
                                  fill="#281651"
                                />
                              </svg>
                            </i>
                            PCN
                          </h3>
                          <p>
                            {cardDetails?.prescription_benefits_details?.rx_pcn}
                          </p>
                        </div>
                        <div className="head-wrp">
                          <h3>
                            <i className="icon-sign">
                              <svg
                                width="9"
                                height="11"
                                viewBox="0 0 9 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  id="Vector"
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M0.361328 8.91861V8.04386C0.931354 7.8554 0.917796 7.8554 0.944912 7.69389L0.972086 1.85272C0.972086 1.5028 0.917796 1.4893 0.456351 1.39507V0.520264H3.67284C4.97571 0.520264 5.60003 0.574102 6.18361 0.950971C6.84866 1.38168 7.18791 2.12192 7.18791 2.79487C7.18791 3.95237 6.5093 4.51764 5.13858 4.98869C5.6407 5.39245 5.77646 5.54057 6.19717 6.06546L7.05215 4.9618L7.85296 5.56746L6.8351 6.95365C7.33722 7.78812 7.54077 7.94969 8.16509 8.03036V8.91873H6.18367C5.99362 8.91873 5.96901 8.89963 5.8463 8.79755C5.67893 8.66603 5.69574 8.66603 5.58647 8.58225L4.10717 10.5203L3.30642 9.92798L4.93498 7.69383C4.555 6.83241 4.25643 6.29409 3.70002 5.63458C3.36533 5.22895 3.2114 5.16359 2.83142 5.16359V7.38436C2.83142 7.88229 2.84498 7.97652 3.38783 8.00347V8.91861H0.361328ZM5.12668 2.83684C5.12668 1.80047 4.36661 1.55822 2.83302 1.55822V4.10196C3.07735 4.11541 3.30802 4.11541 3.45733 4.11541C4.54309 4.11546 5.12668 3.64436 5.12668 2.83684Z"
                                  fill="#281651"
                                />
                              </svg>
                            </i>
                            BIN
                          </h3>
                          <p>
                            {cardDetails?.prescription_benefits_details?.rx_bin}
                          </p>
                        </div>
                        <div className="head-wrp last-head">
                          <h3>
                            <i className="icon-sign">
                              <svg
                                width="9"
                                height="11"
                                viewBox="0 0 9 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  id="Vector"
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M0.361328 8.91861V8.04386C0.931354 7.8554 0.917796 7.8554 0.944912 7.69389L0.972086 1.85272C0.972086 1.5028 0.917796 1.4893 0.456351 1.39507V0.520264H3.67284C4.97571 0.520264 5.60003 0.574102 6.18361 0.950971C6.84866 1.38168 7.18791 2.12192 7.18791 2.79487C7.18791 3.95237 6.5093 4.51764 5.13858 4.98869C5.6407 5.39245 5.77646 5.54057 6.19717 6.06546L7.05215 4.9618L7.85296 5.56746L6.8351 6.95365C7.33722 7.78812 7.54077 7.94969 8.16509 8.03036V8.91873H6.18367C5.99362 8.91873 5.96901 8.89963 5.8463 8.79755C5.67893 8.66603 5.69574 8.66603 5.58647 8.58225L4.10717 10.5203L3.30642 9.92798L4.93498 7.69383C4.555 6.83241 4.25643 6.29409 3.70002 5.63458C3.36533 5.22895 3.2114 5.16359 2.83142 5.16359V7.38436C2.83142 7.88229 2.84498 7.97652 3.38783 8.00347V8.91861H0.361328ZM5.12668 2.83684C5.12668 1.80047 4.36661 1.55822 2.83302 1.55822V4.10196C3.07735 4.11541 3.30802 4.11541 3.45733 4.11541C4.54309 4.11546 5.12668 3.64436 5.12668 2.83684Z"
                                  fill="#281651"
                                />
                              </svg>
                            </i>
                            Group
                          </h3>
                          <p>
                            {
                              cardDetails?.prescription_benefits_details
                                ?.rx_group
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="benifits-wrap health-benefits-wrapper">
                      <h3>Health Benefits Document</h3>
                      <div className="member-content">
                        <div className="head-wrp first-head">
                        {
                          pdfDetails?.documents?.length>0 ? pdfDetails?.documents?.map((item, index)=>{
                            return <a
                            key={index}
                            href={item?.file_url}
                            to="#"
                            className="download-wrap"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="pdf-icon"></i>
                            <span className="benefit-text">
                              {item?.file_name}
                            </span>
                            <i className="arrow-icon"></i>
                          </a>
                          }):<p>No documents</p>
                        }
                        </div>
                      </div>
                    </div>
                    <div className="summary-wrap">
                      <div className="data-network">
                        <h3>Benefits Summary</h3>
                        <div className="tab-wrp">
                          <div className="tab-btn-wrap">
                            <button
                              type="button"
                              className={`btn in-network-btn ${
                                inNet ? "active" : ""
                              }`}
                              onClick={handleInnet}
                            >
                              In Network
                            </button>
                            <button
                              type="button"
                              className={`btn out-network-btn ${
                                outNet ? "active" : ""
                              }`}
                              onClick={handleOutnet}
                            >
                              Out-of-Networks
                            </button>
                          </div>
                          {inNet ? (
                            <div className="innetwork-wrap">
                              <InNetwork />
                            </div>
                          ) : null}
                          {outNet ? (
                            <div className="outnetwork-wrap">
                              <OutNetwork />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="network-providers">
                      <h3>Provider Networks</h3>
                      <div className="provider-block">
                        {
                          cardDetails?.provider_networks_details?.map((item, index)=>{
                            return item?.network_url ? <Link to={item?.network_url} target={"_blank"} className="logo-wrp" key={index}>
                            <div className="logo-body">
                              <img
                                src={
                                  item?.image_url
                                }
                                alt="logo"
                              ></img>
                            </div>
                            <div className="logo-text">
                              {
                                item?.networks_name
                              }
                            </div>
                          </Link>:
                          <div className="logo-wrp" key={index}>
                          <div className="logo-body">
                            <img
                              src={
                                item?.image_url
                              }
                              alt="logo"
                            ></img>
                          </div>
                          <div className="logo-text">
                            {
                              item?.networks_name
                            }
                          </div>
                        </div>
                          })
                        }
                        {/* <div className="logo-wrp">
                          <div className="logo-body">
                            <img
                              src={
                                cardDetails?.provider_networks_details?.[1]
                                  ?.image_url
                              }
                            ></img>
                          </div>
                          <div className="logo-text">PHCS</div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                  <Footer />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default NetworkSummary;
