import React from "react";
import "./ClaimIndex.scss";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";

const ClaimIndex = () => {
  const claims = useSelector((state) => state.homepageSlice?.claims);
  const navigate = useNavigate();

  let dates = claims?.[0]?.service_date;
  let date2 = new Date(dates);
  const date = date2;
  const month = date.toLocaleString("default", { month: "long" });
  const day = date.getDate();

  /** Function for navigating on claims list page */
  const handleClaim = (e) => {
    e.preventDefault();
    navigate("/claims");
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2
        }
      },
    ]
  };

  return (
    <div className="claim-index rtl-block">
      <div className="claim-heading-wrap">
        <h3>Latest Claims</h3>
        {claims?.length > 0 ? (
          <a
            onClick={handleClaim}
            style={{ cursor: "pointer" }}
            className="all-claims"
          >
            See All Claims
          </a>
        ) : null}
      </div>
      <div className="claims-blocks">
        {claims?.length > 0 ? (
          <Slider {...settings}>
          {claims?.map(
            (item, index) =>
              index < 6 && (
                <Link
                  to="/claim-detail"
                  className="claim-body"
                  key={index}
                  state={{ id: item?.claim_number }}
                >
                  <div className="claim-wrp">
                    <h4>{item?.service_date}</h4>
                    <div className="claim-name">
                      <h3>
                        {item?.rendering_provider_last_name_or_org_name
                          ? item?.rendering_provider_last_name_or_org_name
                          : "N/A"}
                      </h3>

                      {/* <span
                    className="payment-status"
                    style={{
                      color: item?.claim_colour,
                      borderColor: item?.claim_colour,
                    }}
                  >
                    {item?.claim_status}
                  </span> */}
                    </div>
                    <div className="claim-detail">
                      <h3>Claim Number : {item?.claim_number}</h3>
                    </div>
                    <div className="payment-wrap">
                      <span
                        className="payment-status"
                        style={{ background: item?.claim_colour }}
                      >
                        {item?.claim_status}
                      </span>
                      <span className="payment-value">
                        {/* {item?.member_responsibility !== 0 */}
                        {item?.member_responsibility}
                        {/* : item?.member_responsibility} */}
                      </span>
                    </div>
                  </div>
                </Link>
              )
          )}
        </Slider>
        ) : (
          <span className="no-data-nw">No data found</span>
        )}
      </div>
    </div>
  );
};

export default ClaimIndex;
