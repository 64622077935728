import axios from "axios";

let url = process.env.REACT_APP_API_URL;
// const baseURL = process.env.REACT_APP_API_URL + process.env.REACT_APP_PREFIX;
// const baseURLAuth = process.env.REACT_APP_API_URL + process.env.REACT_APP_PREFIX_AUTH;
export const axiosInstanceKey = axios.create({
  baseURL: url+"/",
});

export const axiosInstance = axios.create({
  baseURL: url + process.env.REACT_APP_PREFIX,
});

export const axiosInstanceAuth = axios.create({
  baseURL: url + process.env.REACT_APP_PREFIX_AUTH,
});

export const axiosInstanceAuth2 = axios.create({
  baseURL: url + process.env.REACT_APP_PREFIX_AUTH2,
});

export const axiosInstanceRefresh = axios.create({
  baseURL: url,
});

export const axiosInstanceDelete = axios.create({
  baseURL: url,
});

export const axiosInstanceLogout = axios.create({
  baseURL: url,
});

const clientId = process.env.REACT_APP_CLIENT_ID;

axiosInstanceKey.interceptors.request.use(
  function (config) {
    if (localStorage.getItem("new_token")) {
      const token =
        typeof window !== "undefined"
          ? localStorage.getItem("new_token")
          : null;
      if (token) {
        config.headers.Authorization = ` Bearer ${token}`;
        config.headers.channel = `CUSTOMER`;
      }
    } else {
      const token =
        typeof window !== "undefined"
          ? localStorage.getItem("accessToken")
          : null;
      if (token) {
        config.headers.Authorization = ` Bearer ${token}`;
        config.headers.channel = `CUSTOMER`;
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(
  function (config) {
    if (localStorage.getItem("new_token")) {
      const token =
        typeof window !== "undefined"
          ? localStorage.getItem("new_token")
          : null;
      if (token) {
        config.headers.Authorization = ` Bearer ${token}`;
        config.headers.channel = "CUSTOMER";
      }
    } else {
      const token =
        typeof window !== "undefined"
          ? localStorage.getItem("accessToken")
          : null;
      if (token) {
        config.headers.Authorization = ` Bearer ${token}`;
        config.headers.channel = "CUSTOMER";
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstanceAuth.interceptors.request.use(
  function (config) {
    if (localStorage.getItem("new_token")) {
      const token =
        typeof window !== "undefined"
          ? localStorage.getItem("new_token")
          : null;
      if (token) {
        config.headers.Authorization = ` Bearer ${token}`;
        config.headers.channel = "CUSTOMER";
      } else {
        config.headers.Authorization = null;
        config.headers.channel = "CUSTOMER";
      }
    } else {
      const token =
        typeof window !== "undefined"
          ? localStorage.getItem("accessToken")
          : null;
      if (token) {
        config.headers.Authorization = ` Bearer ${token}`;
        config.headers.channel = "CUSTOMER";
      } else {
        config.headers.Authorization = null;
        config.headers.channel = "CUSTOMER";
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstanceAuth2.interceptors.request.use(
  function (config) {
    if (localStorage.getItem("new_token")) {
      const token =
        typeof window !== "undefined"
          ? localStorage.getItem("new_token")
          : null;
      if (token) {
        config.headers.Authorization = ` Bearer ${token}`;
        config.headers.channel = "CUSTOMER";
        config.headers.clientId = clientId;
      } else {
        config.headers.Authorization = null;
        config.headers.channel = "CUSTOMER";
        config.headers.clientId = clientId;
      }
    } else {
      const token =
        typeof window !== "undefined"
          ? localStorage.getItem("accessToken")
          : null;
      if (token) {
        config.headers.Authorization = ` Bearer ${token}`;
        config.headers.channel = "CUSTOMER";
        config.headers.clientId = clientId;
      } else {
        config.headers.Authorization = null;
        config.headers.channel = "CUSTOMER";
        config.headers.clientId = clientId;
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstanceRefresh.interceptors.request.use(
  function (config) {
    const token =
      typeof window !== "undefined"
        ? localStorage.getItem("refreshToken")
        : null;
    if (token) {
      config.headers.refreshToken = token;
      config.headers.channel = "CUSTOMER";
      config.headers.ClientId = clientId;
      config.headers.session = localStorage.getItem("sessionID");
    } else {
      config.headers.refreshToken = null;
      config.headers.channel = "CUSTOMER";
      config.headers.ClientId = clientId;
      config.headers.session = localStorage.getItem("sessionID");
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstanceLogout.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstanceDelete.interceptors.request.use(
  function (config) {
    if (localStorage.getItem("new_token")) {
      const token =
        typeof window !== "undefined"
          ? localStorage.getItem("new_token")
          : null;
      if (token) {
        config.headers.Authorization = ` Bearer ${token}`;
        config.headers.channel = "CUSTOMER";
        config.headers.ClientId = clientId;
      } else {
        config.headers.Authorization = null;
        config.headers.channel = "CUSTOMER";
        config.headers.ClientId = clientId;
      }
    } else {
      const token =
        typeof window !== "undefined"
          ? localStorage.getItem("accessToken")
          : null;
      if (token) {
        config.headers.Authorization = ` Bearer ${token}`;
        config.headers.channel = "CUSTOMER";
        config.headers.ClientId = clientId;
      } else {
        config.headers.Authorization = null;
        config.headers.channel = "CUSTOMER";
        config.headers.ClientId = clientId;
      }
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;
