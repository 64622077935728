import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../api/axios";
import endPoints from "../../api/endpoints";
import { decryption } from "../../Encrypt";
import { notify } from "../../NotifyToast";
import { refreshToken } from "./refrshToken";

const initialState = { state: "check" };

/**Api for displaying non-archived messages list */
export const messageList = createAsyncThunk(
  "message",
  async ({ setIsLoading, payload }, thunkApi) => {
    const response = axiosInstance
      .get(
        endPoints.msgs +
          "?pageNumber=" +
          payload?.pageNumber +
          "&recordsPerPage=" +
          payload?.recordsPerPage
      )
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          setIsLoading(false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
        let decrypt = await decryption(response?.data?.response);
        let parsedData = JSON.parse(decrypt);
        if (response?.data?.code === 200) {
          setIsLoading(false);
          return thunkApi.fulfillWithValue(parsedData);
        }
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          setIsLoading(false);
          // localStorage.removeItem("accessToken");
          // localStorage.removeItem("refreshToken");
          // localStorage.removeItem("idToken");
          // localStorage.removeItem("expiresIn");
          // sessionStorage.clear();
          // window.location = "/";
          thunkApi.dispatch(refreshToken());
        }
        setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

/**API for showing archived message list */
export const messageArchList = createAsyncThunk(
  "messageArch",
  async ({ setIsLoading, payload }, thunkApi) => {
    const response = axiosInstance
      .get(
        endPoints.msgsArch +
          "?pageNumber=" +
          payload?.pageNumber +
          "&recordsPerPage=" +
          payload?.recordsPerPage
      )
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          setIsLoading(false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
        let decrypt = await decryption(response?.data?.response);
        let parsedData = JSON.parse(decrypt);
        if (response?.data?.code === 200) {
          setIsLoading(false);
          return thunkApi.fulfillWithValue(parsedData);
        }
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          setIsLoading(false);
          // localStorage.removeItem("accessToken");
          // localStorage.removeItem("refreshToken");
          // localStorage.removeItem("idToken");
          // localStorage.removeItem("expiresIn");
          // sessionStorage.clear();
          // window.location = "/";
          thunkApi.dispatch(refreshToken());
        }
        setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

/**Api for opening a single message info */
export const openMessage = createAsyncThunk(
  "messageOpen",
  async ({ payload, setIsLoading }, thunkApi) => {
    const response = axiosInstance
      .get(endPoints.msgOpen, {
        params: {
          message_id: payload,
        },
      })
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          setIsLoading(false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
        let decrypt = await decryption(response?.data?.response);
        let parsedData = JSON.parse(decrypt);
        if (response?.data?.code === 200) {
          setIsLoading(false);
          return thunkApi.fulfillWithValue(parsedData);
        }
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          setIsLoading(false);
          // localStorage.removeItem("accessToken");
          // localStorage.removeItem("refreshToken");
          // localStorage.removeItem("idToken");
          // localStorage.removeItem("expiresIn");
          // sessionStorage.clear();
          // window.location = "/";
          thunkApi.dispatch(refreshToken());
        }
        setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

/**Api for marking message archive / non-archive */
export const archiveMsg = createAsyncThunk(
  "messageArc",
  async ({ payload, setIsLoading, navigate }, thunkApi) => {
    let encode = encodeURIComponent(payload?.id);
    const response = axiosInstance
      .put(
        endPoints.arcMsg +
          "?message_id=" +
          encode +
          "&archive_message=" +
          payload?.toggles
      )
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          setIsLoading(false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
        if (response?.data?.code === 200) {
          setIsLoading(false);
          notify.successToast(payload?.toggles==="true" ? "Message archived successfully" : "Message un-archived successfully");
          navigate("/messages");
          return thunkApi.fulfillWithValue(response?.data?.response);
        }
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          setIsLoading(false);
          // localStorage.removeItem("accessToken");
          // localStorage.removeItem("refreshToken");
          // localStorage.removeItem("idToken");
          // localStorage.removeItem("expiresIn");
          // sessionStorage.clear();
          // window.location = "/";
          thunkApi.dispatch(refreshToken());
        }
        setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

/**Api for message search */
export const searchMsg = createAsyncThunk(
  "msgSearch",
  async ({ payload, setIsSearch }, thunkApi) => {
    const response = axiosInstance
      .get(endPoints.searchMessage, {
        params: payload,
      })
      .then(async (response) => {
        if (response?.data?.code !== 200) {
          return thunkApi.fulfillWithValue(response?.data);
        } else {
          let decrypt = await decryption(response?.data?.response);
          let parsedData = JSON.parse(decrypt);
          // setIsSearch(true);
          return thunkApi.fulfillWithValue(parsedData);
        }
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          // setIsLoading(false);
          // localStorage.removeItem("accessToken");
          // localStorage.removeItem("refreshToken");
          // localStorage.removeItem("idToken");
          // localStorage.removeItem("expiresIn");
          // sessionStorage.clear();
          // window.location = "/";
          thunkApi.dispatch(refreshToken());
        }
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

/**Api to change the message status as read */
export const messageStatus = createAsyncThunk(
  "messageRead",
  async (payload, thunkApi) => {
    let id = encodeURIComponent(payload?.message_id);
    const response = axiosInstance
      .put(
        endPoints.msgRead +
          "?message_id=" +
          id +
          "&read_status=" +
          payload?.read_status
      )
      .then(async (response) => {
        if (response?.data?.code !== 200) {
          return thunkApi.fulfillWithValue(response?.data);
        } else {
          let decrypt = await decryption(response?.data?.response);
          let parsedData = JSON.parse(decrypt);
          return thunkApi.fulfillWithValue(parsedData);
        }
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          // setIsLoading(false);
          // localStorage.removeItem("accessToken");
          // localStorage.removeItem("refreshToken");
          // localStorage.removeItem("idToken");
          // localStorage.removeItem("expiresIn");
          // sessionStorage.clear();
          // window.location = "/";
          thunkApi.dispatch(refreshToken());
        }
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

const messageSlice = createSlice({
  name: "cardSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(messageList.pending, (state, action) => {
    });
    builder.addCase(messageList.fulfilled, (state, action) => {
      state.messages = action.payload;
    });
    builder.addCase(messageList.rejected, (state, action) => {
    });
    builder.addCase(messageArchList.pending, (state, action) => {
    });
    builder.addCase(messageArchList.fulfilled, (state, action) => {
      state.messagesArch = action.payload;
    });
    builder.addCase(messageArchList.rejected, (state, action) => {
    });
    builder.addCase(openMessage.pending, (state, action) => {
    });
    builder.addCase(openMessage.fulfilled, (state, action) => {
      state.singleMsg = action.payload;
    });
    builder.addCase(openMessage.rejected, (state, action) => {
    });
    builder.addCase(searchMsg.pending, (state, action) => {
    });
    builder.addCase(searchMsg.fulfilled, (state, action) => {
      state.searchResult = action.payload;
    });
    builder.addCase(searchMsg.rejected, (state, action) => {
    });
  },
});

export default messageSlice.reducer;
