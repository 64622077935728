import React from "react";
import "./DependentsChild.scss";

const DependentsChild = ({dependent}) => {
  return (
    dependent?.length > 0 ? (
      <div>
        {dependent?.map((item, index) => (
          <div className="child-wrap">
          <table key={index}>
            <thead>
              <tr>
                <th>Name</th>
                <th>{item?.name}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Group Number</td>
                <td>{item?.group_number}</td>
              </tr>
              <tr>
                <td>Member ID</td>
                <td>{item?.member_number}</td>
              </tr>
              <tr>
                <td>Person</td>
                <td>{item?.person}</td>
              </tr>
              <tr>
                <td>Relationship</td>
                <td>{item?.relationship}</td>
              </tr>
              <tr>
                <td>Date of Birth</td>
                <td>{item?.date_of_birth}</td>
              </tr>
              <tr>
                <td>Sex</td>
                <td>{item?.gender}</td>
              </tr>
            </tbody>
          </table>
          </div>
        ))}
        </div>
      ) : (
        null
      )
  );
};

export default DependentsChild;
