import React, { useState, useEffect } from "react";
import "../MemberResource/MemberResource.scss";
import "./AccountSettingForm.scss";
import upload from "../../../assets/images/img-placeholder.svg";
import "../AccountSetting/AccountSetting.scss";
import { useDispatch, useSelector } from "react-redux";
import profile, {
  profilePic,
  deleteProflePic,
} from "../../../store/slices/profile";
import { ThreeCircles, ThreeDots } from "react-loader-spinner";
import { DeletePopUp } from "../../auth/Popup/DeletePopUp";
import { ConfirmDelete } from "../../auth/Popup/ConfirmDelete";

const AccountSettingForm = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const profileView = useSelector((state) => state.profileSlice?.profileInfo);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  // const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [sex, setSex] = useState("");
  const [dob, setDob] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [image, setImage] = useState(null);
  const [profileupdate, setProfileUpdate] = useState(false);
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  /** Functions for displaying information of input fields */
  useEffect(() => {
    if (profileView !== "string") {
      setFirstName(profileView?.first_name);
      setLastName(profileView?.last_name);
      setPhone(profileView?.phone_number);
      setEmail(profileView?.email);
      // setAge(profileView?.age);
      setSex(profileView?.sex);
      setDob(profileView?.dob);
    }
  }, [profileView]);

  let dates = new Date(startDate);
  let days = dates.getDate();
  let months = dates.getMonth();
  days = dates.getDate() < 10 ? "0" + dates.getDate() : dates.getDate();
  months =
    dates.getMonth() < 9 ? "0" + (dates.getMonth() + 1) : dates.getMonth() + 1;
  let newdate = months + "-" + days + "-" + dates.getFullYear();

  /** Function for uploading profile image */
  const handleImage = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      let payload = new FormData();
      payload.append("profile_pic", e.target.files[0]);
      setIsLoading(true);
      dispatch(profilePic({ payload, setIsLoading, setProfileUpdate }));
    }
  };

  /** Function for delete profile image */
  const deleteImage = () => {
    let payload = new FormData();
    payload.append("profile_pic", image);
    setIsLoading(true);
    dispatch(deleteProflePic({ payload, setIsLoading, setImage }));
  };

  const deleteAcc = (e) => {
    e.preventDefault();
    setDeleteAccount(true);
  };

  return (
    <>
      {deleteAccount && (
        <DeletePopUp
          setDeleteAccount={setDeleteAccount}
          setDeleteConfirm={setDeleteConfirm}
        />
      )}
      {deleteConfirm && (
        <ConfirmDelete
          setDeleteAccount={setDeleteAccount}
          setDeleteConfirm={setDeleteConfirm}
        />
      )}

      {isLoading ? (
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#240151"
          wrapperClass="loading-spin"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          // wrapperClass=""
        />
      ) : (
        <div className="resource-content account-resource-content">
          <div className="edit-img-upload">
            <div className="circle-wrp">
              <div className="upload-wrp">
                {profileView?.profile_pic_url !== null ? (
                  <div className="cross-wrp-icon" onClick={deleteImage}>
                    <i className="cross-icon" title="Remove image"></i>
                  </div>
                ) : (
                  <label className="camera-label" htmlFor="pic-upload">
                    <i className="upload-icon"></i> 
                  </label>
                )}
              </div>
              <div className="img-wrp">
                <img
                  src={
                    !image
                      ? profileView?.profile_pic_url
                        ? profileView?.profile_pic_url
                        : upload
                      : URL.createObjectURL(image)
                  }
                  alt="upload"
                />
                <input
                  onChange={handleImage}
                  type="file"
                  id="pic-upload"
                  title={
                    profileView?.profile_pic_url === null
                      ? "No file choosen"
                      : ""
                  }
                  accept="image/png, image/jpeg"
                  className="profile-input"
                />
              </div>
            </div>
            <label htmlFor="pic-upload" className="edit-image-text">
              <i className="edit-image-icon"></i>Edit Your Profile Picture
            </label>
          </div>
          <div className="form-main-block">
            <div className="account-wrap account-bottom-line">
              <form>
                <div className="signin-form-wrp">
                  <div className="form-wrap">
                    <div className="form-main-wrppr">
                      <div className="form-block-display form-check-wrp">
                        <div className="form-block">
                          <label className="form-label">First Name</label>
                          <input
                            disabled
                            type="text"
                            value={firstName}
                            class="form-control"
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </div>
                        <div className="form-block">
                          <label className="form-label">Last Name</label>
                          <input
                            disabled
                            type="text"
                            value={lastName}
                            class="form-control"
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                      </div>
                      {/* <div className="form-display-wrap form-check-wrp">
                        <div className="form-block">
                          <label className="form-label">Age</label>
                          <input
                            // value={age}
                            disabled
                            type="text"
                            maxlength="15"
                            class="form-control"
                            value="34 Years"
                          />
                        </div>
                        <div className="form-block select-wrp">
                          <label className="form-label">Sex</label>
                          <input
                            // value={sex}
                            disabled
                            type="text"
                            maxlength="15"
                            class="form-control"
                            value="Male"
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className="form-display-wrap form-check-wrp">
                      {/* <div className="form-block type-number">
                        <label className="form-label">Date of birth</label>
                        <input
                          disabled
                          type="email"
                          value={dob}
                          class="form-control"
                        />
                      </div> */}
                      <div className="form-block type-number">
                        <label className="form-label">Phone Number</label>
                        <input
                          disabled
                          type="number"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          maxlength="10"
                          class="form-control"
                        />
                      </div>
                      <div className="form-block type-number">
                        <label className="form-label">Email</label>
                        <input
                          disabled
                          type="email"
                          value={email}
                          class="form-control"
                        />
                      </div>
                    </div>
                    {/* <div className="form-display-wrap form-check-wrp">
                    </div> */}
                  </div>
                </div>
              </form>
            </div>
            <div className="btn-wrap">
              <button
                className="delete-acc-btn"
                type="submit"
                onClick={deleteAcc}
              >
                <i className="icon-delete"></i>
                Delete Account
                <i className="icon-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AccountSettingForm;
