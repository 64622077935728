import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  axiosInstanceAuth2,
  axiosInstanceAuth,
  axiosInstanceKey,
} from "../../api/axios";
import endPoints from "../../api/endpoints";
import { notify } from "../../NotifyToast";
import { decryption, encryption } from "../../Encrypt";

export const userLogin = createAsyncThunk(
  "login",
  async ({ payload, setIsLoading, isToggle }, thunkApi) => {
    // const redirect = payload.navigate;
    let x = await decryption(payload?.data);
    let mail = JSON.parse(x);
    return axiosInstanceAuth2
      .post(endPoints.login, payload)
      .then(async (response) => {
        if (response?.data?.code === 200) {
          localStorage.removeItem("signUpEmail");
          localStorage.removeItem("signPhone");
          let decryptEmail = await decryption(payload?.data);
          let mail2 = JSON.parse(decryptEmail);
          if (isToggle === true) {
            localStorage.setItem("save-email", await encryption(mail2?.email));
          }
          let decrypt = await decryption(response?.data?.response);
          let parsedData = JSON.parse(decrypt);
          if (parsedData?.access_token) {
            localStorage.setItem("customer_token", parsedData?.access_token);
            localStorage.setItem("refresh_token", parsedData?.refresh_token);
            notify.successToast("Logged in successfully");
            setIsLoading(false);
            window.location = "/homepage";
            return thunkApi.fulfillWithValue(parsedData?.access_token);
          }
          if (parsedData?.is_qr_scanned === false) {
            localStorage.setItem("isEmail", mail?.email);
            localStorage.setItem("is_qr_scanned", parsedData?.is_qr_scanned);
            localStorage.setItem("qr_code", parsedData?.qr_code);
            window.location = "/scan-qr";
            setIsLoading(false);
            return thunkApi.fulfillWithValue({
              qr_token: parsedData,
              is_qr_scanned: parsedData?.is_qr_scanned,
              data: payload,
              qr_code: parsedData?.qr_code,
            });
          } else if (parsedData?.is_qr_scanned === true) {
            localStorage.setItem("isEmail", mail?.email);
            window.location = "/security-code";
            setIsLoading(false);
            return thunkApi.fulfillWithValue({
              qr_token: parsedData,
              is_qr_scanned: parsedData?.is_qr_scanned,
              data: payload,
              qr_code: parsedData?.qr_code,
            });
          } else {
            notify.errorToast(parsedData);
            setIsLoading(false);
            return thunkApi.rejectWithValue({
              message: parsedData,
            });
          }
        } else {
          setIsLoading(false);
          notify.errorToast(response?.data?.response);
          return thunkApi.rejectWithValue({
            message: response?.data?.response,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
  }
);

export const qrScan = createAsyncThunk("scanQr", async (payload, thunkApi) => {
  const redirect = payload.navigate;
  return axiosInstanceAuth
    .put(endPoints.qr_scan, payload)
    .then((response) => {
      if (response?.data?.response === true) {
        notify.successToast("Scanned successfully");
        payload.setIsLoading(false);
        redirect("/security-code");
        localStorage.removeItem("qr_code");
        return thunkApi.fulfillWithValue(response?.data?.response);
      } else {
        // alert(response?.data?.response);
      }
    })
    .catch((error) => {
      payload.setIsLoading(false);
      return thunkApi.rejectWithValue(error);
    });
});

export const qrVerify = createAsyncThunk(
  "verify",
  async (payload, thunkApi) => {
    const redirect = payload.navigate;
    return axiosInstanceAuth
      .post(endPoints.verifyCode, payload)
      .then((response) => {
        if (response?.data?.response?.access_token) {
          localStorage.setItem("token", response?.data?.response?.access_token);
          notify.successToast("Logged in successfully");
          payload.setIsLoading(false);
          redirect("/homepage");
          return thunkApi.fulfillWithValue(
            response?.data?.response?.access_token
          );
        } else {
          payload.setIsLoading(false);
          notify.errorToast(response?.data?.response);
        }
      })
      .catch((error) => {
        payload.setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
  }
);

export const verifyToken = createAsyncThunk(
  "tokenVerify",
  async (payload, thunkApi) => {
    const redirect = payload.navigate;
    return axiosInstanceAuth
      .post(endPoints.token_verify, payload)
      .then((response) => {
        if (response?.data?.code === 200) {
          payload.setIsLoading(false);
          return thunkApi.fulfillWithValue({
            token: response?.data?.response,
          });
        } else {
          payload.setIsLoading(false);
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch((error) => {
        payload.setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
  }
);

export const keyClock = createAsyncThunk(
  "keyClk",
  async (setIsLoading, thunkApi) => {
    const response = axiosInstanceKey
      .post(endPoints.keyclk)
      .then(async (response) => {
        if (response?.status === 401 || response?.status === 403) {
          setIsLoading(false);
        }
        if (response?.status !== 200) {
          setIsLoading(false);
          notify.errorToast(response?.data?.response);
        } else {
          setIsLoading(false);
          // setTimeout(() => (window.location = "/"), 3000);
          window.location.href = response?.data;
          notify.successToast(response?.data?.response);
          return thunkApi.fulfillWithValue(response?.data);
        }
      })
      .catch((error) => {
        if (response?.status === 401 || response?.status === 403) {
          setIsLoading(false);
          localStorage.removeItem("accessToken");
          notify.errorToast(error?.data?.resonse);
        }
        setIsLoading(false);
        notify.errorToast("Oops! something went wrong");
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

const verify =
  typeof window !== "undefined"
    ? localStorage.getItem("customer_token")
      ? true
      : false
    : false;

const initialState = {
  verify,
  QR: JSON.parse(localStorage.getItem("is_qr_scanned")),
  qr_code: localStorage.getItem("qr_code"),
  email: localStorage.getItem("isEmail"),
};

const loginSlice = createSlice({
  name: "islogin",
  initialState,
  reducers: {
    logout: (state, action) => {
      state.islogin = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.pending, (state, action) => {
    });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.logged = action.payload;
      state.QR = action.payload.is_qr_scanned;
      state.qr_code = action.payload.qr_code;
      state.getEmail = action.payload.isEmail;

    });
    builder.addCase(userLogin.rejected, (state, action) => {
    });
    builder.addCase(qrScan.pending, (state, action) => {
    });
    builder.addCase(qrScan.fulfilled, (state, action) => {
      state.scanned = action.payload;
      delete state.QR;
      delete state.qr_code;
    });
    builder.addCase(qrScan.rejected, (state, action) => {
    });
    builder.addCase(qrVerify.pending, (state, action) => {
    });
    builder.addCase(qrVerify.fulfilled, (state, action) => {
      state.verify = action.payload;
    });
    builder.addCase(qrVerify.rejected, (state, action) => {
    });
  },
});

export const { logout } = loginSlice.actions;

export default loginSlice.reducer;
