import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import endPoints from "../../api/endpoints";
import { notify } from "../../NotifyToast";
import { decryption, decryptionObj } from "../../Encrypt";
import { axiosInstanceAuth2, axiosInstanceAuth } from "../../api/axios";

/**Function to register new provider */
export const userSignUp = createAsyncThunk(
  "signUp",
  async ({ payload, setIsLoading, navigate, finalData }, thunkApi) => {
    return axiosInstanceAuth2
      .post(endPoints.signUp, {data:finalData})
      .then(async (response) => {
        if (response?.data?.code === 200) {
          // let decrypt = await decryption(response?.data?.response);
          // let parsedData = JSON.parse(decrypt);
          // let payloadDecrypt = await decryption(payload?.data);
          // let newParsed = JSON.parse(payloadDecrypt);
          localStorage.setItem("signUpEmail", payload?.email);
          localStorage.setItem("signPhone", payload?.phone_number);
          setIsLoading(false);
          navigate("/verify-email");
          return thunkApi.fulfillWithValue({
            token: response?.data?.response,
          });
        } else {
          notify.errorToast(response?.data?.response);
          setIsLoading(false);
          return thunkApi.fulfillWithValue({ response });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        notify.errorToast(error?.response?.data?.response);
        return thunkApi.rejectWithValue(error);
      });
  }
);

/** Api for verifying mobile number with otp */
export const phoneVerify = createAsyncThunk(
  "numVerify",
  async ({ payload, setIsLoading, navigate, setIsDisabled }, thunkApi) => {
    return axiosInstanceAuth2
      .post(endPoints.verifyPhone, payload)
      .then(async (response) => {
        if (response?.data?.code === 200) {
          setIsLoading(false);
          navigate("/verify-email");
          return thunkApi.fulfillWithValue({
            token: response?.data?.response,
          });
        } else if (response?.data?.code === 400) {
          notify.errorToast(response?.data?.response);
          setIsLoading(false);
          setTimeout(() => setIsDisabled(false), 3000);
          return thunkApi.fulfillWithValue({
            token: response?.data?.response,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
  }
);

/** API for resent otp for phone number verify in case did not get the otp */
export const reVerifyPhone = createAsyncThunk(
  "reVerify",
  async ({ payload, setIsLoading }, thunkApi) => {
    let phone = encodeURIComponent(payload);
    return axiosInstanceAuth2
      .post(endPoints.resenMob + "?encryptedMobileNumber=" + phone)
      .then(async (response) => {
        if (response?.data?.code !== 200) {
          setIsLoading(false);
          return thunkApi.fulfillWithValue({
            token: response?.data?.response,
          });
        } else {
          setIsLoading(false);
          notify.successToast(response?.data?.response);
        }
        if (response?.data?.code === 400) {
          notify.errorToast(response?.data?.response);
          setIsLoading(false);
          return thunkApi.fulfillWithValue({
            token: response?.data?.response,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
  }
);

/** API for resent otp for email verify in case did not get the otp */
export const reVerifyEmail = createAsyncThunk(
  "reVerifyEmail",
  async ({ payload, setIsLoading, setTimer }, thunkApi) => {
    let phone = encodeURIComponent(payload);
    return axiosInstanceAuth
      .post(endPoints.resendEmail + "?encryptedEmailId=" + phone)
      .then(async (response) => {
        if (response?.data?.code !== 200) {
          setIsLoading(false);
          return thunkApi.fulfillWithValue({
            token: response?.data?.response,
          });
        } else {
          setIsLoading(false);
          setTimer(30)
          notify.successToast(response?.data?.response);
        }
        if (response?.data?.code === 400) {
          notify.errorToast(response?.data?.response);
          setIsLoading(false);
          return thunkApi.fulfillWithValue({
            token: response?.data?.response,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
  }
);

/** API for verify email with otp */
export const emailVerify = createAsyncThunk(
  "mailVerify",
  async ({ payload, setIsLoading, navigate, setIsDisabled }, thunkApi) => {
    return axiosInstanceAuth2
      .post(endPoints.verifyEmail, payload)
      .then(async (response) => {
        if (response?.data?.code === 200) {
          setIsLoading(false);
          navigate("/auth-insight");
          return thunkApi.fulfillWithValue({
            token: response?.data?.response,
          });
        } else if (response?.data?.code === 400) {
          notify.errorToast(response?.data?.response);
          setIsLoading(false);
          setTimeout(() => setIsDisabled(false), 3000);
          return thunkApi.fulfillWithValue({
            token: response?.data?.response,
          });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
  }
);

/** API for verifying SSN/DOB detail on Auth Insights page */
export const insightVerify = createAsyncThunk(
  "verifyInsight",
  async (
    { payload, setIsLoading, setOpenpopup, setOpenpopuploading, setFailPopup },
    thunkApi
  ) => {
    return axiosInstanceAuth2
      .post(endPoints.verifyInsight, payload)
      .then(async (response) => {
        if (response?.data?.code === 200) {
          // let payloadDecrypt = await decryption(response?.data?.response);
          // let newParsed = JSON.parse(payloadDecrypt);
          // localStorage.setItem(
          //   "accessToken",
          //   response?.data?.response?.access_token
          // );
          setOpenpopuploading(false);
          setIsLoading(false);
          setOpenpopup(true);
          setTimeout(() => setOpenpopup(false), 1500);
          setTimeout(() => (window.location = "/"), 1500);
          localStorage.removeItem("signUpEmail");
          localStorage.removeItem("signPhone");
          return thunkApi.fulfillWithValue({
            token: response?.data?.response,
          });
        } else {
          // notify.errorToast(response?.data?.response);
          setFailPopup(true);
          setOpenpopuploading(false);
          setIsLoading(false);
          setTimeout(() => setFailPopup(false), 3000);
          return thunkApi.fulfillWithValue(response?.data?.response);
        }
      })
      .catch((error) => {
        setFailPopup(true);
        setOpenpopuploading(false);
        setIsLoading(false);
        setTimeout(() => setFailPopup(false), 1500);
        return thunkApi.rejectWithValue(error);
      });
  }
);
/**Function to view provider profile */
export const userProfile = createAsyncThunk(
  "profileView",
  async (payload, thunkApi) => {
    const response = axiosInstanceAuth
      .get(endPoints.profile, payload)
      .then(async (response) => {
        if (
          response?.data?.response === "Invalid Access or Token Expired" ||
          response?.data?.response === "Missing Authorization Header" ||
          response?.data?.response === "Invalid Access" ||
          response?.data?.response === "Token Expired" ||
          response?.data?.response === "Please provide valid token" ||
          response?.data?.code === 401 ||
          response?.data?.code === 403
        ) {
          notify.errorToast("Please sign in");
          window.location.href = "/";
          payload.setIsLoading(false);
          localStorage.removeItem("token");
        }
        if (response?.data?.code === 200) {
          let decrypt = await decryption(response?.data?.response);
          let parsedData = JSON.parse(decrypt);
          payload.setIsLoading(false);
          return thunkApi.fulfillWithValue(parsedData);
        }
      })
      .catch((error) => {
        if (
          error?.response?.data?.response ===
            "Invalid Access or Token Expired" ||
          error?.response?.data?.response === "Invalid Access" ||
          error?.response?.data?.response === "Token Expired" ||
          error?.response?.data?.response === "Please provide valid token" ||
          response?.data?.response === "Missing Authorization Header" ||
          error?.response?.data?.code === 401 ||
          error?.response?.data?.code === 403
        ) {
          payload.setIsLoading(false);
          localStorage.removeItem("token");
          window.location.href = "/";
        }
        payload.setIsLoading(false);
        notify.errorToast("Oops! something went wrong");
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

/**Function to edit provider profile */
export const editProfile = createAsyncThunk(
  "editProfile",
  async ({ payload, setDisabled, setIsLoading }, thunkApi) => {
    const response = axiosInstanceAuth
      .put(endPoints.edit_profile, payload)
      .then(async (response) => {
        if (
          response?.data?.response === "Invalid Access or Token Expired" ||
          response?.data?.response === "Missing Authorization Header" ||
          response?.data?.response === "Invalid Access" ||
          response?.data?.response === "Token Expired" ||
          response?.data?.response === "Please provide valid token" ||
          response?.data?.code === 401
        ) {
          setIsLoading(false);
          setDisabled(true);
          localStorage.removeItem("token");
          window.location.href = "/";
          notify.errorToast("Please sign in");
        }

        if (response?.data?.code === 200) {
          notify.successToast("Profile updated successfully");
          setTimeout(() => setIsLoading(false), 1000);
          setTimeout(() => {
            window.location.reload();
          }, 500);
          setDisabled(true);
          return thunkApi.fulfillWithValue(response?.data);
        }
      })
      .catch((error) => {
        if (
          error?.response?.data?.response ===
            "Invalid Access or Token Expired" ||
          error?.response?.data?.response === "Invalid Access" ||
          error?.response?.data?.response === "Token Expired" ||
          response?.data?.response === "Missing Authorization Header"
        ) {
          // payload.setIsLoading(false);
          // localStorage.removeItem("token");
          // window.location.href = "/";
          // notify.errorToast("Please sign in");
        }
        payload.setDisabled(true);
        notify.errorToast("Oops! something went wrong");
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

/**Api for validating token is valid or not */
export const verifyToken = createAsyncThunk(
  "tokenVerify",
  async (payload, thunkApi) => {
    const redirect = payload.navigate;
    return axiosInstanceAuth
      .post(endPoints.token_verify, payload)
      .then((response) => {
        if (response?.data?.code === 200) {
          payload.setIsLoading(false);
          return thunkApi.fulfillWithValue({
            token: response?.data?.response,
          });
        } else {
          payload.setIsLoading(false);
          localStorage.removeItem("token");
          window.location = "/";
        }
      })
      .catch((error) => {
        payload.setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
  }
);

const initialState = {};

const signUpSlice = createSlice({
  name: "signUpSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userSignUp.pending, (state, action) => {
    });
    builder.addCase(userSignUp.fulfilled, (state, action) => {
    });
    builder.addCase(userSignUp.rejected, (state, action) => {
    });
    builder.addCase(userProfile.pending, (state, action) => {
    });
    builder.addCase(userProfile.fulfilled, (state, action) => {
      state.profileInfo = action.payload;
    });
    builder.addCase(userProfile.rejected, (state, action) => {
    });
    builder.addCase(insightVerify.pending, (state, action) => {
    });
    builder.addCase(insightVerify.fulfilled, (state, action) => {
      state.register = action.payload;
    });
    builder.addCase(insightVerify.rejected, (state, action) => {
    });
  },
});

export const { logout } = signUpSlice.actions;

export default signUpSlice.reducer;
