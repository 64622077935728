import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../api/axios";
import endPoints from "../../api/endpoints";
import { encryption, decryption } from "../../Encrypt";
import { notify } from "../../NotifyToast";
import { refreshToken } from "./refrshToken";

const initialState = { state: "check" };

/**Api for showing pdf files for download */
export const pdfFile = createAsyncThunk(
  "filePdf",
  async (setIsLoading, thunkApi) => {
    const response = axiosInstance
      .get(endPoints.pdf)
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          setIsLoading(false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
          // localStorage.removeItem("customer_token");
          // window.location.href = "/";
          // notify.errorToast("Please sign in");
        }
        let decrypt = await decryption(response?.data?.response);
        let parsedData = JSON.parse(decrypt);
        if (response?.data?.code === 200) {
          setIsLoading(false);
          return thunkApi.fulfillWithValue(parsedData);
        }
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          setIsLoading(false);
          // localStorage.removeItem("accessToken");
          // localStorage.removeItem("refreshToken");
          // localStorage.removeItem("idToken");
          // localStorage.removeItem("expiresIn");
          // sessionStorage.clear();
          // window.location = "/";
          thunkApi.dispatch(refreshToken());
          // localStorage.removeItem("customer_token");
          // window.location.href = "/";
          // notify.errorToast("Please sign in");
        }
        setIsLoading(false);
        // notify.errorToast("Oops! something went wrong");
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

const pdfSlice = createSlice({
  name: "cardSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(pdfFile.pending, (state, action) => {
    });
    builder.addCase(pdfFile.fulfilled, (state, action) => {
      state.filesPdf = action.payload;
    });
    builder.addCase(pdfFile.rejected, (state, action) => {
    });
  },
});

export default pdfSlice.reducer;
