import React, { useState } from "react";
import "./QuickAccess.scss";
import Scrollbars from "react-custom-scrollbars-2";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { notify } from "../../../NotifyToast";

const QuickAccess = () => {
  const quickInfo = useSelector((state) => state.accessSlice?.accessInfo);

  const [read, setRead] = useState(false)

  return (
    <div className="quick-content quick-access-content">
      <div className="quick-head">
        <h3>Quick Access</h3>
      </div>
      <div className="quick-body scroll-wrp">
        <Scrollbars
          autoHeight
          autoHide
          renderThumbHorizontal={() => <div className="thumb-horizantal" />}
          renderTrackHorizontal={() => <div className="track-horizantal" />}
        >
          <div className="quick-box-main">
            <div className="quick-box">
              <div className="icon-wrap">
                <div className="walmart-title-wrap">
                  <div className="walmart-logo">
                    <img src={quickInfo?.[0]?.partner_image} alt="walmart" />
                  </div>
                  <span className="walmart-title">
                    {quickInfo?.[0]?.partner_name}
                  </span>
                </div>
                <div className="icon-telehealth">
                  {quickInfo?.[0]?.partner_type}
                </div>
              </div>
              <div className={read ? "walmart-text expanded":"walmart-text"}>
                <p>{quickInfo?.[0]?.partner_desc}</p>
                <button className="" onClick={()=>setRead(prev=>!prev)}>Read {read?"less":"more"}</button>
              </div>
              <div className="copay-care-wrap">
                <span className="copay-care-block">
                  {quickInfo?.[0]?.coupon_code}
                </span>
              </div>
              <Link
                className="btn"
                target="_blank"
                to={quickInfo?.[0]?.partner_page_url}
              >
                Connect MD Live
              </Link>
            </div>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default QuickAccess;
