import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../api/axios";
import endPoints from "../../api/endpoints";
import { decryption } from "../../Encrypt";
import { refreshToken } from "./refrshToken";

const initialState = { state: "check" };

/**Api for displaying health plan summary */
export const healthPlan = createAsyncThunk(
  "healthPlan",
  async (setIsLoading, thunkApi) => {
    const response = axiosInstance
      .get(endPoints.homeHealthPlan)
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          setIsLoading(false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
        let decrypt = await decryption(response?.data?.response);
        let parsedData = JSON.parse(decrypt);
        if (response?.data?.code === 200) {
          setIsLoading(false);
          return thunkApi.fulfillWithValue(parsedData);
        }
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          setIsLoading(false);
          // localStorage.removeItem("accessToken");
          // localStorage.removeItem("refreshToken");
          // localStorage.removeItem("idToken");
          // localStorage.removeItem("expiresIn");
          // sessionStorage.clear();
          // window.location = "/";
          thunkApi.dispatch(refreshToken());
        }
        setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

/**Api for displaying claim indexes on homepage */
export const claimIndex = createAsyncThunk(
  "claimIndex",
  async ({payload, setIsLoading}, thunkApi) => {
    const response = axiosInstance
      .get(endPoints.claimsIndex + "?pageNumber=" + payload?.pageNumber + "&recordsPerPage=" + payload?.recordsPerPage)
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          setIsLoading(false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
        let decrypt = await decryption(response?.data?.response);
        let parsedData = JSON.parse(decrypt);
        if (response?.data?.code === 200) {
          setIsLoading(false);
          return thunkApi.fulfillWithValue({parsedData:parsedData, data:response?.data});
        }
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          setIsLoading(false);
          // localStorage.removeItem("accessToken");
          // localStorage.removeItem("refreshToken");
          // localStorage.removeItem("idToken");
          // localStorage.removeItem("expiresIn");
          // sessionStorage.clear();
          // window.location = "/";
          thunkApi.dispatch(refreshToken());
        }
        setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

export const claimGraph = createAsyncThunk(
  "graphss",
  async (setIsLoading, thunkApi) => {
    const response = axiosInstance
      .get(endPoints.graphs)
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          setIsLoading(false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
        let decrypt = await decryption(response?.data?.response);
        let parsedData = JSON.parse(decrypt);
        if (response?.data?.code === 200) {
          setIsLoading(false);
          return thunkApi.fulfillWithValue(parsedData);
        }
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          setIsLoading(false);
          // localStorage.removeItem("accessToken");
          // localStorage.removeItem("refreshToken");
          // localStorage.removeItem("idToken");
          // localStorage.removeItem("expiresIn");
          // sessionStorage.clear();
          // window.location = "/";
          thunkApi.dispatch(refreshToken());
        }
        setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

const homepageSlice = createSlice({
  name: "homepageSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(healthPlan.pending, (state, action) => {
    });
    builder.addCase(healthPlan.fulfilled, (state, action) => {
      state.planInfo = action.payload;
    });
    builder.addCase(healthPlan.rejected, (state, action) => {
    });
    builder.addCase(claimIndex.pending, (state, action) => {
    });
    builder.addCase(claimIndex.fulfilled, (state, action) => {
      state.claims = action.payload.parsedData;
      state.claimsData = action.payload.data;
    });
    builder.addCase(claimIndex.rejected, (state, action) => {
    });
    builder.addCase(claimGraph.pending, (state, action) => {
    });
    builder.addCase(claimGraph.fulfilled, (state, action) => {
      state.graph = action.payload;
    });
    builder.addCase(claimGraph.rejected, (state, action) => {
    });
  },
});

export default homepageSlice.reducer;
