import React, { useEffect, useState } from "react";
import "./ClaimCard.scss";
import Scrollbars from "react-custom-scrollbars-2";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { claimIndex } from "../../../store/slices/homepage";

const ClaimCard = () => {
  const claimesList = useSelector((state) => state.homepageSlice?.claims);
  const claimesData = useSelector((state) => state.homepageSlice?.claimsData);

  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()

  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(
    Math.ceil(claimesData?.totalRecords / itemsPerPage)
  );


  useEffect(() => {
    setPageCount(Math.ceil(claimesData?.totalRecords / itemsPerPage));
  }, [itemsPerPage, claimesData]);

  const handlePageClick = async(event) =>{
    setCurrentPage(event.selected);
    const payload = {
      pageNumber:event.selected,
      recordsPerPage:itemsPerPage
    }
    dispatch(claimIndex({payload, setIsLoading}));
  }

  const pageChange = async (e) => {
    setItemsPerPage(e.target.value);
    setCurrentPage(0)
    const payload = {
      pageNumber:0,
      recordsPerPage:e.target.value
    }
    dispatch(claimIndex({payload, setIsLoading}));
  };

  return (
    <div className="claim-card-wrap">
      {/* <Scrollbars
        className={`${claimesList?.length === 0 ? "no-data-scrollbar" : ""}`}
        style={{
          width: "auto",
          height: "100%",
        }}
        renderThumbVertical={() => <div className="thumb-vertical" />}
        renderTrackVertical={() => <div className="track-vertical" />}
      > */}
      <div className="claim-card-nw claim-wrp-main">
        {claimesList && claimesList?.length > 0 ? (
          claimesList?.map((item, index) => (
            <Link
              to="/claim-detail"
              className="claim-head-wrp"
              key={index}
              state={{ id: item?.claim_number }}
            >
              <div className="claim-wrp">
                <div className="claim-main-head">
                  <span className="date">{item?.service_date}</span>
                  <i className="arrow-right"></i>
                </div>
                <div className="claim-main-wrap">
                  <div className="claim-name">
                    <h4>Claim Number : {item?.claim_number}</h4>
                    <h3>{item?.rendering_provider_last_name_or_org_name}</h3>
                  </div>
                  <div className="claim-detail">
                    <span
                      className="payment-status"
                      style={{
                        background: item?.claim_colour,
                      }}
                    >
                      {item?.claim_status}
                    </span>
                  </div>
                </div>
                <div className="claim-detail member">
                  <h3>Member Responsibility</h3>
                  <span className="claim-amt">
                    {/* {item?.member_responsibility !== 0 */}
                    {/* ? */}
                    {item?.member_responsibility}
                    {/* : item?.member_responsibility} */}
                  </span>
                </div>
              </div>
            </Link>
          ))
        ) : (
          <div className="no-data-found">
            <h3>No data found</h3>
          </div>
        )}
      </div>
      {/* </Scrollbars> */}
      <div className="pagintion">
            {claimesData?.totalRecords && claimesData?.totalRecords > 10 ? (
              <div className="entry-wrp">
                <span className="entry-text">show entries</span>
                <div className="select-wrap">
                  <select value={itemsPerPage} onChange={(e) => pageChange(e)}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </select>
                  <i className="icon-arrow"></i>
                </div>
              </div>
            ) : null}
            {claimesData?.totalRecords && claimesData?.totalRecords > 10 ? (
              <ReactPaginate
                breakLabel="..."
                nextLabel=""
                onPageChange={handlePageClick}
                marginPagesDisplayed={1}
                pageRangeDisplayed={window.innerWidth<767 ? 1 : 2}
                pageCount={pageCount}
                previousLabel=""
                renderOnZeroPageCount={null}
                className="pagination-list"
                forcePage={currentPage}
              />
            ) : null}
          </div>
    </div>
  );
};

export default ClaimCard;
