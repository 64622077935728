import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../api/axios";
import endPoints from "../../api/endpoints";
import { decryption } from "../../Encrypt";
import { refreshToken } from "./refrshToken";

const initialState = { state: "check" };

/**APi for displaying member resource details */
export const memberRes = createAsyncThunk(
  "membResource",
  async (_, thunkApi) => {
    const response = axiosInstance
      .get(endPoints.memRes)
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          //   setIsLoading(false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
        let decrypt = await decryption(response?.data?.response);
        let parsedData = JSON.parse(decrypt);
        if (response?.data?.code === 200) {
          return thunkApi.fulfillWithValue(parsedData);
        }
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          //   setIsLoading(false);
          // localStorage.removeItem("accessToken");
          // localStorage.removeItem("refreshToken");
          // localStorage.removeItem("idToken");
          // localStorage.removeItem("expiresIn");
          // sessionStorage.clear();
          // window.location = "/";
          thunkApi.dispatch(refreshToken());
        }
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

/**Api for displaying insurance details */
export const insurance = createAsyncThunk(
  "insuranceRes",
  async (setIsLoading, thunkApi) => {
    const response = axiosInstance
      .get(endPoints.insuranceRe)
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          setIsLoading(false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
        let decrypt = await decryption(response?.data?.response);
        let parsedData = JSON.parse(decrypt);
        if (response?.data?.code === 200) {
          setIsLoading(false);
          return thunkApi.fulfillWithValue(parsedData);
        }
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          setIsLoading(false);
          // localStorage.removeItem("accessToken");
          // localStorage.removeItem("refreshToken");
          // localStorage.removeItem("idToken");
          // localStorage.removeItem("expiresIn");
          // sessionStorage.clear();
          // window.location = "/";
          thunkApi.dispatch(refreshToken());
        }
        setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

const resourceSlice = createSlice({
  name: "resourceSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(memberRes.pending, (state, action) => {
    });
    builder.addCase(memberRes.fulfilled, (state, action) => {
      state.memResource = action.payload;
    });
    builder.addCase(memberRes.rejected, (state, action) => {
    });
    builder.addCase(insurance.pending, (state, action) => {
    });
    builder.addCase(insurance.fulfilled, (state, action) => {
      state.insuranceResp = action.payload;
    });
    builder.addCase(insurance.rejected, (state, action) => {
    });
  },
});

export default resourceSlice.reducer;
