import React from 'react'
import { Link } from 'react-router-dom'
import './FixedFooter.scss'
import { useSelector } from 'react-redux';
const FixedFooter = () => {
    const quickInfo = useSelector((state) => state.accessSlice?.accessInfo);
    return (
        <div className='fixed-footer'>
            <ul className='fixed-menu'>
            <li className={window.location.pathname === "/homepage" ? "active" : ""}><Link to="/homepage">
                    <i className='icon-home'>
                    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2.00256 15.9543V23.4494H22.5001V7.70648L12.2528 0.987671L2.00256 7.52711"
                            stroke="#BBB8C1"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M2.00254 12.8841C2.83173 12.8841 3.50508 12.2108 3.50508 11.3816C3.50508 10.5524 2.83173 9.87903 2.00254 9.87903C1.17335 9.87903 0.5 10.5524 0.5 11.3816C0.5 12.2108 1.17335 12.8841 2.00254 12.8841Z"
                            stroke="#BBB8C1"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        </svg>
                    </i>Home</Link></li>

                    <li className={window.location.pathname === "/NetworkSummary"? "active": ""}><Link to="/NetworkSummary">
                    <i className='icon-provider'>
                        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.8306 7.5091C14.5524 7.5091 15.1359 6.84337 15.1359 6.0243C15.1359 5.20522 14.5524 4.53949 13.8306 4.53949C13.1088 4.53949 12.5253 5.20522 12.5253 6.0243C12.5253 6.84337 13.1088 7.5091 13.8306 7.5091Z"
                                stroke="#BBB8C1"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M11.0253 10.9013C11.0253 9.13977 12.282 7.71106 13.8304 7.71106C15.3788 7.71106 16.6354 9.13977 16.6354 10.9013"
                                stroke="#BBB8C1"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M13.8288 11.7877C16.7846 11.7877 19.1808 9.3915 19.1808 6.43565C19.1808 3.47981 16.7846 1.08362 13.8288 1.08362C10.8729 1.08362 8.47675 3.47981 8.47675 6.43565C8.47675 9.3915 10.8729 11.7877 13.8288 11.7877Z"
                                stroke="#BBB8C1"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M13.8288 19.7654V12.304"
                                stroke="#BBB8C1"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M5.85203 25.3529C8.80788 25.3529 11.2041 22.9567 11.2041 20.0008C11.2041 17.045 8.80788 14.6488 5.85203 14.6488C2.89619 14.6488 0.5 17.045 0.5 20.0008C0.5 22.9567 2.89619 25.3529 5.85203 25.3529Z"
                                stroke="#BBB8C1"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M19.1808 20.0018H11.7194"
                                stroke="#BBB8C1"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M22.5885 21.9155C23.6432 21.9155 24.4997 21.0591 24.4997 20.0044C24.4997 18.9497 23.6432 18.0932 22.5885 18.0932C21.5338 18.0932 20.6774 18.9497 20.6774 20.0044C20.6774 21.0591 21.5338 21.9155 22.5885 21.9155Z"
                                stroke="#BBB8C1"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </i>Benefits</Link></li>
                <li className={window.location.pathname === "/benefits" ? "active" : ""}><Link to="/benefits">
                    <i className='icon-card'>
                    <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path 
                        fillRule="evenodd" 
                        clipRule="evenodd" 
                        d="M6.53516 19.1173V17.5427C7.55265 17.2035 7.52845 17.2035 7.57685 16.9128L7.62536 6.39867C7.62536 5.76881 7.52845 5.74451 6.70477 5.57489V4.00024H12.4462C14.7718 4.00024 15.8862 4.09715 16.9279 4.77552C18.115 5.55079 18.7206 6.88322 18.7206 8.09453C18.7206 10.178 17.5093 11.1955 15.0626 12.0434C15.9588 12.7702 16.2012 13.0368 16.9521 13.9816L18.4783 11.995L19.9077 13.0852L18.0908 15.5803C18.9871 17.0824 19.3505 17.3732 20.4649 17.5184V19.1175H16.928C16.5888 19.1175 16.5449 19.0831 16.3258 18.8994C16.0271 18.6626 16.0571 18.6626 15.862 18.5118L13.2215 22.0002L11.7922 20.9341L14.6991 16.9127C14.0209 15.3621 13.4879 14.3931 12.4947 13.206C11.8973 12.4759 11.6225 12.3582 10.9443 12.3582V16.3556C10.9443 17.2519 10.9685 17.4215 11.9375 17.47V19.1173H6.53516ZM15.0413 8.17009C15.0413 6.30461 13.6846 5.86857 10.9471 5.86857V10.4473C11.3833 10.4715 11.795 10.4715 12.0615 10.4715C13.9996 10.4716 15.0413 9.62362 15.0413 8.17009Z" 
                        fill="#BBB8C1"/>
                    </svg>

                    </i>Drugs Search</Link></li>
                
                
                <li className={
                  window.location.pathname === "/plan2" ? "active" : ""
                }><Link target="_blank"
                to={quickInfo?.[0]?.partner_page_url}>
                    <i className='my-care'>
                    <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M22.9148 4.9353H4.0852C3.20972 4.9353 2.5 5.71828 2.5 6.68413V19.3165C2.5 20.2823 3.20972 21.0653 4.0852 21.0653H22.9148C23.7903 21.0653 24.5 20.2823 24.5 19.3165V6.68413C24.5 5.71828 23.7903 4.9353 22.9148 4.9353Z"
                            stroke="#BBB8C1"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M15.1704 12.0999H21.4999"
                            stroke="#BBB8C1"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M18.335 8.9353L18.335 15.2648"
                            stroke="#BBB8C1"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M9.27646 12.9283C10.2453 12.9283 11.0327 12.0336 11.0327 10.9318C11.0327 9.83002 10.2479 8.9353 9.27646 8.9353C8.30505 8.9353 7.52026 9.83002 7.52026 10.9318C7.52026 12.0336 8.30505 12.9283 9.27646 12.9283Z"
                            stroke="#BBB8C1"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M5.5 17.0615C5.5 14.9263 7.18973 13.1965 9.27058 13.1965C11.3514 13.1965 13.0412 14.9263 13.0412 17.0615"
                            stroke="#BBB8C1"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        </svg>
                    </i>Telemedicine</Link>
                </li>
                <li className={
                  window.location.pathname === "/member-screen"
                    ? "active"
                    : ""
                }><Link to="/member-screen">
                    <i className='member'>
                    <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M23.202 20.187H3.79812L3.69138 20.1915C3.30741 20.2247 3.01147 20.4339 3.01147 20.687C3.01147 20.9631 3.36366 21.187 3.79812 21.187H23.202L23.3088 21.1825C23.6928 21.1493 23.9887 20.9401 23.9887 20.687C23.9887 20.4109 23.6366 20.187 23.202 20.187Z"
                            fill="#BBB8C1"
                        />
                        <path
                            d="M23.202 13.7798H3.79812L3.69138 13.7843C3.30741 13.8175 3.01147 14.0267 3.01147 14.2798C3.01147 14.5559 3.36366 14.7798 3.79812 14.7798H23.202L23.3088 14.7753C23.6928 14.7421 23.9887 14.5329 23.9887 14.2798C23.9887 14.0037 23.6366 13.7798 23.202 13.7798Z"
                            fill="#BBB8C1"
                        />
                        <path
                            d="M22.938 6.79639H11.0295L10.8869 6.80095C10.3741 6.83406 9.97876 7.04325 9.97876 7.29639C9.97876 7.57253 10.4492 7.79639 11.0295 7.79639H22.938L23.0806 7.79182C23.5934 7.75871 23.9888 7.54952 23.9888 7.29639C23.9888 7.02025 23.5183 6.79639 22.938 6.79639Z"
                            fill="#BBB8C1"
                        />
                        <path
                            d="M5.49504 9.77986C6.86707 9.77986 7.9786 8.66834 7.9786 7.2963C7.9786 5.92427 6.86707 4.81274 5.49504 4.81274C4.123 4.81274 3.01147 5.92427 3.01147 7.2963C3.01147 8.66834 4.123 9.77986 5.49504 9.77986Z"
                            stroke="#BBB8C1"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        </svg>
                    </i>Member</Link>
                </li>
            </ul>
        </div >
    )
}

export default FixedFooter