import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SummaryTable from "../../components/SummaryCopayment/SummaryTable/SummaryTable";
import "./CopaySummary.scss";
import Footer from "../../components/Footer/Footer";

const CopaySummary = () => {
  const navigate = useNavigate();
  const paySummary = useSelector((state) => state.benefitSlice?.summaryCo);

  /**Function for going back to previous page */
  const handleBack = () => {
    navigate(-1);
  };

  return (
    <div className="copay-summary">
      <div className="header-banner">
        <div className="container">
          <div className="copay-summary-heading">
            <h1>Copayment Summary</h1>
            <button class="bck-btn-wrap" type="button" onClick={handleBack}>
              <i class="icon-back"></i>Back
            </button>
          </div>
        </div>
      </div>
      <div className="card-headings">
        <div className="container">
          <span className="back-heading" onClick={()=>window.history.back()}>
            <i className="card-back-icon"></i>
          </span>
          <span className="card-head-text">Copayment Summary</span>
        </div>
      </div>
      <div className="container">
        <div className="copay-data">
          <div className="data-head">
            <h1>Welcome to Your Prescription Benefit Program!</h1>
            <div className="message-head">
              <div className="admin-wrp">
                <span className="admin">From Admin{paySummary?.from} </span>
                <span className="date">Feb, 22 2024</span>
              </div>
            </div>
            {/* <button className="bck-btn-wrap" onClick={handleBack}>
              <i className="icon-back"></i>
              Back
            </button> */}
          </div>
          <div className="copayment-summry-wrp">
            <div className="copayment-content">
              <p>
                Teddy is excited to serve you and would like to introduce you to
                our program before your benefits begin.
              </p>
              <p>
                Teddy has a national pharmacy network, which means you can
                receive your medications through any local retail pharmacy of
                your choice. If you ever encounter a pharmacy not in our
                network, please call the pharmacy help desk and we will enroll
                the pharmacy into the network.
              </p>
              <p>
                For a list of participating pharmacies, please visit:{" "}
                <a href="#" className="click-summary">
                  http://www.ehimrx.com/pharmacylocator.php
                </a>
              </p>
              <p>
                You will be receiving an ID card to use at the pharmacy. The ID
                card will have the employee’s name on every card. If you have
                any questions regarding your prescription benefits, please
                contact Teddy’s help desk at 800-311-3446.
              </p>
            </div>
            <div className="summary-copayment">
              <h4>Summary of Copayments</h4>
              <p>
                Every time you receive a prescription, you will be responsible
                for a copayment. Generally, your copayment will be lowest for
                generic prescriptions and highest for medications that are
                considered Non-Preferred under your plan design. Your plan’s
                copays are highlighted below:
              </p>
              <div className="summary-copay-table">
                <SummaryTable />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default CopaySummary;
