import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/head-logo-new.svg";
import "./Header.scss";
import { useDispatch, useSelector } from "react-redux";
import { viewProfile } from "../../store/slices/profile";
import upload from "../../assets/images/img-placeholder.svg";
import axios, { axiosInstanceLogout, axiosInstanceRefresh } from "../../api/axios";
import { quickAccess } from "../../store/slices/quickAccess";

const LOGOUT_URL = "/logout-account";

const Header = () => {
  const quickInfo = useSelector((state) => state.accessSlice?.accessInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profileView = useSelector((state) => state.profileSlice?.profileInfo);
  const isValid = localStorage.getItem("accessToken");
  const isNew = localStorage.getItem("new_token");
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const [isLoading, setIsLoading] = useState(false);


  /** Function for checking if user is logged in or not */
  useEffect(() => {
    if (!isValid) {
      //TODO: need to check this
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("expiresIn");
      localStorage.removeItem("idToken");
      localStorage.removeItem("new_token");
      window.location = "/";
    }
  });

  /** Function for calling profile API to display profile info */
  useEffect(() => {
    setTimeout(() => {
      dispatch(viewProfile());
    }, 1500);
    dispatch(quickAccess(setIsLoading));
  }, []);

  const logoutUser = async (e) => {
    e.preventDefault();
    const accessToken = localStorage.getItem("accessToken");
    const idToken = localStorage.getItem("idToken");
    axiosInstanceLogout
      .get(LOGOUT_URL + "?session="+localStorage.getItem("sessionID"), {
      })
      .then((response) => {
        // Clear session storage or perform any other logout actions
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("expiresIn");
        localStorage.removeItem("idToken");
        localStorage.removeItem("new_token");
        sessionStorage.clear();
        // Redirect the user to the logout redirect URL
        window.location.href = "/";
      })
      .catch((error) => {
        console.error("API call error:", error);
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
      });
  };

  return (
    <div className={(window.location?.pathname === "/homepage") ? "header" : window?.innerWidth > 767 ? "header" : "header d-none"}>
      <div className="header-wrap">
        <div className="container">
          <Link to="/homepage" className={window.location?.pathname === "/homepage" ? "logo curs-def" : "logo"}>
            <img src={logo} alt="logo" />
          </Link>
          <ul className="navigation">
            <li>
              <Link
                to="/homepage"
                className={
                  window.location.pathname === "/homepage" ? "active-menu" : ""
                }
              >
                <i className="icon-home">
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.34473 18.0365V26.5543H26.6392V8.66328L14.9936 1.02768L3.34473 8.45944"
                      stroke="#B6B6B6"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3.34624 14.5475C4.28858 14.5475 5.05381 13.7823 5.05381 12.8399C5.05381 11.8976 4.28858 11.1324 3.34624 11.1324C2.4039 11.1324 1.63867 11.8976 1.63867 12.8399C1.63867 13.7823 2.4039 14.5475 3.34624 14.5475Z"
                      stroke="#B6B6B6"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                  />
                  </svg>
                </i>
                <span className="menu-text mob-hide">Home</span>
              </Link>
            </li>
            <li>
              <Link
                to="/NetworkSummary "
                className={
                  window.location.pathname === "/NetworkSummary"
                    ? "active-menu"
                    : ""
                }
              >
                <i className="icon-home">
                  <svg
                    width="27"
                    height="26"
                    viewBox="0 0 27 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.8309 7.2909C15.5527 7.2909 16.1362 6.62517 16.1362 5.8061C16.1362 4.98702 15.5527 4.32129 14.8309 4.32129C14.1091 4.32129 13.5256 4.98702 13.5256 5.8061C13.5256 6.62517 14.1091 7.2909 14.8309 7.2909Z"
                      stroke="#BBB8C1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.0256 10.6832C12.0256 8.92163 13.2823 7.49292 14.8307 7.49292C16.3791 7.49292 17.6357 8.92163 17.6357 10.6832"
                      stroke="#BBB8C1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.8291 11.5695C17.7849 11.5695 20.1811 9.17336 20.1811 6.21751C20.1811 3.26167 17.7849 0.865479 14.8291 0.865479C11.8732 0.865479 9.47705 3.26167 9.47705 6.21751C9.47705 9.17336 11.8732 11.5695 14.8291 11.5695Z"
                      stroke="#BBB8C1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.8291 19.5474V12.0859"
                      stroke="#BBB8C1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.85228 25.1347C9.80813 25.1347 12.2043 22.7385 12.2043 19.7827C12.2043 16.8269 9.80813 14.4307 6.85228 14.4307C3.89643 14.4307 1.50024 16.8269 1.50024 19.7827C1.50024 22.7385 3.89643 25.1347 6.85228 25.1347Z"
                      stroke="#BBB8C1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M20.1812 19.7837H12.7197"
                      stroke="#BBB8C1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M23.5887 21.6973C24.6434 21.6973 25.4998 20.8409 25.4998 19.7862C25.4998 18.7315 24.6434 17.875 23.5887 17.875C22.534 17.875 21.6775 18.7315 21.6775 19.7862C21.6775 20.8409 22.534 21.6973 23.5887 21.6973Z"
                      stroke="#BBB8C1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </i>
                <span className="menu-text mob-hide">Benefits</span>
              </Link>
            </li>

            <li>
              <Link
                to="/benefits"
                className={
                  window.location.pathname === "/benefits" ? "active-menu" : ""
                }
              >
                <i className="icon-home">
                  <svg
                    width="27"
                    height="26"
                    viewBox="0 0 27 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.53516 19.1173V17.5427C7.55265 17.2035 7.52845 17.2035 7.57685 16.9128L7.62536 6.39867C7.62536 5.76881 7.52845 5.74451 6.70477 5.57489V4.00024H12.4462C14.7718 4.00024 15.8862 4.09715 16.9279 4.77552C18.115 5.55079 18.7206 6.88322 18.7206 8.09453C18.7206 10.178 17.5093 11.1955 15.0626 12.0434C15.9588 12.7702 16.2012 13.0368 16.9521 13.9816L18.4783 11.995L19.9077 13.0852L18.0908 15.5803C18.9871 17.0824 19.3505 17.3732 20.4649 17.5184V19.1175H16.928C16.5888 19.1175 16.5449 19.0831 16.3258 18.8994C16.0271 18.6626 16.0571 18.6626 15.862 18.5118L13.2215 22.0002L11.7922 20.9341L14.6991 16.9127C14.0209 15.3621 13.4879 14.3931 12.4947 13.206C11.8973 12.4759 11.6225 12.3582 10.9443 12.3582V16.3556C10.9443 17.2519 10.9685 17.4215 11.9375 17.47V19.1173H6.53516ZM15.0413 8.17009C15.0413 6.30461 13.6846 5.86857 10.9471 5.86857V10.4473C11.3833 10.4715 11.795 10.4715 12.0615 10.4715C13.9996 10.4716 15.0413 9.62362 15.0413 8.17009Z"
                      fill="#BBB8C1"
                    />
                  </svg>
                </i>
                <span className="menu-text mob-hide">Drugs Search</span>
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                to={quickInfo?.[0]?.partner_page_url}
                className={
                  window.location.pathname === "/plan2" ? "active-menu" : ""
                }
              >
                <i className="icon-home">
                  <svg
                    width="27"
                    height="26"
                    viewBox="0 0 27 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.9148 4.9353H4.0852C3.20972 4.9353 2.5 5.71828 2.5 6.68413V19.3165C2.5 20.2823 3.20972 21.0653 4.0852 21.0653H22.9148C23.7903 21.0653 24.5 20.2823 24.5 19.3165V6.68413C24.5 5.71828 23.7903 4.9353 22.9148 4.9353Z"
                      stroke="#BBB8C1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M15.1704 12.0999H21.4999"
                      stroke="#BBB8C1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18.335 8.9353L18.335 15.2648"
                      stroke="#BBB8C1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.27646 12.9283C10.2453 12.9283 11.0327 12.0336 11.0327 10.9318C11.0327 9.83002 10.2479 8.9353 9.27646 8.9353C8.30505 8.9353 7.52026 9.83002 7.52026 10.9318C7.52026 12.0336 8.30505 12.9283 9.27646 12.9283Z"
                      stroke="#BBB8C1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.5 17.0615C5.5 14.9263 7.18973 13.1965 9.27058 13.1965C11.3514 13.1965 13.0412 14.9263 13.0412 17.0615"
                      stroke="#BBB8C1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </i>
                <span className="menu-text mob-hide">Telemedicine</span>
              </Link>
            </li>
            {/* <li className="message-wrp">
              <Link
                to="/messages"
                className={
                  window.location.pathname === "/messages" ? "active-menu" : ""
                }
              >
                <i className="icon-message">
                  <svg
                    width="31"
                    height="28"
                    viewBox="0 0 31 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.755 2.005C10.1322 2.005 4.76002 7.37715 4.76002 14C4.76002 15.8131 5.16273 17.5304 5.88223 19.0721C5.99439 19.3125 5.97127 19.5942 5.82143 19.813L2.9225 24.0468H9.97741C10.1187 24.0468 10.257 24.0867 10.3766 24.1619C12.2223 25.3224 14.4105 25.995 16.755 25.995C23.3779 25.995 28.75 20.6228 28.75 14C28.75 7.37715 23.3779 2.005 16.755 2.005ZM3.26002 14C3.26002 6.54873 9.30374 0.505005 16.755 0.505005C24.2063 0.505005 30.25 6.54873 30.25 14C30.25 21.4513 24.2063 27.495 16.755 27.495C14.1982 27.495 11.8028 26.7826 9.76456 25.5468H1.5C1.22174 25.5468 0.966345 25.3928 0.836566 25.1466C0.706787 24.9005 0.723956 24.6027 0.881166 24.3731L4.34652 19.3121C3.64755 17.6799 3.26002 15.8846 3.26002 14Z"
                      fill="#240151"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.08789 10.8138C8.08789 9.52036 9.13762 8.47063 10.431 8.47063C11.7244 8.47063 12.7741 9.52036 12.7741 10.8138C12.7741 12.1071 11.7244 13.1569 10.431 13.1569C9.13762 13.1569 8.08789 12.1071 8.08789 10.8138ZM10.431 9.97063C9.96605 9.97063 9.58789 10.3488 9.58789 10.8138C9.58789 11.2787 9.96605 11.6569 10.431 11.6569C10.896 11.6569 11.2741 11.2787 11.2741 10.8138C11.2741 10.3488 10.896 9.97063 10.431 9.97063Z"
                      fill="#240151"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.08789 15.5931C8.08789 15.1789 8.42368 14.8431 8.83789 14.8431H18.0507C18.4649 14.8431 18.8007 15.1789 18.8007 15.5931C18.8007 16.0073 18.4649 16.3431 18.0507 16.3431H8.83789C8.42368 16.3431 8.08789 16.0073 8.08789 15.5931Z"
                      fill="#240151"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.08789 18.7794C8.08789 18.3652 8.42368 18.0294 8.83789 18.0294H25.461C25.8752 18.0294 26.211 18.3652 26.211 18.7794C26.211 19.1936 25.8752 19.5294 25.461 19.5294H8.83789C8.42368 19.5294 8.08789 19.1936 8.08789 18.7794Z"
                      fill="#240151"
                    />
                  </svg>
                </i>
                <span className="menu-text mob-hide">Messages</span>
              </Link>
            </li> */}
            <li>
              <Link
                to="/member-screen"
                className={
                  window.location.pathname === "/member-screen"
                    ? "active-menu"
                    : ""
                }
              >
                <i className="icon-home">
                  <svg
                    width="27"
                    height="26"
                    viewBox="0 0 27 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.202 20.187H3.79812L3.69138 20.1915C3.30741 20.2247 3.01147 20.4339 3.01147 20.687C3.01147 20.9631 3.36366 21.187 3.79812 21.187H23.202L23.3088 21.1825C23.6928 21.1493 23.9887 20.9401 23.9887 20.687C23.9887 20.4109 23.6366 20.187 23.202 20.187Z"
                      fill="#BBB8C1"
                    />
                    <path
                      d="M23.202 13.7798H3.79812L3.69138 13.7843C3.30741 13.8175 3.01147 14.0267 3.01147 14.2798C3.01147 14.5559 3.36366 14.7798 3.79812 14.7798H23.202L23.3088 14.7753C23.6928 14.7421 23.9887 14.5329 23.9887 14.2798C23.9887 14.0037 23.6366 13.7798 23.202 13.7798Z"
                      fill="#BBB8C1"
                    />
                    <path
                      d="M22.938 6.79639H11.0295L10.8869 6.80095C10.3741 6.83406 9.97876 7.04325 9.97876 7.29639C9.97876 7.57253 10.4492 7.79639 11.0295 7.79639H22.938L23.0806 7.79182C23.5934 7.75871 23.9888 7.54952 23.9888 7.29639C23.9888 7.02025 23.5183 6.79639 22.938 6.79639Z"
                      fill="#BBB8C1"
                    />
                    <path
                      d="M5.49504 9.77986C6.86707 9.77986 7.9786 8.66834 7.9786 7.2963C7.9786 5.92427 6.86707 4.81274 5.49504 4.81274C4.123 4.81274 3.01147 5.92427 3.01147 7.2963C3.01147 8.66834 4.123 9.77986 5.49504 9.77986Z"
                      stroke="#BBB8C1"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </i>
                <span className="menu-text mob-hide">Member</span>
              </Link>
            </li>
            <li>
              <button type="button" className="logout-btn" onClick={logoutUser}>
                <i className="icon-logout">
                  <svg
                    width="26"
                    height="28"
                    viewBox="0 0 26 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 23.356C18.1777 23.356 22.375 19.1586 22.375 13.981C22.375 8.80329 18.1777 4.60596 13 4.60596C7.82233 4.60596 3.625 8.80329 3.625 13.981C3.625 19.1586 7.82233 23.356 13 23.356Z"
                      stroke="#BBB8C1"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13.4688 15.4484C13.4688 15.7141 13.2589 15.9296 13 15.9296C12.7411 15.9296 12.5313 15.7141 12.5313 15.4484V9.50977L10.3536 11.6275C10.1657 11.8103 9.86901 11.802 9.69096 11.6091C9.51291 11.4162 9.52093 11.1116 9.70887 10.9289L12.6776 8.04178C12.8584 7.86596 13.1416 7.86596 13.3224 8.04178L16.2911 10.9289C16.4791 11.1116 16.4871 11.4162 16.3091 11.6091C16.131 11.802 15.8343 11.8103 15.6464 11.6275L13.4688 9.50977V15.4484ZM14.875 18.8167C14.875 19.8797 14.0356 20.7414 13 20.7414C11.9644 20.7414 11.125 19.8797 11.125 18.8167C11.125 17.7536 11.9644 16.8919 13 16.8919C14.0356 16.8919 14.875 17.7536 14.875 18.8167ZM12.0625 18.8167C12.0625 19.3481 12.4823 19.779 13 19.779C13.5178 19.779 13.9375 19.3481 13.9375 18.8167C13.9375 18.2852 13.5178 17.8543 13 17.8543C12.4823 17.8543 12.0625 18.2852 12.0625 18.8167Z"
                      fill="#BBB8C1"
                      stroke="#BBB8C1"
                      strokeWidth="0.2"
                    />
                  </svg>
                </i>
                <span className="menu-text mob-hide">
                  Logout
                </span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
