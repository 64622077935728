import React from "react";
import "./Popup.scss";
export const AuthLoading = () => {
  return (
    <div className="popup">
      <div className="popup-wrap">
        <div className="poup-content">
          <div className="auth">
            <h2>Authenticating...</h2>
          </div>
        </div>
      </div>
    </div>
  );
};
