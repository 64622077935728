import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./OpenMessage.scss";
import { messageStatus, openMessage } from "../../store/slices/messages";
import { useDispatch, useSelector } from "react-redux";
import { encryption, encryptionObj } from "../../Encrypt";
import { ThreeCircles, ThreeDots } from "react-loader-spinner";
import { archiveMsg } from "../../store/slices/messages";
import Footer from "../Footer/Footer";

const OpenMessage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  let { state } = useLocation();
  const msgDetails = useSelector((state) => state.messageSlice?.singleMsg);
  const [toggle, setToggle] = useState(false);

  /**Function to display message details */
  useEffect(() => {
    async function fetchMyAPI() {
      const data = state?.id;
      let payload = await encryption(data);
      setIsLoading(true);
      dispatch(openMessage({ payload, setIsLoading }));
    }
    fetchMyAPI();
  }, []);

  /**Function for calling the api to mark message as read */
  useEffect(() => {
    if (state?.tab) {
      async function fetchMyAPI() {
        const data = state?.id;
        let finalData = await encryption(data);
        let payload = {
          message_id: finalData,
          read_status: true,
        };
        dispatch(messageStatus(payload));
      }
      fetchMyAPI();
    } else {
      return;
    }
  }, []);

  /**Function for Archive toggle button */
  useEffect(() => {
    setToggle(msgDetails?.archived_message);
  }, [msgDetails]);

  /**Function for going back to previous page */
  const handleBack = () => {
    navigate(-1);
  };

  /**Function for enable / disable archive message status */
  const handleArchive = async (e) => {
    let data = await encryption(state?.id);
    let final = data.replace(" ", "+");
    let payload = {
      id: final,
      toggles: e.target.value,
    };
    setIsLoading(true);
    dispatch(archiveMsg({ payload, setIsLoading, navigate }));
  };

  return (
    <>
      {isLoading ? (
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#240151"
          wrapperClass="loading-spin"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
        />
      ) : (
        <div className="open-message">
          <div className="card-headings">
            <div className="container">
              <span className="back-heading" onClick={()=>window.history.back()}>
                <i className="card-back-icon"></i>
              </span>
              <span className="card-head-text">Messages</span>
              <button
                type="button"
                className="medium-card-back"
                onClick={handleBack}
              >
                <i className="medium-card-icon"></i>
                Back
              </button>
            </div>
          </div>
          <div className="container">
            <div className="message-data">
              <h1>{msgDetails?.message_header}</h1>
              <div className="message-content">
                <div className="open-admin-wrp">
                  <div className="admin-wrp">
                    <span className="admin">From {msgDetails?.from} </span>
                    <span className="date">{msgDetails?.time}</span>
                  </div>
                  <button className="bck-btn-wrap" onClick={handleBack}>
                    <i className="icon-back"></i>
                    Back
                  </button>
                </div>
                <p> {msgDetails?.message_content}</p>
                <div className="save-detail-wrp">
                  <div className="save-radio">
                    <label className="switch">
                      <input
                        type="checkbox"
                        value={toggle}
                        checked={toggle}
                        onChange={(e) => {
                          setToggle(e.target.checked);
                          handleArchive(e);
                        }}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <span className="save-text">Archive This Message</span>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default OpenMessage;
