import { toast } from "react-toastify";

const notifyToast = (message) =>
  toast(message, {
    theme: "dark",
    autoClose: 2000,
    hideProgressBar: false,
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    position: "top-right",
  });

const successToast = (message) =>
  toast.success(message, {
    theme: "dark",
    autoClose: 2000,
    hideProgressBar: false,
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    position: "top-right",
  });
const errorToast = (message) =>
  toast.error(message, {
    theme: "dark",
    autoClose: 2000,
    hideProgressBar: false,
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    position: "top-right",
  });

const warningToast = (message) =>
  toast(message, {
    theme: "dark",
    autoClose: 2000,
    hideProgressBar: false,
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    position: "top-right",
  });
const infoToast = (message) =>
  toast(message, {
    theme: "dark",
    autoClose: 2000,
    hideProgressBar: false,
    pauseOnHover: false,
    pauseOnFocusLoss: false,
    position: "top-right",
  });

export const notify = { successToast, errorToast, warningToast, infoToast };

export default notifyToast;
