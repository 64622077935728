import React, { useEffect, useState } from "react";
import "./CareBenefits.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import BenefitSummary from "../../components/MyCare/BenefitSummary/BenefitSummary";
import ClaimIndex from "../../components/MyCare/ClaimIndex/ClaimIndex";
import { claimIndex } from "../../store/slices/homepage";
import { useDispatch } from "react-redux";
import { healthPlan } from "../../store/slices/homepage";
import { ThreeCircles, ThreeDots } from "react-loader-spinner";
import { cardId } from "../../store/slices/card";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";

const CareBenefits = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [claimExpand, setCliamExpand] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch(healthPlan(setIsLoading));
    const payload = {
      pageNumber:0,
      recordsPerPage:10
    }
    dispatch(claimIndex({payload, setIsLoading}));
    dispatch(cardId(setIsLoading));
  }, []);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      {isLoading ? (
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#240151"
          wrapperClass="loading-spin"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          // wrapperClass=""
        />
      ) : (
        <div className="care-benefits">
          <div className="card-headings">
            <div className="container">
              <span className="back-heading" onClick={()=>window.history.back()}>
                <i className="card-back-icon"></i>
              </span>
              <span className="card-head-text">Claims</span>
              <button
                type="button"
                className="medium-card-back"
                onClick={handleBack}
              >
                <i className="medium-card-icon"></i>
                Back
              </button>
            </div>
          </div>
          <div className="container">
            <div className="care-data">
              <div className="tabbing-wrap">
                <Tabs>
                  <TabList>
                    {/* <Tab id="123">Benefits Summary</Tab> */}
                    {/* <Tab>Claims Index</Tab> */}
                  </TabList>

                  {/* <TabPanel>
                    <div className="tab-content">
                      <BenefitSummary />
                    </div>
                  </TabPanel> */}
                  <TabPanel>
                    <div
                      className={`tab-content claim-section-wrapper ${
                        claimExpand === true ? "claim-expanded" : ""
                      }`}
                    >
                      <ClaimIndex
                        setCliamExpand={setCliamExpand}
                        claimExpand={claimExpand}
                      />
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default CareBenefits;
