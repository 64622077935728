import React from "react";
import { useSelector } from "react-redux";
import "./Popup.scss";

export const AuthFail = () => {
  const registration = useSelector((state) => state?.signupSlice?.register);

  return (
    <div className="popup">
      <div className="popup-wrap">
        <div className="poup-content">
          <div className="auth-fail">
            <i className="icon-success">
              <svg
                width="57"
                height="57"
                viewBox="0 0 57 57"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M48.4377 8.99204C59.3723 19.9266 59.3724 37.6554 48.4377 48.5901C37.5031 59.5247 19.7743 59.5247 8.83966 48.5901C-2.09504 37.6554 -2.09494 19.9266 8.83966 8.99204C19.7744 -1.94266 37.503 -1.94266 48.4377 8.99204ZM45.4678 11.9619C36.1733 2.6674 21.104 2.6674 11.8095 11.9619C2.51506 21.2563 2.51502 36.3257 11.8095 45.6202C21.104 54.9147 36.1734 54.9147 45.4678 45.6202C54.7623 36.3257 54.7623 21.2563 45.4678 11.9619ZM38.5382 18.8915C39.3583 19.7116 39.3583 21.0413 38.5382 21.8614L31.6085 28.7911L38.5382 35.7207C39.3583 36.5408 39.3583 37.8705 38.5382 38.6906C37.7181 39.5106 36.3884 39.5106 35.5683 38.6906L28.6387 31.7609L21.709 38.6906C20.8889 39.5106 19.5592 39.5106 18.7392 38.6906C17.9191 37.8705 17.9191 36.5408 18.7392 35.7207L25.6688 28.7911L18.7392 21.8614C17.9191 21.0413 17.9191 19.7116 18.7392 18.8915C19.5592 18.0715 20.8889 18.0714 21.709 18.8915L28.6387 25.8212L35.5683 18.8915C36.3884 18.0714 37.7181 18.0715 38.5382 18.8915Z"
                  fill="#FF451A"
                />
              </svg>
            </i>
            <h3>Authentication Failed!</h3>
            <p>{registration ? registration : null}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
