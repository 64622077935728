import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../api/axios";
import endPoints from "../../api/endpoints";
import { encryption, decryption } from "../../Encrypt";
import { notify } from "../../NotifyToast";
import { refreshToken } from "./refrshToken";

const initialState = { state: "check" };

/**Api for displaying quick access info on the footer */
export const healthCard = createAsyncThunk(
  "healthCard",
  async (setIsLoading, thunkApi) => {
    const response = axiosInstance
      .get(endPoints.healthCard)
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          setIsLoading(false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
        let decrypt = await decryption(response?.data?.response);
        let parsedData = JSON.parse(decrypt);
        if (response?.data?.code === 200) {
          setIsLoading(false);
          return thunkApi.fulfillWithValue(parsedData);
        }
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          setIsLoading(false);
          // localStorage.removeItem("accessToken");
          // localStorage.removeItem("refreshToken");
          // localStorage.removeItem("idToken");
          // localStorage.removeItem("expiresIn");
          // sessionStorage.clear();
          // window.location = "/";
          thunkApi.dispatch(refreshToken());
        }
        setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

const healthCardSlice = createSlice({
  name: "healthCardSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(healthCard.pending, (state, action) => {
    });
    builder.addCase(healthCard.fulfilled, (state, action) => {
      state.healthCardInfo = action.payload;
    });
    builder.addCase(healthCard.rejected, (state, action) => {
    });
  },
});

export default healthCardSlice.reducer;
