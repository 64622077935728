import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../api/axios";
import endPoints from "../../api/endpoints";
import { decryption } from "../../Encrypt";
import { refreshToken } from "./refrshToken";

const initialState = { state: "check" };

export const cardId = createAsyncThunk(
  "IdCard",
  async (setIsLoading, thunkApi) => {
    const response = axiosInstance
      .get(endPoints.card)
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          setIsLoading(false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
        let decrypt = await decryption(response?.data?.response);
        let parsedData = JSON.parse(decrypt);
        if (response?.data?.code === 200) {
          setIsLoading(false);
          return thunkApi.fulfillWithValue(parsedData);
        }
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          // setIsLoading(false);
          // localStorage.removeItem("accessToken");
          // localStorage.removeItem("refreshToken");
          // localStorage.removeItem("idToken");
          // localStorage.removeItem("expiresIn");
          // sessionStorage.clear();
          // window.location = "/";
          thunkApi.dispatch(refreshToken());
        }
        setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);
export const benefitId = createAsyncThunk(
  "IdBenefit",
  async (setIsLoading, thunkApi) => {
    const response = axiosInstance
      .get(endPoints.benefits)
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          setIsLoading(false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
        let decrypt = await decryption(response?.data?.response);
        let parsedData = JSON.parse(decrypt);
        if (response?.data?.code === 200) {
          setIsLoading(false);
          return thunkApi.fulfillWithValue(parsedData);
        }
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          // setIsLoading(false);
          // localStorage.removeItem("accessToken");
          // localStorage.removeItem("refreshToken");
          // localStorage.removeItem("idToken");
          // localStorage.removeItem("expiresIn");
          // sessionStorage.clear();
          // window.location = "/";
          thunkApi.dispatch(refreshToken());
        }
        setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

export const dependent = createAsyncThunk(
  "depend",
  async (setIsLoading, thunkApi) => {
    const response = axiosInstance
      .get(endPoints.dependents)
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          setIsLoading(false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
        let decrypt = await decryption(response?.data?.response);
        let parsedData = JSON.parse(decrypt);
        if (response?.data?.code === 200) {
          setIsLoading(false);
          return thunkApi.fulfillWithValue(parsedData);
        }
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          setIsLoading(false);
          // localStorage.removeItem("accessToken");
          // localStorage.removeItem("refreshToken");
          // localStorage.removeItem("idToken");
          // localStorage.removeItem("expiresIn");
          // sessionStorage.clear();
          // window.location = "/";
          thunkApi.dispatch(refreshToken());
        }
        setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

export const claimCount = createAsyncThunk(
  "clCount",
  async (setIsLoading, thunkApi) => {
    const response = axiosInstance
      .get(endPoints.count)
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          setIsLoading(false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
        let decrypt = await decryption(response?.data?.response);
        let parsedData = JSON.parse(decrypt);
        if (response?.data?.code === 200) {
          setIsLoading(false);
          return thunkApi.fulfillWithValue(parsedData);
        }
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          setIsLoading(false);
          // localStorage.removeItem("accessToken");
          // localStorage.removeItem("refreshToken");
          // localStorage.removeItem("idToken");
          // localStorage.removeItem("expiresIn");
          // sessionStorage.clear();
          // window.location = "/";
          thunkApi.dispatch(refreshToken());
        }
        setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

const cardSlice = createSlice({
  name: "cardSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(cardId.pending, (state, action) => {
    });
    builder.addCase(cardId.fulfilled, (state, action) => {
      state.cards = action.payload;
    });
    builder.addCase(cardId.rejected, (state, action) => {
    });
    builder.addCase(benefitId.pending, (state, action) => {
    });
    builder.addCase(benefitId.fulfilled, (state, action) => {
      state.benefitCard = action.payload;
    });
    builder.addCase(benefitId.rejected, (state, action) => {
    });
    builder.addCase(dependent.pending, (state, action) => {
    });
    builder.addCase(dependent.fulfilled, (state, action) => {
      state.dependents = action.payload;
    });
    builder.addCase(dependent.rejected, (state, action) => {
    });
    builder.addCase(claimCount.pending, (state, action) => {
    });
    builder.addCase(claimCount.fulfilled, (state, action) => {
      state.claimCounts = action.payload;
    });
    builder.addCase(claimCount.rejected, (state, action) => {
    });
  },
});

export default cardSlice.reducer;
