import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios, { axiosInstanceKey } from "../../api/axios";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { ThreeDots } from "react-loader-spinner";
import { roRO } from "@mui/x-date-pickers";
import { notify } from "../../NotifyToast";

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const USERINFO_URL = "/user";
const LOGOUT_URL = "/logout-account";
const EXCHANGE_CODE_URL = "/token";
const clientId = process.env.REACT_APP_CLIENT_ID;

const Register = () => {
  const userRef = useRef();
  const errRef = useRef();
  const [userData, setUserData] = useState("");
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const code = query.get("code"); // Get 'code' query param
  const sessionID = query.get("session_state");
  localStorage.setItem("sessionID",sessionID)
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate()

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    // Function to send authorization code to backend
    const sendAuthCodeToBackend = async (authCode) => {
      const response = await axiosInstanceKey
        .get(EXCHANGE_CODE_URL, {
          params: {
            code: authCode,
            session:sessionID,
            clientId: clientId,
          },
        })
        .then(async (response) => {
          if (response?.data?.code !== 200 && response?.data?.code !== 201) {
            setIsLoading(false);
            navigate("/");
          } else {
            localStorage.setItem(
              "accessToken",
              response?.data?.response?.access_token
            );
            localStorage.setItem(
              "refreshToken",
              response?.data?.response?.refresh_token
            );
            localStorage.setItem(
              "expiresIn",
              response?.data?.response?.expires_in
            );
            localStorage.setItem("idToken", response?.data?.response?.id_token);
            setSuccess(true);

            navigate("/homepage");
          }
        })
        .catch((err) => {
          notify.errorToast(err?.response?.data?.response)
          if (
            err?.response?.status === 401 ||
            err?.response?.status === 403 ||
            err?.response?.data?.code === 401 ||
            err?.response?.data?.code === 403
          ) {
            setIsLoading(false);
          }
          console.error("Error sending auth code to backend:", err);
          navigate("/");
        });
      // Handle response, such as storing access tokens or redirecting
    };
    if (code) {
      sendAuthCodeToBackend(code);
    } else if (!accessToken) {
      navigate("/");
    } else {
      setSuccess(true);
    }
  }, [code]);

  const fetchUser = async (e) => {
    const accessToken = localStorage.getItem("accessToken");
    axios
      .get(USERINFO_URL, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          ClientId: `app2`,
        },
      })
      .then((response) => {
        setUserData(JSON.stringify(response.data, null, 2));
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("idToken");
          localStorage.removeItem("new_token");
          window.location = "/";
        }
        console.error("API call error:", error);
      });
  };

  const logoutUser = async (e) => {
    const accessToken = localStorage.getItem("accessToken");
    const idToken = localStorage.getItem("idToken");
    axios
      .get(LOGOUT_URL, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          idToken: `${idToken}`,
        },
      })
      .then((response) => {
        // Clear session storage or perform any other logout actions
        sessionStorage.clear();
        // Redirect the user to the logout redirect URL
        window.location.href = "/";
      })
      .catch((error) => {
        console.error("API call error:", error);
      });
  };

  return (
    <>
      {/* {success ? (
        <section>
          <h1>Login successful for MD Live !!!!</h1>
          <span className="line">
            <a href="#" onClick={logoutUser}>
              Logout
            </a>
          </span>
          <button onClick={fetchUser}>Fetch user</button>

          {userData && <pre>{userData}</pre>}
        </section>
      ) : (
        <section>Failed to fetch access token</section>
      )} */}
      {isLoading ? (
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#240151"
          wrapperClass="loading-spin"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
        // wrapperClass=""
        />
      ) : null}
    </>
  );
};

export default Register;
