import React from 'react'
import './SummaryTable.scss'
const SummaryTable = () => {
    return (
        <div className='summary-table'>
            <div className='table'>
                <div className='data main-head'>
                    <div className='value head email'>Co-pay</div>
                    <div className='value head action'>Description</div>
                </div>
                <div className='value-wrp'>
                    <div className='data'>
                        <div className='value email'>$0.00</div>
                        <div className='value action'>Copayment on any covered PPACA medication</div>
                    </div>
                    <div className='data'>
                        <div className='value email'>$5.00</div>
                        <div className='value action'>Copayment on any covered generic</div>
                    </div>
                    <div className='data'>

                        <div className='value email'>$40.00</div>
                        <div className='value action'>Copayment on any covered preferred brand medication</div>
                    </div>
                    <div className='data'>

                        <div className='value email'>$80.00</div>
                        <div className='value action'>Copayment on any covered non-preferred brand medication</div>
                    </div>
                    <div className='data'>

                        <div className='value email'>Not Covered</div>
                        <div className='value action'>Specialty medications are excluded from coverage</div>
                    </div>
                    <div className='data'>

                        <div className='value email'>3X</div>
                        <div className='value action'>Copayment on any covered medication when filled in a 3-month supply. Three months supply may be filled through a local participating pharmacy.</div>
                    </div>
                    <div className='data'>

                        <div className='value email'>Single $5000 Family $10,000</div>
                        <div className='value action'>Once a member/contract spends the maximum in pharmacy copays that member/contract will have a $0 copay on all covered medications for the plan year. One person in a 2-person/family contract will be capped at the single amount and the rest of the members under that contract will have to meet the remaining balance.</div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SummaryTable