import React, { useEffect, useState } from "react";
import "./ClaimPermissions.scss";
import { decryption, encryptionObj } from "../../../Encrypt";
import axiosInstance from "../../../api/axios";
import endPoints from "../../../api/endpoints";
import { notify } from "../../../NotifyToast";
import { ThreeDots } from "react-loader-spinner";

const ClaimPermissions = ({ setTabData, claimData, setClaimData }) => {
  const [isLoader, setIsLoader] = useState(true);
  useEffect(() => {
    axiosInstance
      .get(endPoints.claimPermission)
      .then(async (response) => {
        setIsLoader(false);
        if (response?.data?.code === 200) {
          let decrypt = await decryption(response?.data?.response);
          setClaimData(JSON.parse(decrypt));
        }
      })
      .catch((error) => {
        setIsLoader(false);
      });
  }, []);

  const handleInput = (e, index) => {
    const data = [...claimData];
    data[index] = { ...data[index], grant_claim_access: e.target.checked };
    setClaimData(data);
  };

  const handleSubmit = async () => {
    let finalData = await encryptionObj(claimData);
    let payload = {
      data: finalData,
    };
    setIsLoader(true);
    axiosInstance
      .put(endPoints.claimPermission, payload)
      .then(async (response) => {
        setIsLoader(false);
        if (response?.data?.code === 200) {
          notify.successToast(response?.data?.response);
        } else {
          notify.errorToast(
            response?.data?.response ?? "Something went wrong!"
          );
        }
      })
      .catch((error) => {
        setIsLoader(false);
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
        else{
          notify.errorToast(
            error?.response?.data?.response ?? "Something went wrong!"
          );
        }
      });
  };
  return (
    <>
      {
        isLoader && <ThreeDots
        visible={true}
        height="80"
        width="80"
        color="#240151"
        wrapperClass="loading-spin"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
      />
      }
    <div className="member-resource-wrap account-setting claim-perm-tab">
      <div className="resource-head">
        <button className="back-btn" onClick={() => setTabData(false)}>
          <i className="icon-resouce"></i>
        </button>
        Claim Sharing Permissions
      </div>
      <div className="container-claims">
        <p>Please select all family members you wish to share your claims information with. All family members grayed out are not eligible to be changed.</p>
        <h1>Select members</h1>
        {claimData?.map((item, index) => {
          return (
            <div className="checkbox-wrapper" key={item?.member_id}>
              <input
                type="checkbox"
                id={`claim${item?.member_id}`}
                checked={item?.grant_claim_access}
                onChange={(e) => handleInput(e, index)}
              />
              <label htmlFor={`claim${item?.member_id}`} className="perm-label">
                {item?.member_name}
              </label>
            </div>
          );
        })}
        <button className="btn save" onClick={handleSubmit}>
          Save
          <i className="update-icon">
            <svg
              width="22"
              height="22"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.9231 7.9575L20.0341 7.8288C20.4416 7.42147 21.0791 7.38449 21.5283 7.71786L21.657 7.82897L29.3017 15.4753C29.7088 15.8825 29.746 16.5195 29.4131 16.9687L29.3021 17.0974L21.6574 24.7527C21.2096 25.2011 20.483 25.2016 20.0346 24.7537C19.627 24.3466 19.5895 23.7092 19.9224 23.2597L20.0335 23.1309L25.7108 17.4438L4.78518 17.4452C4.20426 17.4452 3.72415 17.0136 3.64818 16.4534L3.6377 16.2977C3.6377 15.7169 4.06939 15.2368 4.62947 15.1608L4.78518 15.1502L25.7307 15.1488L20.034 9.4516C19.6267 9.04417 19.5897 8.40669 19.9231 7.9575Z"
                fill="white"
              />
            </svg>
          </i>
        </button>
      </div>
    </div>
    </>
  );
};
export default ClaimPermissions;
