import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstanceRefresh, axiosInstanceKey } from "../../api/axios";
import endPoints from "../../api/endpoints";
import { notify } from "../../NotifyToast";

const initialState = { state: "check" };

/**Refresh token api when the login token gets expire */
export const refreshToken = createAsyncThunk(
  "tokenRefresh",
  async (_, thunkApi) => {
    return axiosInstanceRefresh
      .get(endPoints.refresh)
      .then((response) => {
        if (response?.data?.code === 200) {
          localStorage.setItem(
            "new_token",
            response?.data?.response?.access_token
          );
          setTimeout(() => {
            window.location.reload();
          }, 500);
          return thunkApi.fulfillWithValue(response?.data?.response);
        } else {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
          notify.errorToast(response?.data?.response);
          return thunkApi.rejectWithValue(response?.data?.response);
        }
      })
      .catch((error) => {
        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
        return thunkApi.rejectWithValue(error);
      });
  }
);

const refreshSlice = createSlice({
  name: "refreshSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(refreshToken.pending, (state, action) => {
    });
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      state.refreshInfo = action.payload;
    });
    builder.addCase(refreshToken.rejected, (state, action) => {
    });
  },
});

export default refreshSlice.reducer;
