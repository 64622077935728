import { configureStore } from "@reduxjs/toolkit";
import loginSlice from "./slices/login";
import signupSlice from "./slices/signUp";
import homepageSlice from "./slices/homepage";
import profileSlice from "./slices/profile";
import benefitSlice from "./slices/benefits";
import cardSlice from "./slices/card";
import resourceSlice from "./slices/memberResource";
import accessSlice from "./slices/quickAccess";
import indexDetialSlice from "./slices/claimIndexDetails";
import pdfSlice from "./slices/pdf";
import messageSlice from "./slices/messages";
import forgotPasswordSlice from "./slices/forgotPass";
import healthCardSlice from "./slices/healthCard";

const store = configureStore({
  reducer: {
    loginSlice,
    signupSlice,
    homepageSlice,
    profileSlice,
    benefitSlice,
    cardSlice,
    resourceSlice,
    accessSlice,
    indexDetialSlice,
    pdfSlice,
    messageSlice,
    forgotPasswordSlice,
    healthCardSlice
  },
});

export default store;
