import React, { useState } from "react";
import "../MemberResource/MemberResource.scss";
import "./AccountSetting.scss";
import AccountSettingForm from "../AccountSettingForm/AccountSettingForm";
import ChangePassword from "../ChangePassword/ChangePassword";

const AccountSetting = ({ setTabData }) => {
  const [openPassword, setOpenPassword] = useState(true);

  return (
    <div className="member-resource-wrap account-setting">
      <div className="resource-head">
        <button className="back-btn" onClick={() => setTabData(false)}>
          <i className="icon-resouce"></i>
        </button>
        Account Details
      </div>
      {/* {openPassword ? ( */}
      <AccountSettingForm setOpenPassword={setOpenPassword} />
      {/* ) : ( */}
      {/* <ChangePassword setOpenPassword={setOpenPassword} />
      )} */}
    </div>
  );
};

export default AccountSetting;
