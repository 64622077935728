import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import StaffTable from "../../components/StaffTable/StaffTable";
import "./Benefits.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  copayment,
  benefitsSearch,
  benefitsList,
} from "../../store/slices/benefits";
import { encryption } from "../../Encrypt";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import Footer from "../../components/Footer/Footer";

const Benefits = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [searchState, setSearchState] = useState(false);
  const paySummary = useSelector((state) => state.benefitSlice?.summaryCo);
  const result = useSelector((state) => state.benefitSlice?.searchResult);
  const navigate = useNavigate();
  const [popup, setPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiPage, setApiPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const handlePopup = () => {
    document.body.classList.toggle("open-popup");
    setPopup((prev) => !prev);
  };

  /** Function for going back to previous page */
  const handleBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  /** Function to call copayment api after page load */
  useEffect(() => {
    dispatch(copayment());
  }, []);

  /** Function for search inside search bar */
  const handleSearch = async (e) => {
    if (e.target.value !== "") {
      setSearchState(true);
    } else {
      let payload = {
        pageNumber: 0,
        recordsPerPage: itemsPerPage,
      };
      dispatch(benefitsList({ payload, setIsLoading }));
      setSearchState(false);
    }
    setSearch(e.target.value);
    let data = {
      product_label: e.target.value,
    };
    let payload = await encryption(data?.product_label);
    dispatch(benefitsSearch({ payload, setIsLoading, apiPage:0, recordsPerPage:itemsPerPage }));
  };


  return (
    <>
      {isLoading ? <LoadingScreen /> : null}
      <div className="benefits-wrap">
        <div className="header-banner">
          <div className="container">
            <h1>Drugs Search</h1>
          </div>
        </div>
        <div className="card-headings">
          <div className="container">
            <span className="back-heading" onClick={()=>window.history.back()}>
              <i className="card-back-icon"></i>
            </span>
            <span className="card-head-text">Rx Drugs</span>
          </div>
        </div>
        <div className="benifit-search-wrap">
          <div className="container">
            <div className="benefits-card">
              <div className="benefits-data">
                <div className="search-main-wrap">
                  <div className="search-wrp">
                    <input
                      type="text"
                      className="search-input"
                      placeholder="Search your drugs here"
                      onChange={(e) => handleSearch(e)}
                    />
                    <i className="icon-search">
                      <svg
                        width="48"
                        height="26"
                        viewBox="0 0 48 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M35.1618 1.63916H13.1156C6.77706 1.63916 1.63867 6.77755 1.63867 13.1161C1.63867 19.4546 6.77706 24.593 13.1156 24.593H35.1618C41.5003 24.593 46.6387 19.4546 46.6387 13.1161C46.6387 6.77755 41.5003 1.63916 35.1618 1.63916Z"
                          stroke="#240151"
                          strokeWidth="1"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M30.6556 18.6636C34.2969 18.6636 37.2487 15.7118 37.2487 12.0705C37.2487 8.4292 34.2969 5.47736 30.6556 5.47736C27.0143 5.47736 24.0625 8.4292 24.0625 12.0705C24.0625 15.7118 27.0143 18.6636 30.6556 18.6636Z"
                          stroke="#240151"
                          strokeWidth="1"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M40.4041 22.9435L35.0781 17.2872"
                          stroke="#240151"
                          strokeWidth="1"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M18.0097 15.4614C19.309 15.5259 20.4147 14.5215 20.4792 13.2222C20.5437 11.923 19.5393 10.8172 18.2401 10.7527C16.9408 10.6882 15.835 11.6926 15.7705 12.9919C15.706 14.2911 16.7104 15.3969 18.0097 15.4614Z"
                          stroke="#240151"
                          strokeWidth="1"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </i>
                  </div>
                  {/* <Link
                    to="/copay-summary"
                    className={
                      window.location.pathname === "/copay-summary"
                        ? "active-menu"
                        : ""
                    }
                  >
                    <button className="copayment-popup">
                      <div className="info-wrp">
                        <i className="info-icon"></i>
                      </div>
                      <p>Copayment Summary</p>
                    </button>
                  </Link> */}
                </div>

                <div className="benefit-data-wrap">
                  <div className="copayment-table">
                    <StaffTable
                      search={search}
                      searchState={searchState}
                      setApiPage={setApiPage}
                      itemsPerPage={itemsPerPage}
                      setItemsPerPage={setItemsPerPage}
                    />
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Benefits;
