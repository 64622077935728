const endPoints = {
  login: "/sign-in",
  signUp: "/v2/registration",
  verifyPhone: "/verify/phone-number",
  verifyEmail: "/email-verify",
  verifyInsight: "/verify-member",
  profile: "/profile",
  homeHealthPlan: "/home/health-plan-summary",
  token_verify: "/profile/refresh",
  claimsIndex: "/claims/index",
  benifits: "/rx-benefits/all-product",
  copayment: "/rx-benefits/copayment-summary",
  benefitSearch: "/rx-benefits/product",
  card: "/card",
  benefits: "/benefits",
  memRes: "/resource/member-resources",
  insuranceRe: "/resource/insurance-and-benefits",
  quickAcs: "/home/quick-access",
  indexDetails: "/claims/index-details",
  pdf: "/documents",
  msgs: "/message/un-archive",
  msgsArch: "/message/archive",
  msgOpen: "message",
  arcMsg: "/message/archive-toggle",
  uploadPic: "/profile/pic-upload",
  passLink: "/password/reset-link",
  pass_reset: "/forgot-password",
  linkCheck: "/password/reset-link/check",
  changePass: "/change-password",
  refresh: "/token-refresh",
  deleteImg: "/profile/pic",
  resenMob: "/verify/resend-mobile-otp",
  resendEmail: "/verify/resend-email-otp",
  deleteAccnt: "/account",
  searchMessage: "/message/search",
  keyclk: "/member-web",
  keyclk2: "/app2",
  msgRead: "/message/read-toggle",
  dependents: "v2/dependent",
  count: "/claims/count",
  graphs: "/claims/graph-details",
  healthCard:"/benefits/health-card",
  genericResources:"/resource/generic-resources",
  contactUs:"/contact-us",
  claimPermission:"/claim-permissions"
};

export default endPoints;
