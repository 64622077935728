import { Provider } from "react-redux";
import "./assets/styles/main.scss";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import SignIn from "./components/auth/SignIn/SignIn";
import SignUp from "./components/auth/SignUp/SignUp";
import ForgetPassword from "./components/auth/ForgetPassword/ForgetPassword";
import VerifyPhone from "./components/auth/VerifyPhone/VerifyPhone";
import VerifyEmail from "./components/auth/VerifyEmail/VerifyEmail";
import store from "./store/store";
import AuthInsight from "./components/auth/AuthInsight/AuthInsight";
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import Auth from "./components/auth/Auth";
import Homepage from "./pages/Homepage/Homepage";
import Benefits from "./pages/Benefits/Benefits";
import Plans from "./pages/Plans/Plans";
import Header from "./components/Header/Header";
import FixedFooter from "./components/FixedFooter/FixedFooter";
import { useEffect, useState } from "react";
import CopaySummary from "./pages/CopaySummary/CopaySummary";
import CareBenefits from "./pages/CareBenefits/CareBenefits";
import Messages from "./pages/Messages/Messages";
import OpenMessage from "./components/OpenMessage/OpenMessage";
import MemberScreen from "./pages/MemberScreen/MemberScreen";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ResetPass from "./components/auth/ForgetPassword/ResetPass";
import ExpandClaimCard from "./components/MyCare/ExpandClaimCard/ExpandClaimCard";
import Register from "./pages/Homepage/Register2";
import NetworkSummary from "./components/PlanPageData/NetworkSummary/NetworkSummary";
import DrugSearch from "./components/auth/DrugPage/DrugSearch/DrugSearch";
import AboutUs from "./pages/AboutUs/AboutUs";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import ContactUs from "./pages/ContactUs/ContactUs";

function App() {
  const location = useLocation();
  const [contentManage, setContentManage] = useState(false);
  const [token, setToken] = useState(localStorage.getItem("customer_token"));
  const [token2, setToken2] = useState(localStorage.getItem("new_token"));
  useEffect(() => {
    setToken(localStorage.getItem("customer_token"));
    setToken2(localStorage.getItem("new_token"));
    setContentManage(
      location.pathname === "/sign-up" ||
      location.pathname === "/verify-phone" ||
      location.pathname === "/forget-password" ||
      location.pathname === "/auth" ||
      location.pathname === "/" ||
      location.pathname === "/verify-email" ||
      location.pathname === "/auth-insight" ||
      location.pathname === "/reset-password" ||
      location.pathname === "/landing-page" ||
      location.pathname === "/register2"
    );
  }, [location]);

  const AuthenticatedRoute = ({ element: Element, ...rest }) => {
    return localStorage.getItem("accessToken") ? <Element {...rest} /> : <Navigate to="/" />;
  };

  return (
    <div className={`App ${contentManage ? "" : "has-header"}`}>
      <Provider store={store}>
        {(contentManage === false && localStorage.getItem("accessToken")) ? <Header /> : null}
        <div>
          <ToastContainer limit={1} />
        </div>
        <Routes>
          <Route path="/homepage" element={<AuthenticatedRoute element={Homepage} />} />
          <Route path="/benefits" element={<AuthenticatedRoute element={Benefits} />} />
          <Route path="/plans" element={<AuthenticatedRoute element={Plans} />} />
          <Route path="/copay-summary" element={<AuthenticatedRoute element={CopaySummary} />} />
          <Route path="/claims" element={<AuthenticatedRoute element={CareBenefits} />} />
          {/* <Route path="/claim-detail" element={<AuthenticatedRoute element={Homepage} /><ClaimExpand />} /> */}
          <Route path="/messages" element={<AuthenticatedRoute element={Messages} />} />
          <Route path="/open-message" element={<AuthenticatedRoute element={OpenMessage} />} />
          <Route path="/member-screen" element={<AuthenticatedRoute element={MemberScreen} />} />
          <Route path="/" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/landing-page" element={<Register />} />
          {/* <Route path="/register2" element={<Register />}/> */}
          <Route path="/contact-us" element={<AuthenticatedRoute element={ContactUs}/>} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/reset-password" element={<ResetPass />} />
          {/* <Route path="/verify-phone" element={<VerifyPhone />} /> */}
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/auth-insight" element={<AuthInsight />} />
          <Route path="/Loading-screen" element={<LoadingScreen />} />
          {/* <Route path="/auth" element={<Auth />} /> */}
          <Route path="/claim-detail" element={<AuthenticatedRoute element={ExpandClaimCard} />} />
          <Route path="/NetworkSummary" element={<AuthenticatedRoute element={NetworkSummary} />} />
          <Route path="/DrugSearch" element={<AuthenticatedRoute element={DrugSearch} />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        {!contentManage && <FixedFooter />}
      </Provider>
    </div>
  );
}

export default App;
