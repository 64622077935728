import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./Chart.scss";
import { useSelector } from "react-redux";

const Chart = () => {
  const graphBar = useSelector((state) => state.homepageSlice?.graph);
  const [data, setData] = useState();

  useEffect(() => {
    if(graphBar?.claim_count_details?.length>0){
      const maxValue = Math.max(...graphBar?.claim_count_details.map(item => item.total_count));
      setData({
        series: [
          {
            name: "Claims",
            data: graphBar?.claim_count_details?.map((item, index) => ({
              x: item?.month,
              y: item?.total_count,
              goals: [
                {
                  name: "Paid : ",
                  value: item?.paid,
                  strokeHeight: 0,
                  strokeColor: "transparent",
                },
                {
                  name: "In process : ",
                  value: item?.in_process,
                  strokeHeight: 0,
                  strokeColor: "transparent",
                },
                {
                  name: "Denied : ",
                  value: item?.deny,
                  strokeHeight: 0,
                  strokeColor: "transparent",
                },
              ].filter(goal => goal.value !== 0),
            })),
          },
        ],
  
        options: {
          // annotations: {
          //   points: [
          //     {
          //       x: "Bananas",
          //       seriesIndex: 0,
          //       label: {
          //         borderColor: "#775DD0",
          //         offsetY: 0,
          //         style: {
          //           color: "#fff",
          //           background: "#775DD0",
          //         },
          //         text: "Bananas are good",
          //       },
          //     },
          //   ],
          // },
          chart: {
            height: 225,
            type: "bar",
            zoom: {
              enabled: false
            }
          },
  
          plotOptions: {
            bar: {
              borderRadius: 0,
              columnWidth: "60%",
            },
          },
          dataLabels: {
            enabled: false,
          },
          // legend: {
          //   show: true,
          //   showForSingleSeries: true,
          //   customLegendItems: ["Actual", "Expected"],
          //   markers: {
          //     fillColors: ["#00E396", "#775DD0"],
          //   },
          // },
          // stroke: {
          //   width: 2,
          // },
  
          grid: {
            row: {
              colors: ["#fff"],
            },
            lines: {
              show: true,
            },
          },
          xaxis: {
            type: "category",
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            labels: {
              rotate: -45,
              style: {
                fontSize: "10px",
                fontWeight: 600,
                cssClass: "apexcharts-xaxis-label",
              },
            },
  
            tickPlacement: "on",
          },
          yaxis: {
            show: false,
            tickAmount: 9,
            max: maxValue + 4
            // title: {
            //     text: 'Servings',
            // },
          },
          tooltip: {
            style: {
              fontSize: '16px',
            },
            y: {
              formatter: function (value) {
                return Math.floor(value);
              },
              title: {
                formatter: function (seriesName) {
                  return seriesName;
                },
              },
            },
          },
          // fill: {
          //   type: "gradient",
          //   gradient: {
          //     shade: "light",
          //     type: "horizontal",
          //     shadeIntensity: 0.25,
          //     gradientToColors: undefined,
          //     inverseColors: true,
          //     opacityFrom: 0.85,
          //     opacityTo: 0.85,
          //     stops: [50, 0, 100],
          //   },
          // },
          fill: {
            colors: ["#18D6DC"],
            border: "0",
            opacity: "1",
          },
        },
      });
    }
  }, [graphBar]);

  return (
    <div id="chart" className="apex-chart-wrp">
      {graphBar?.claim_count_details?.length > 0 && data && (
        <ReactApexChart
          options={data?.options}
          series={data?.series}
          type="bar"
          height={window.innerWidth < 1920 ? 200 : 300}
        />
      )}
    </div>
  );
};

export default Chart;
