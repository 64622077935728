import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./ExpandClaimCard.scss";
import "../CostBreakdown/CostBreakdown.scss";
import "../../SecondHeader/SecondHeader.scss";
import "../ClaimExpand/ClaimExpand.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { encryption } from "../../../Encrypt";
import { claimIndexDetail } from "../../../store/slices/claimIndexDetails";
import Footer from "../../Footer/Footer";

const ExpandClaimCard = () => {
  let { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const claimDetail = useSelector(
    (state) => state.indexDetialSlice?.claimsDetails
  );

  /** Function for going bakc to previous page */
  const handleBack = () => {
    navigate(-1);
  };

  /** API to display Claim detail data */
  useEffect(() => {
    async function fetchMyAPI() {
      setIsLoading(true);
      let claim_num = state?.id;
      let num = await encryption(claim_num);
      let payload = {
        claim_number: num,
      };
      dispatch(claimIndexDetail({ setIsLoading, payload }));
    }
    fetchMyAPI();
  }, []);


  return (
    <>
      {isLoading ? (
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#240151"
          wrapperClass="loading-spin"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
        />
      ) : (
        <div className="claims-expand-wrap">
          <div className="card-headings">
            <div className="container">
              <span className="back-heading" onClick={()=>window.history.back()}>
                <i className="card-back-icon"></i>
              </span>
              <span className="card-head-text">Claims</span>
              <button
                type="button"
                className="medium-card-back"
                onClick={handleBack}
              >
                <i className="medium-card-icon"></i>
                Back
              </button>
            </div>
          </div>
          <div className="claim-expand-wrp main-claim-nw shadow-claim">
            {claimDetail ? (
              <div className="claim-container">
                <div className="claim-side-wrap">
                  <div className="side-claim-block">
                    <span className="side-claim-name">
                      Uptown Urgent Therapy
                    </span>
                    <span className="side-claim-number">
                      Claim Number : 000973281
                    </span>
                  </div>
                  <div className="side-claim-block">
                    <span className="side-claim-name">
                      Moment Physical Therapy
                    </span>
                    <span className="side-claim-number">
                      Claim Number : 000973281
                    </span>
                  </div>
                  <div className="side-claim-block">
                    <span className="side-claim-name">
                      Uptown Urgent Therapy
                    </span>
                    <span className="side-claim-number">
                      Claim Number : 000973281
                    </span>
                  </div>
                  <div className="side-claim-block">
                    <span className="side-claim-name">
                      Moment Physical Therapy
                    </span>
                    <span className="side-claim-number">
                      Claim Number : 000973281
                    </span>
                  </div>
                </div>
                <div className="claim-detail-wrapper expand-claim-detail">
                  <div className="head-insurnce">
                    <div className="insurance-head-wrppr">
                      <h2>{claimDetail?.provider_name}</h2>
                      <span className="payment-status">Paid</span>
                    </div>
                    <div className="claim-insuranced-wrp new-claim">
                      <div className="claim-main-wrap">
                        <ul>
                          <li>
                            <div className="claim-expand-key">
                              Claim Number{" "}
                              <span className="number-after">: </span>
                            </div>
                            <div className="claim-expand-value">
                              {claimDetail?.patient_details?.claim_number
                                ? claimDetail?.patient_details?.claim_number
                                : "- -"}
                            </div>
                          </li>
                          <li>
                            <div className="claim-expand-key">Patient Name</div>
                            <div className="claim-expand-value">
                              {claimDetail?.patient_details?.patient_name
                                ? claimDetail?.patient_details?.patient_name
                                : "- -"}
                            </div>
                          </li>
                          <li>
                            <div className="claim-expand-key">Service Date</div>
                            <div className="claim-expand-value">
                              {claimDetail?.patient_details?.service_date
                                ? claimDetail?.patient_details?.service_date
                                : "- -"}
                            </div>
                          </li>
                          <li>
                            <div className="claim-expand-key">Payment Date</div>
                            <div className="claim-expand-value">
                              {claimDetail?.patient_details?.payment_date}
                            </div>
                          </li>
                          <li>
                            <div className="claim-expand-key">Payer Name</div>
                            <div className="claim-expand-value">
                              {claimDetail?.patient_details?.payer_name
                                ? claimDetail?.patient_details?.payer_name
                                : null}
                            </div>
                          </li>
                        </ul>
                        <div className="claim-payment-wrap">
                          <span className="status-wrap">Status:</span>
                          <span
                            className="payment-status"
                            style={{
                              background:
                                claimDetail?.patient_details?.claim_colour,
                              whiteSpace: "nowrap",
                            }}
                          >
                            {claimDetail?.patient_details?.claim_status}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="cost-breakdown">
                    {claimDetail?.benefits_explained?.length>1 && <div className="breakdown-wrap">
                      <h2>Cost Breakdown Summary</h2>
                      <div className="cost-breakdown-columns ">
                        <ul>
                          <li>
                            <div className="claim-expand-key">
                              Billed Amount
                            </div>
                            <div className="claim-expand-value">
                              {claimDetail?.cost_break_down_summary
                                ?.provider_billed
                                ? claimDetail?.cost_break_down_summary
                                    ?.provider_billed
                                : null}
                            </div>
                          </li>
                          <li>
                            <div className="claim-expand-key">
                              Allowed Amount
                            </div>
                            <div className="claim-expand-value">
                              {claimDetail?.cost_break_down_summary
                                ? claimDetail?.cost_break_down_summary
                                    ?.allowed_amount
                                : null}
                            </div>
                          </li>
                          <li>
                            <div className="claim-expand-key">
                              Plan Payment Amount
                            </div>
                            <div className="claim-expand-value">
                              {claimDetail?.cost_break_down_summary
                                ? claimDetail?.cost_break_down_summary
                                    ?.plan_paid
                                : null}
                            </div>
                          </li>
                          <li>
                            <div className="claim-expand-key">
                              Member Co-pay
                            </div>
                            <div className="claim-expand-value">
                              {" "}
                              {claimDetail?.cost_break_down_summary
                                ? claimDetail?.cost_break_down_summary
                                    ?.member_copay
                                : null}
                            </div>
                          </li>
                          <li>
                            <div className="claim-expand-key">
                              Member Responsibility
                            </div>
                            <div className="claim-expand-value">
                              {claimDetail?.cost_break_down_summary
                                ? claimDetail?.cost_break_down_summary
                                    ?.member_responsibility
                                : null}
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>}
                    <div className="procedure-wrap">
                      <h2>Benefits Explained</h2>
                      {claimDetail &&
                      claimDetail?.benefits_explained?.length > 0
                        ? claimDetail?.benefits_explained?.map(
                            (item, index) => (
                              <ul key={index}>
                                <li>
                                  <div className="claim-expand-key">
                                    #
                                    {
                                      item?.procedure_details
                                        ?.procedure_serial_number
                                    }{" "}
                                    Procedure Code
                                  </div>
                                  <div className="claim-expand-value">
                                    {item?.procedure_details?.procedure_code}
                                  </div>
                                </li>
                                <li>
                                  <div className="claim-expand-key">
                                    Description
                                  </div>
                                  <div className="claim-expand-value">
                                    {item?.procedure_details?.description}
                                  </div>
                                </li>
                                <li>
                                  <div className="claim-expand-key">
                                    Service Date
                                  </div>
                                  <div className="claim-expand-value">
                                    {item?.procedure_details?.service_date}
                                  </div>
                                </li>
                                <li>
                                  <div className="claim-expand-key">
                                    Billed Amount
                                  </div>
                                  <div className="claim-expand-value">
                                    {item?.provider_billed}
                                  </div>
                                </li>
                                <li>
                                  <div className="claim-expand-key">
                                    Allowed Amount
                                  </div>
                                  <div className="claim-expand-value">
                                    {item?.allowed_amount}
                                  </div>
                                </li>
                                <li>
                                  <div className="claim-expand-key">
                                    Plan Payment Amount
                                  </div>
                                  <div className="claim-expand-value">
                                    {item?.plan_paid}
                                  </div>
                                </li>
                                <li>
                                  <div className="claim-expand-key">
                                    Member Co-pay
                                  </div>
                                  <div className="claim-expand-value">
                                    {item?.member_copay}
                                  </div>
                                </li>
                                <li>
                                  <div className="claim-expand-key">
                                    Member Responsibility
                                  </div>
                                  <div className="claim-expand-value">
                                    {item?.member_responsibility}
                                  </div>
                                </li>
                              </ul>
                            )
                          )
                        : null}
                    </div>
                  </div>
                </div>

                {/* {claimDetail?.benefits_explained?.length < 1 &&
                  claimDetail?.benefits_explained?.length == undefined ? (
                  <>
                    <h2>
                      Benefits <span className="summary">Explained</span>
                    </h2>
                    <div className="claims-wrppr">
                      {claimDetail?.benefits_explained.map((item, index) => (
                        <div className="claim-detail-wrapper" key={index}>
                          <h2>The Family Clinic</h2>
                          <div className="claim-header">
                            <div className="claim-icon-wrp">
                              <i className="icon-member"></i>
                              <div className="main-claim-txt">
                                <span className="claim-no">{index + 1} - </span>
                                <div className="claim-name">
                                  <h4>Procedure Code</h4>
                                  <h5>
                                    {item?.procedure_details
                                      ? item?.procedure_details?.procedure_code
                                      : null}
                                  </h5>
                                </div>
                              </div>
                            </div>
                            <div className="claim-detail">
                              <div className="payment-status service-date-wrp">
                                <span className="service">Service Date</span>
                                <span className="date">
                                  {item?.procedure_details
                                    ? item?.procedure_details?.service_date
                                    : null}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="desc-main-wrp new-des-wrp">
                            <h3>Description</h3>
                            <p>
                              {item?.procedure_details
                                ? item?.procedure_details?.description
                                : null}
                            </p>
                          </div>
                          <div className="cost-breakdown">
                            <h2>
                              Cost Breakdown{" "}
                              <span className="summary">Summary</span>
                            </h2>
                            <div className="cost-breakdown-columns">
                              <div className="product-wrp">
                                <div className="name">Billed Amount</div>
                                <div className="price">
                                  ${item ? item?.provider_billed : null}
                                </div>
                              </div>
                              <div className="product-wrp">
                                <div className="name">Allowed Amount</div>
                                <div className="price">
                                  ${item ? item?.allowed_amount : null}
                                </div>
                              </div>
                              <div className="product-wrp">
                                <div className="name">Plan Payment Amount</div>
                                <div className="price">
                                  ${item ? item?.plan_paid : null}
                                </div>
                              </div>
                              <div className="product-wrp">
                                <div className="name">Member Co-pay</div>
                                <div className="price">
                                  ${item ? item?.member_copay : null}
                                </div>
                              </div>
                              <div className="final-column">
                                <i className="icon-member"></i>
                                <h3>Member Responsibility</h3>
                                <div className="price">
                                  ${item ? item?.member_responsibility : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : null} */}
                <Footer />
              </div>
            ) : (
              <div className="claim-expand-wrp main-claim-nw shadow-claim">
                <div className="container no-data-container">
                  <div className="no-data">
                    <p>No data found</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ExpandClaimCard;
