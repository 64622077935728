import React from "react";
import CoverageTable from "../CoverageTable.js/CoverageTable";
import PlanHealth from "../PlanHealth/PlanHealth";
import "../PlanHealth/PlanHealth.scss";
import "./InNetwork.scss";
import { useSelector } from "react-redux";

const InNetwork = () => {
  const cardDetails = useSelector((state) => state.cardSlice?.benefitCard);

  return (
    <div className="network-wrp inner-netwrk">
      <div className="network-data">
        <div className="ltl-block">
          <div className="health-plan-head">
            <i className="icon-network"></i>
            <h3>In Network Benefits</h3>
          </div>
          <div className="health-range new-ui-health-range">
            <div className="individual-health-range">
              <div className="user-type">
                <i className="individual-icon"></i>
                Individual Plan
              </div>
              <div className="type-plans">
                <div className="plan-wrp plan-one">
                  {/* <PlanHealth /> */}
                  <div className="progress-main">
                    <div className="progress-left progress-bar">
                      <h4>Deductible</h4>
                      <div className="progress-wrp">
                        <div
                          className="actual-progress"
                          style={{
                            width:
                              cardDetails?.benefits_summary_details
                                ?.in_networks_details?.individual_plan
                                ?.deductible?.utilization_percentage !== null
                                ? cardDetails?.benefits_summary_details
                                    ?.in_networks_details?.individual_plan
                                    ?.deductible?.utilization_percentage + "%"
                                : "0",
                          }}
                        ></div>
                      </div>
                      <div className="progress-value">
                        <span className="value">
                          {(cardDetails?.benefits_summary_details
                            ?.in_networks_details?.individual_plan?.deductible
                            ?.max_amount === "0" || cardDetails?.benefits_summary_details
                            ?.in_networks_details?.individual_plan?.deductible
                            ?.max_amount === "Not Applicable")
                            ? "Not Applicable"
                            : cardDetails?.benefits_summary_details
                                ?.in_networks_details?.individual_plan
                                ?.deductible?.current_amount === "0"
                            ? cardDetails?.benefits_summary_details
                                ?.in_networks_details?.individual_plan
                                ?.deductible?.current_amount
                            : cardDetails?.benefits_summary_details
                                ?.in_networks_details?.individual_plan
                                ?.deductible?.current_amount}
                          {/* $927.44 */}
                        </span>
                        <span className="value">
                          {(cardDetails?.benefits_summary_details
                            ?.in_networks_details?.individual_plan?.deductible
                            ?.max_amount === "0" || cardDetails?.benefits_summary_details
                            ?.in_networks_details?.individual_plan?.deductible
                            ?.max_amount === "Not Applicable")
                            ? null
                            : cardDetails?.benefits_summary_details
                                ?.in_networks_details?.individual_plan
                                ?.deductible?.max_amount}
                          {/* $5000.00 */}
                        </span>
                      </div>
                    </div>
                    <div className="progress-left progress-bar">
                      <h4>Out of Pocket Max</h4>
                      <div className="progress-wrp">
                        <div
                          className="actual-progress"
                          style={{
                            width:
                              cardDetails?.benefits_summary_details
                                ?.in_networks_details?.individual_plan
                                ?.out_of_pocket_max?.utilization_percentage !==
                              null
                                ? cardDetails?.benefits_summary_details
                                    ?.in_networks_details?.individual_plan
                                    ?.out_of_pocket_max
                                    ?.utilization_percentage + "%"
                                : "0",
                          }}
                        ></div>
                      </div>
                      <div className="progress-value">
                        <span className="value">
                          {(cardDetails?.benefits_summary_details
                            ?.in_networks_details?.individual_plan
                            ?.out_of_pocket_max?.max_amount === "0" || cardDetails?.benefits_summary_details
                            ?.in_networks_details?.individual_plan
                            ?.out_of_pocket_max?.max_amount === "Not Applicable")
                            ? "Not Applicable"
                            : cardDetails?.benefits_summary_details
                                ?.in_networks_details?.individual_plan
                                ?.out_of_pocket_max?.current_amount === "0"
                            ? cardDetails?.benefits_summary_details
                                ?.in_networks_details?.individual_plan
                                ?.out_of_pocket_max?.current_amount
                            : cardDetails?.benefits_summary_details
                                ?.in_networks_details?.individual_plan
                                ?.out_of_pocket_max?.current_amount}
                          {/* $927.44 */}
                        </span>
                        <span className="value">
                          {(cardDetails?.benefits_summary_details
                            ?.in_networks_details?.individual_plan
                            ?.out_of_pocket_max?.max_amount === "0" || cardDetails?.benefits_summary_details
                            ?.in_networks_details?.individual_plan
                            ?.out_of_pocket_max?.max_amount === "Not Applicable")
                            ? null
                            : cardDetails?.benefits_summary_details
                                ?.in_networks_details?.individual_plan
                                ?.out_of_pocket_max?.max_amount}
                          {/* $5000.00 */}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className='plan-wrp'>
                                    <PlanHealth />
                                </div> */}
              </div>
            </div>
            {cardDetails?.benefits_summary_details?.in_networks_details
              ?.family_plan !== null &&
            cardDetails?.member_details?.coverage !== "Employee Only" ? (
              <div className="family-health-range">
                <div className="user-type">
                  <i className="family-icon"></i>
                  Family's Plan
                </div>
                <div className="type-plans">
                  <div className="plan-wrp plan-one">
                    {/* <PlanHealth /> */}
                    <div className="progress-main">
                      <div className="progress-left progress-bar">
                        <h4>Deductible</h4>
                        <div className="progress-wrp">
                          <div
                            className="actual-progress"
                            style={{
                              width:
                                cardDetails?.benefits_summary_details
                                  ?.in_networks_details?.family_plan?.deductible
                                  ?.utilization_percentage !== null
                                  ? cardDetails?.benefits_summary_details
                                      ?.in_networks_details?.family_plan
                                      ?.deductible?.utilization_percentage + "%"
                                  : "0",
                            }}
                          ></div>
                        </div>
                        <div className="progress-value">
                          <span className="value">
                            {(cardDetails?.benefits_summary_details
                              ?.in_networks_details?.family_plan?.deductible
                              ?.max_amount === "0" || cardDetails?.benefits_summary_details
                              ?.in_networks_details?.family_plan?.deductible
                              ?.max_amount === "Not Applicable")
                              ? "Not Applicable"
                              : cardDetails?.benefits_summary_details
                                  ?.in_networks_details?.family_plan?.deductible
                                  ?.current_amount === "0"
                              ? cardDetails?.benefits_summary_details
                                  ?.in_networks_details?.family_plan?.deductible
                                  ?.current_amount
                              : cardDetails?.benefits_summary_details
                                  ?.in_networks_details?.family_plan?.deductible
                                  ?.current_amount === "0"
                              ? cardDetails?.benefits_summary_details
                                  ?.in_networks_details?.family_plan?.deductible
                                  ?.current_amount
                              : cardDetails?.benefits_summary_details
                                  ?.in_networks_details?.family_plan?.deductible
                                  ?.current_amount}
                            {/* $927.44 */}
                          </span>
                          <span className="value">
                            {(cardDetails?.benefits_summary_details
                              ?.in_networks_details?.family_plan?.deductible
                              ?.max_amount === "0" || cardDetails?.benefits_summary_details
                              ?.in_networks_details?.family_plan?.deductible
                              ?.max_amount === "Not Applicable")
                              ? null
                              : cardDetails?.benefits_summary_details
                                  ?.in_networks_details?.family_plan?.deductible
                                  ?.max_amount}
                            {/* $5000.00 */}
                          </span>
                        </div>
                      </div>
                      <div className="progress-left progress-bar">
                        <h4>Out of Pocket Max</h4>
                        <div className="progress-wrp">
                          <div
                            className="actual-progress"
                            style={{
                              width:
                                cardDetails?.benefits_summary_details
                                  ?.in_networks_details?.family_plan
                                  ?.out_of_pocket_max
                                  ?.utilization_percentage !== null
                                  ? cardDetails?.benefits_summary_details
                                      ?.in_networks_details?.family_plan
                                      ?.out_of_pocket_max
                                      ?.utilization_percentage + "%"
                                  : "0",
                            }}
                          ></div>
                        </div>
                        <div className="progress-value">
                          <span className="value">
                            {(cardDetails?.benefits_summary_details
                              ?.in_networks_details?.family_plan
                              ?.out_of_pocket_max?.max_amount === "0" || cardDetails?.benefits_summary_details
                              ?.in_networks_details?.family_plan
                              ?.out_of_pocket_max?.max_amount === "Not Applicable")
                              ? "Not Applicable"
                              : cardDetails?.benefits_summary_details
                                  ?.in_networks_details?.family_plan
                                  ?.out_of_pocket_max?.current_amount === "0"
                              ? cardDetails?.benefits_summary_details
                                  ?.in_networks_details?.family_plan
                                  ?.out_of_pocket_max?.current_amount
                              : cardDetails?.benefits_summary_details
                                  ?.in_networks_details?.family_plan
                                  ?.out_of_pocket_max?.current_amount}
                            {/* $927.44 */}
                          </span>
                          <span className="value">
                            {(cardDetails?.benefits_summary_details
                              ?.in_networks_details?.family_plan
                              ?.out_of_pocket_max?.max_amount === "0" || cardDetails?.benefits_summary_details
                              ?.in_networks_details?.family_plan
                              ?.out_of_pocket_max?.max_amount === "Not Applicable")
                              ? null
                              : cardDetails?.benefits_summary_details
                                  ?.in_networks_details?.family_plan
                                  ?.out_of_pocket_max?.max_amount}
                            {/* $5000.00 */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className='plan-wrp'>
                                    <PlanHealth />
                                </div> */}
                </div>
              </div>
            ) : null}
            {cardDetails?.benefits_summary_details?.in_networks_details
              ?.coverage_details?.length > 0 ? (
              <div className="cover-table">
                <CoverageTable />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InNetwork;
