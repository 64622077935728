import React from 'react'
import './LoadingScreen.scss'
const LoadingScreen = () => {
    return (
        <div className='loading-screen'>
            <header className='header'>
                <div className='container'>
                    <div className='left-block'>

                    </div>
                    <div className='right-block'>

                    </div>
                </div>
            </header>
            <div className='content'>
                <div className='container'>
                    <div className='content-inner'>
                        <div className='info-head'>
                            <div className='container'>
                                <div className='left-block'>

                                </div>
                                <div className='right-block'>

                                </div>
                            </div>
                        </div>
                        <div className='id-info'>
                            <div className='container'>
                                <div className='left-block'>

                                </div>
                                <div className='middle-block'>

                                </div>
                                <div className='right-block'>

                                </div>
                            </div>
                        </div>
                        <div className='banner-wrap'>
                            <div className='container'>
                                <div className='banner-block'>
                                    <div className='left'>

                                    </div>
                                    <div className='rgt'>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='quick-access'>
                            <div className='container'>
                                <div className='quick-access-box'>
                                    <div className='quick-flow'>
                                        <div className='wait-box'>
                                            <p>Please wait
                                                <span className='sub-text'> Your data is loading...</span>
                                            </p>
                                        </div>

                                        <div className='quick-line line-one'>

                                        </div>
                                        <div className='quick-line'>
                                            <button className='mob-hide quick-btn'></button>

                                        </div>
                                    </div>
                                    <div className='btn-box'>

                                    </div>
                                    <div className='footer-banner'>
                                        <div className='container'>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default LoadingScreen