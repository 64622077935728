import React, { useState } from "react";
import { useSelector } from "react-redux";
import "./CoverageTable.scss";

const CoverageTable = () => {
  const [data, setData] = useState(true);
  const cardDetails = useSelector((state) => state.cardSlice?.benefitCard);
  const handleChange = () => {
    setData((prev) => !prev);
  };

  return (
    <div className="coverage-table-wrp">
      <div className="cover-head">
        <h3>Coverage Details</h3>
        <div
          className={`accordion-arrow ${data ? "active" : ""}`}
          onClick={handleChange}
        ></div>
      </div>
      <div className={`table ${data ? "active-accordion" : ""}`}>
        <div className="data main-head">
          <div className="value head primary">Types</div>
          <div className="value head">Co-pay</div>
          <div className="value head">Co-insurance</div>
        </div>
        {cardDetails?.benefits_summary_details?.in_networks_details
          ? cardDetails?.benefits_summary_details?.in_networks_details?.coverage_details.map(
              (item, index) => (
                <div className="data" key={index}>
                  <div className="value primary">{item?.details_type}</div>
                  <div className="value ">{item?.co_pay}</div>
                  <div className="value ">{item?.co_insurance}</div>
                </div>
              )
            )
          : null}
      </div>
    </div>
  );
};

export default CoverageTable;
