import React, { useState } from "react";
import Chart from "../Chart/Chart";
import "./HealthPlan.scss";
import { useSelector } from "react-redux";

const HealthPlan = () => {
  const healthData = useSelector((state) => state.homepageSlice?.planInfo);
  const graphBar = useSelector((state) => state.homepageSlice?.graph);

  return (
    <div className="health-plan-wrp paln-wrp">
      <div className="health-plan-head">
        <h3>Health Plan Summary</h3>
      </div>
      {healthData?.network_detail.family_plan == "" ||
      healthData?.network_detail.family_plan == null ||
      healthData?.network_detail.family_plan == undefined ? (
        <div className="health-body">
          <div className="progress-main">
            <div className="progress-left progress-bar deductible">
              <h4>Deductible</h4>
              <div className="progress-wrp">
                <div
                  className="actual-progress"
                  style={{
                    width:
                      healthData?.network_detail?.individual_plan?.deductible
                        ?.utilization_percentage !== null
                        ? healthData?.network_detail?.individual_plan
                            ?.deductible?.utilization_percentage + "%"
                        : "0",
                  }}
                ></div>
              </div>
              <div className="progress-value">
                <span className="value">
                  {/* $ */}
                  {(healthData?.network_detail?.individual_plan?.deductible
                    ?.max_amount === "0" || healthData?.network_detail?.individual_plan?.deductible
                    ?.max_amount === "Not Applicable")
                    ? "Not Applicable"
                    : healthData?.network_detail?.individual_plan?.deductible
                        ?.current_amount === "0"
                    ? healthData?.network_detail?.individual_plan?.deductible
                        ?.current_amount
                    : healthData?.network_detail?.individual_plan?.deductible
                        ?.current_amount}
                </span>
                <span className="value">
                  {/* $ */}
                  {(healthData?.network_detail?.individual_plan?.deductible
                    ?.max_amount === "0" || healthData?.network_detail?.individual_plan?.deductible
                    ?.max_amount === "Not Applicable")
                    ? null
                    : healthData?.network_detail?.individual_plan?.deductible
                        ?.max_amount}
                </span>
              </div>
            </div>
            <div className="progress-right progress-bar">
              <h4>Out of Pocket Max</h4>
              <div className="progress-wrp">
                <div
                  className="actual-progress"
                  style={{
                    width:
                      healthData?.network_detail?.individual_plan
                        ?.out_of_pocket_max?.utilization_percentage !== null
                        ? healthData?.network_detail?.individual_plan
                            ?.out_of_pocket_max?.utilization_percentage + "%"
                        : "0",
                  }}
                ></div>
              </div>
              <div className="progress-value">
                <span className="value">
                  {/* $ */}
                  {(healthData?.network_detail?.individual_plan
                    ?.out_of_pocket_max?.max_amount === "0" || healthData?.network_detail?.individual_plan
                    ?.out_of_pocket_max?.max_amount === "Not Applicable")
                    ? "Not Applicable"
                    : healthData?.network_detail?.individual_plan
                        ?.out_of_pocket_max?.current_amount === "0"
                    ? healthData?.network_detail?.individual_plan
                        ?.out_of_pocket_max?.current_amount
                    : healthData?.network_detail?.individual_plan
                        ?.out_of_pocket_max?.current_amount}
                </span>
                <span className="value">
                  {/* $ */}
                  {(healthData?.network_detail?.individual_plan
                    ?.out_of_pocket_max?.max_amount === "0" || healthData?.network_detail?.individual_plan
                    ?.out_of_pocket_max?.max_amount === "Not Applicable")
                    ? null
                    : healthData?.network_detail?.individual_plan
                        ?.out_of_pocket_max?.max_amount}
                </span>
              </div>
            </div>
            {/* <button className="btn">See All Claims</button> */}
          </div>
          <div className="chart-wrp">
            {/* <div className="chart-claim-status">
            <div className="number-claims-wrap">
              <span className="claim-dot"></span>
              <span className="claim-no">3</span>
              <span className="claim-letter">Claims</span>
            </div>
            <span className="claim-type">
              Paid :<span className="claim-type-value">1</span>
            </span>
            <span className="claim-type">
              Void :<span className="claim-type-value">1</span>
            </span>
            <span className="claim-type">
              Denied :<span className="claim-type-value">1</span>
            </span>
          </div> */}
            <div className="chart-head">
              <div className="select-wrp">
                {/* <span className="year-title">Year</span>
                <span className="year-value">2023</span> */}
              </div>
            </div>
            <Chart />
          </div>
        </div>
      ) : (
        <div className="health-body">
          <div className="progress-main">
            <div className="progress-left progress-bar deductible">
              <h4>Deductible</h4>
              <div className="progress-wrp">
                <div
                  className="actual-progress"
                  style={{
                    width:
                      healthData?.network_detail?.family_plan?.deductible
                        ?.utilization_percentage !== null
                        ? healthData?.network_detail?.family_plan?.deductible
                            ?.utilization_percentage + "%"
                        : "0",
                  }}
                ></div>
              </div>
              <div className="progress-value">
                <span className="value">
                  {/* $ */}
                  {(healthData?.network_detail?.family_plan?.deductible
                    ?.max_amount === "0" || healthData?.network_detail?.family_plan?.deductible
                    ?.max_amount === "Not Applicable")
                    ? "Not Applicable"
                    : healthData?.network_detail?.family_plan?.deductible
                        ?.current_amount === "0"
                    ? healthData?.network_detail?.family_plan?.deductible
                        ?.current_amount
                    : healthData?.network_detail?.family_plan?.deductible
                        ?.current_amount}
                </span>
                <span className="value">
                  {/* $ */}
                  {(healthData?.network_detail?.family_plan?.deductible
                    ?.max_amount === "0" || healthData?.network_detail?.family_plan?.deductible
                    ?.max_amount === "Not Applicable")
                    ? null
                    : healthData?.network_detail?.family_plan?.deductible
                        ?.max_amount}
                </span>
              </div>
            </div>
            <div className="progress-right progress-bar">
              <h4>Out of Pocket Max</h4>
              <div className="progress-wrp">
                <div
                  className="actual-progress"
                  style={{
                    width:
                      healthData?.network_detail?.family_plan?.out_of_pocket_max
                        ?.utilization_percentage !== null
                        ? healthData?.network_detail?.family_plan
                            ?.out_of_pocket_max?.utilization_percentage + "%"
                        : "0",
                  }}
                ></div>
              </div>
              <div className="progress-value">
                <span className="value">
                  {/* $ */}
                  {(healthData?.network_detail?.family_plan?.out_of_pocket_max
                    ?.max_amount === "0" || healthData?.network_detail?.family_plan?.out_of_pocket_max
                    ?.max_amount === "Not Applicable")
                    ? "Not Applicable"
                    : healthData?.network_detail?.family_plan?.out_of_pocket_max
                        ?.current_amount === "0"
                    ? healthData?.network_detail?.family_plan?.out_of_pocket_max
                        ?.current_amount
                    : healthData?.network_detail?.family_plan?.out_of_pocket_max
                        ?.current_amount}
                </span>
                <span className="value">
                  {/* $ */}
                  {(healthData?.network_detail?.family_plan?.out_of_pocket_max
                    ?.max_amount === "0" || healthData?.network_detail?.family_plan?.out_of_pocket_max
                    ?.max_amount === "Not Applicable")
                    ? null
                    : healthData?.network_detail?.family_plan?.out_of_pocket_max
                        ?.max_amount}
                </span>
              </div>
            </div>
            {/* <button className="btn">See All Claims</button> */}
          </div>
          <div className="chart-wrp">
            {/* <div className="chart-claim-status">
          <div className="number-claims-wrap">
            <span className="claim-dot"></span>
            <span className="claim-no">3</span>
            <span className="claim-letter">Claims</span>
          </div>
          <span className="claim-type">
            Paid :<span className="claim-type-value">1</span>
          </span>
          <span className="claim-type">
            Void :<span className="claim-type-value">1</span>
          </span>
          <span className="claim-type">
            Denied :<span className="claim-type-value">1</span>
          </span>
        </div> */}
            <div className="chart-head">
              <div className="select-wrp">
                <span className="year-title">Year</span>
                <span className="year-value">{graphBar?.plan_year}</span>
              </div>
            </div>
            <Chart />
          </div>
        </div>
      )}
    </div>
  );
};

export default HealthPlan;
