import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { searchMsg } from "../../store/slices/messages";
import ReactPaginate from "react-paginate";
import "../MessageCardInbox/MessageCardInbox.scss";
import '../MessageCardArchive/MessageCardArchive.scss'

const SearchMessageCardArchive = ({searchData,setIsSearch}) => {
    const dispatch = useDispatch();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const msgSearch = useSelector((state) => state.messageSlice?.searchResult);
  const [pageCount, setPageCount] = useState(
    Math.ceil(msgSearch?.total_archive_messages / itemsPerPage)
  );

  useEffect(() => {
    setPageCount(Math.ceil(msgSearch?.total_archive_messages / itemsPerPage));
  }, [itemsPerPage, msgSearch]);

  const handlePageClick = async(event) =>{
    const payload = {
      pageNumber:event.selected,
      recordsPerPage:itemsPerPage
    }
    payload.message_header = searchData;
      setIsSearch(true);
      dispatch(searchMsg({ payload, setIsSearch }));
  }

  const pageChange = async (e) => {
    setItemsPerPage(e.target.value);
    const payload = {
      pageNumber:0,
      recordsPerPage:e.target.value
    }
    payload.message_header = searchData;
      setIsSearch(true);
      dispatch(searchMsg({ payload, setIsSearch }));
  }
  return (
    <div>
              {msgSearch?.archive_messages &&
              msgSearch?.archive_messages?.length > 0 ? (
                msgSearch?.archive_messages?.map((item, index) => (
                  <Link
                    to="/open-message"
                    className="inbox-card"
                    key={index}
                    state={{ id: item?.message_id }}
                  >
                    <div className="claim-wrp first-claim-wrap">
                      <div className="claim-name">
                      <div className="top-new-msz archive-time-wrap">
                          <div className="message-head">
                            <div className="rgt-icon">{item?.time}</div>
                          </div>
                          <div className="message-head">
                            <div className="admin-wrp">
                              <span className="from-admin">
                                From {item?.from}
                              </span>
                              {item?.status === "NEW" ? (
                                <span className="new">{item?.status}</span>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="bottom-new-msz">
                          <h3>{item?.message_header}</h3>
                          <p>{item?.message_content}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
              ) : (
                <div className="default-msg-popup"><h3>No data found</h3></div>
              )}
              <div className="pagintion message-pagination">
                {msgSearch?.total_archive_messages &&
                msgSearch?.total_archive_messages > 10 ? (
                  <div className="entry-wrp">
                    <span className="entry-text">show entries</span>
                    <div className="select-wrap">
                      <select
                        value={itemsPerPage}
                        onChange={(e) => pageChange(e)}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                      </select>
                      <i className="icon-arrow"></i>
                    </div>
                  </div>
                ) : null}
                {msgSearch?.total_archive_messages &&
                msgSearch?.total_archive_messages > 10 ? (
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel=""
                    onPageChange={handlePageClick}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={window.innerWidth<767 ? 1 : 2}
                    pageCount={pageCount}
                    previousLabel=""
                    renderOnZeroPageCount={null}
                    className="pagination-list"
                  />
                ) : null}
              </div>
            </div>
  )
}

export default SearchMessageCardArchive