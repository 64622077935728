import React, { useEffect, useState } from "react";
import "./Messages.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Wave from "../../assets/images/ihp-life 1.png";
import MessageCardInbox from "../../components/MessageCardInbox/MessageCardInbox";
import MessageCardArchive from "../../components/MessageCardArchive/MessageCardArchive";
import Footer from "../../components/Footer/Footer";
import { useDispatch } from "react-redux";
import { messageList, searchMsg } from "../../store/slices/messages";
import { ThreeDots } from "react-loader-spinner";
import { encryption } from "../../Encrypt";

const Messages = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const dispatch = useDispatch();
  const [searchData, setSearchData] = useState(null)

  /**Function to call api for displaying messages list */
  // useEffect(() => {
  //   setIsLoading(true);
  //   let payload = {
  //     pageNumber: pageNo,
  //     recordsPerPage: perPage,
  //   };
  //   dispatch(messageList({ setIsLoading, payload }));
  // }, []);

  /**Function to call api for message search list */
  const handleSearch = async (e) => {
    if (e.target.value.length !== 0) {
      let data = await encryption(e.target.value);
      setSearchData(data);
      let payload = {
        message_header: data,
        pageNumber:0,
        recordsPerPage:10
      }
      setIsSearch(true);
      dispatch(searchMsg({ payload, setIsSearch }));
    } else {
      setIsSearch(false);
    }
  };


  return (
    <>
      {isLoading ? (
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#240151"
          wrapperClass="loading-spin"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
        />
      ) : (
        <div className="message-wrap">
          <div className="header-banner">
            <div className="container">
              <h1>
                Messages <span className="msh-head">. {tabIndex===0?'Inbox':'Archive'}</span>
              </h1>
            </div>
          </div>
          <div className="card-headings">
            <div className="container">
              <span className="back-heading" onClick={()=>window.history.back()}>
                <i className="card-back-icon"></i>
              </span>
              <span className="card-head-text">Messages</span>
            </div>
          </div>
          <div className="message-container-wrap">
            <div className="container">
              <div className="tabbing-wrap">
                <Tabs
                  selectedIndex={tabIndex}
                  onSelect={(index) => setTabIndex(index)}
                >
                  <TabList>
                    <Tab>Inbox</Tab>
                    <Tab>Archive</Tab>
                  </TabList>
                  <div className="search-wrp">
                    <input
                      type="text"
                      className="search-input"
                      placeholder="Search..."
                      onChange={(e) => handleSearch(e)}
                    />
                    <button type="button" className="search-btn">
                      <i className="search-icon"></i>
                    </button>
                  </div>
                  <TabPanel>
                    <div className="tab-content">
                      <MessageCardInbox
                        tabIndex={tabIndex}
                        isSearch={isSearch}
                        setPageNo={setPageNo}
                        setPerPage={setPerPage}
                        searchData={searchData}
                        setIsSearch={setIsSearch}
                      />
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="tab-content">
                      <MessageCardArchive
                        tabIndex={tabIndex}
                        isSearch={isSearch}
                        setPageNo={setPageNo}
                        setPerPage={setPerPage}
                        searchData={searchData}
                        setIsSearch={setIsSearch}
                      />
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Messages;
