import React from "react";
import "./Popup.scss";
export const Popup = () => {
  return (
    <div className="popup">
      <div className="popup-wrap">
        <div className="poup-content">
          <div className="auth-success">
            <i className="icon-success">
              <svg
                width="57"
                height="57"
                viewBox="0 0 57 57"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M28.6367 0.791016C44.1006 0.791016 56.6367 13.327 56.6367 28.791C56.6367 44.2549 44.1006 56.791 28.6367 56.791C13.1727 56.791 0.636719 44.2549 0.636719 28.791C0.636719 13.327 13.1727 0.791016 28.6367 0.791016ZM28.6367 4.99102C15.4923 4.99102 4.83672 15.6466 4.83672 28.791C4.83672 41.9353 15.4923 52.591 28.6367 52.591C41.781 52.591 52.4367 41.9353 52.4367 28.791C52.4367 15.6466 41.781 4.99102 28.6367 4.99102ZM25.1367 32.8211L37.6519 20.3061C38.472 19.486 39.8014 19.486 40.6216 20.3061C41.3672 21.0516 41.435 22.2183 40.8248 23.0404L40.6216 23.2759L26.6216 37.2759C25.8762 38.0215 24.7094 38.0893 23.8874 37.4791L23.6519 37.2759L16.6518 30.2759C15.8317 29.4557 15.8317 28.1263 16.6518 27.3062C17.3974 26.5605 18.564 26.4928 19.3861 27.1029L19.6216 27.3062L25.1367 32.8211Z"
                  fill="#00D0BE"
                />
              </svg>
            </i>
            <h3>Authentication Successful!</h3>
            <p>
              Your authentication has been successful. You can now access the
              system, application, or platform with the privileges and
              permissions granted to your account....
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
