import React from "react";
import { useSelector } from "react-redux";
import "./Popup.scss";

export const DeletePopUp = ({ setDeleteAccount, setDeleteConfirm }) => {
  const registration = useSelector((state) => state?.signupSlice?.register);

  return (
    <div className="popup delete-popup">
      <div className="popup-wrap">
        <div className="poup-content">
          <button
            className="close-popup"
            onClick={() => setDeleteAccount(false)}
          ></button>
          <div className="auth-fail">
            <i className="icon-success">
              <svg
                width="56"
                height="57"
                viewBox="0 0 56 57"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.998 0.501953C43.4619 0.501953 55.998 13.038 55.998 28.502C55.998 43.9658 43.4619 56.502 27.998 56.502C12.5341 56.502 -0.00195312 43.9658 -0.00195312 28.502C-0.00195312 13.038 12.5341 0.501953 27.998 0.501953ZM27.998 4.70195C14.8537 4.70195 4.19805 15.3576 4.19805 28.502C4.19805 41.6463 14.8537 52.302 27.998 52.302C41.1424 52.302 51.798 41.6463 51.798 28.502C51.798 15.3576 41.1424 4.70195 27.998 4.70195Z"
                  fill="#FFD37E"
                />
                <path
                  d="M25.7422 31.5098V16.4707C25.7422 15.2248 26.7522 14.2148 27.998 14.2148C29.2439 14.2148 30.2539 15.2248 30.2539 16.4707V31.5098C30.2539 32.7556 29.2439 33.7656 27.998 33.7656C26.7522 33.7656 25.7422 32.7556 25.7422 31.5098ZM31.0059 39.7812C31.0059 41.4425 29.6593 42.7891 27.998 42.7891C26.3368 42.7891 24.9902 41.4425 24.9902 39.7812C24.9902 38.12 26.3368 36.7734 27.998 36.7734C29.6593 36.7734 31.0059 38.12 31.0059 39.7812Z"
                  fill="#FFD37E"
                />
              </svg>
            </i>
            <h3>Are You Sure?</h3>
            <p>
              Your account has been marked for deletion. This action will initiate a 30-day grace period during which your account will be inactive but not permanently deleted. You can restore access to your account anytime within these 30 days by login again. After the 30-day period, your account and all associated data will be permanently deleted and cannot be recovered.
            </p>

            <div className="btn-wrap">
              <button
                className="btn yes-btn"
                type="button"
                onClick={() => {
                  setDeleteAccount(false);
                  setDeleteConfirm(true);
                }}
              >
                Yes
              </button>
              <button
                className="btn no-btn"
                onClick={() => setDeleteAccount(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
