import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import wave from "../../../assets/images/tressback-ground.png";
import "../SignIn/SignIn.scss";
import "../VerifyPhone/VerifyPhone.scss";
import "./VerifyEmail.scss";
import ReactCodeInput from "react-verification-code-input";
import { useDispatch } from "react-redux";
import { emailVerify, reVerifyEmail } from "../../../store/slices/signUp";
import { ThreeCircles, ThreeDots } from "react-loader-spinner";
import { encryptionObj, encryption } from "../../../Encrypt";
import blueLogo from "../../../assets/images/head-logo-new.svg";
import orangeLogo from "../../../assets/images/orange-logo.svg";

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [timer, setTimer] = useState(30);
  const [key, setKey] = useState(0); // Key state to force re-render
  const interval = useRef();
  const countdown = () => setTimer((prev) => prev - 1);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  let email = localStorage.getItem("signUpEmail");

  /** Function for showing 30 second countdown*/
  useEffect(() => {
    if(!email){
      window.location.href = "/"
    }
    if (timer > 0) {
      interval.current = setInterval(countdown, 1000);
      return () => clearInterval(interval.current);
    }
  }, [timer]);

  /** Function to verify otp on input complete */
  const verifyOtp = async (val) => {
    setOtp(val);
    let data = {
      email: localStorage.getItem("signUpEmail"),
      phone_number: localStorage.getItem("signPhone"),
      code: val,
    };
    let finalData = await encryptionObj(data);

    let payload = {data:finalData};
    setIsDisabled(true);
    setIsLoading(true);
    dispatch(emailVerify({ payload, setIsLoading, navigate, setIsDisabled }));
  };

  /** Function to verify otp on button click */
  const handleVerify = async (e) => {
    e.preventDefault();
    if (otp.length < 4) {
      setOtpError(true);
    } else {
      let data = {
        email: localStorage.getItem("signUpEmail"),
        phone_number: localStorage.getItem("signPhone"),
        code: otp,
      };
      let finalData = await encryptionObj(data);

      let payload = {data: finalData};
      setIsDisabled(true);
      setIsLoading(true);
      dispatch(emailVerify({ payload, setIsLoading, navigate, setIsDisabled }));
    }
  };

  /** Function for going back on previous page */
  const handleBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  /** Function for change input values for otp */
  const handleChange = (e) => {
    setOtp(e);
    setOtpError(false);
  };

  /** Function for resend otp on email */
  const resendOTP = async () => {
    setIsLoading(true);
    let payload = await encryption(localStorage.getItem("signUpEmail"));
    dispatch(reVerifyEmail({ payload, setIsLoading,setTimer }));
    setOtp(""); // Clear the OTP state
    setKey(prevKey => prevKey + 1); // Change the key to force re-render
  };

  return (
    <>
      {isLoading && (
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#240151"
          wrapperClass="loading-spin"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
        />
      )}
      <div className="signin-form-wrapper forget-password-form verify-wrap ">
        <div className="wave-wrap">
          <img src={wave} alt="wave" />
        </div>
        <div className="colored-background">
          <Link to="/" className="logo">
            <img src={blueLogo} alt="logo" />
          </Link>
        </div>
        <div className="right-signin-wrp">
          <form>
            <div className="signin-wrp block-signin-wrp">
              <button
                className="bck-btn-wrap"
                onClick={handleBack}
                type="button"
              >
                <i className="icon-back"></i>
                Back
              </button>
              <Link to="/" className="logo">
                <img src={orangeLogo} alt="logo" />
              </Link>
              <div className="signin-form-wrp">
                <h1>Verify your Email</h1>
                <p>
                  Please enter verification code we’ve sent on your email
                  address <strong>{email}</strong>.
                </p>
                <div className="form-wrap">
                  <label className="form-label mob-hide">
                    Enter Code Received on Email
                  </label>
                  <div className="new-wrp-num-field">
                    <div className="form-number-field">
                      <ReactCodeInput
                        key={key} // Use the key to force re-render
                        fields={4}
                        disabled={isLoading ? true : false}
                        onChange={(e) => handleChange(e)}
                        onComplete={verifyOtp}
                        className="code-input-wrp"
                      />
                    </div>
                    {otpError ? (
                      <p className="error">Please enter the OTP</p>
                    ) : null}
                  </div>
                  {timer !== 0 ? (
                    <div className="timer-new">
                      <span className="show-count">
                        {/* <Link
                          to="#"
                          style={{ color: "#240151" }}
                          onClick={resendOTP}
                        >
                          Resend OTP
                        </Link> */}
                        <span className="timer">00:{timer}</span>
                      </span>
                    </div>
                  ) : null}
                  {timer === 0 ? <div className="timer-wrp">
                    Didn’t receive a code?{" "}
                    <Link
                      to="#"
                      style={{ color: "#540BE0" }}
                      onClick={resendOTP}
                    >
                      Resend Now
                    </Link>
                  </div> : null}
                </div>
              </div>
              <div className="bottom-wrp verify-btn-wrp" onClick={handleVerify}>
                <button className="btn" disabled={isDisabled}>
                  Verify
                  <i className="icon-arrow">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 33 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.9231 7.9575L20.0341 7.8288C20.4416 7.42147 21.0791 7.38449 21.5283 7.71786L21.657 7.82897L29.3017 15.4753C29.7088 15.8825 29.746 16.5195 29.4131 16.9687L29.3021 17.0974L21.6574 24.7527C21.2096 25.2011 20.483 25.2016 20.0346 24.7537C19.627 24.3466 19.5895 23.7092 19.9224 23.2597L20.0335 23.1309L25.7108 17.4438L4.78518 17.4452C4.20426 17.4452 3.72415 17.0136 3.64818 16.4534L3.6377 16.2977C3.6377 15.7169 4.06939 15.2368 4.62947 15.1608L4.78518 15.1502L25.7307 15.1488L20.034 9.4516C19.6267 9.04417 19.5897 8.40669 19.9231 7.9575Z"
                        fill="white"
                      />
                    </svg>
                  </i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
