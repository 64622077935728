import React, { useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import "./DrugSearch.scss";
const DrugSearch = () => {
    return (
        <div className='drug-search-wrap'>
            <div className='drug-search-container'>
                <div className='header-banner'>
                    <div className='container'>
                        <h1>Drugs Search</h1>
                    </div>
                </div>
                <div className="card-headings">
                    <div className="container">
                        <span className="back-heading" onClick={()=>window.history.back()}>
                            <i className="card-back-icon"></i>
                        </span>
                        <span className="card-head-text">Rx Drugs</span>
                    </div>
                </div>
                <div className='drug-content'>
                    <div className='container'>
                        <div className='drug-block'>
                            <div className='drug-head'>
                                <form className='drug-search'>
                                    <div className='search-wrap'>
                                        <input type='text' id='search-block' className='drug-search-input' placeholder='Search your drugs here'></input>
                                        <button type='button' className='search-btn'><i className='search-icon'></i></button>
                                    </div>
                                </form>
                                <Link
              to="/copay-summary"
              className={
                window.location.pathname === "/copay-summary" ? "active-menu" : ""
              }
            >
                                <div className='copayment-wrap'>
                                    <span className='copayment-text'>Copayment Summary</span>
                                    <span className='copayment-icon-wrp'>
                                        <i className='copayment-icon'></i>
                                    </span>
                                </div>
                                </Link>
                            </div>
                            <div className='drug-table'>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Product Label</th>
                                            <th>Therapeutic Class</th>
                                            <th>Formulary Tier</th>
                                            <th>Co-Pay</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>BUSPIRONE TAB 5MG</td>
                                            <td>Anti-Anxiety</td>
                                            <td>Generic</td>
                                            <td>$0.00</td>
                                        </tr>
                                        <tr>
                                            <td>BUSPIRONE TAB 5MG</td>
                                            <td>Anti-Anxiety</td>
                                            <td>Generic</td>
                                            <td>$0.00</td>
                                        </tr>
                                        <tr>
                                            <td>BUSPIRONE TAB 5MG</td>
                                            <td>Anti-Anxiety</td>
                                            <td>Generic</td>
                                            <td>$0.00</td>
                                        </tr>
                                        <tr>
                                            <td>BUSPIRONE TAB 5MG</td>
                                            <td>Anti-Anxiety</td>
                                            <td>Generic</td>
                                            <td>$0.00</td>
                                        </tr>
                                        <tr>
                                            <td>BUSPIRONE TAB 5MG</td>
                                            <td>Anti-Anxiety</td>
                                            <td>Generic</td>
                                            <td>$0.00</td>
                                        </tr>
                                        <tr>
                                            <td>BUSPIRONE TAB 5MG</td>
                                            <td>Anti-Anxiety</td>
                                            <td>Generic</td>
                                            <td>$0.00</td>
                                        </tr>
                                        <tr>
                                            <td>BUSPIRONE TAB 5MG</td>
                                            <td>Anti-Anxiety</td>
                                            <td>Generic</td>
                                            <td>$0.00</td>
                                        </tr>
                                        <tr>
                                            <td>BUSPIRONE TAB 5MG</td>
                                            <td>Anti-Anxiety</td>
                                            <td>Generic</td>
                                            <td>$0.00</td>
                                        </tr>
                                        <tr>
                                            <td>BUSPIRONE TAB 5MG</td>
                                            <td>Anti-Anxiety</td>
                                            <td>Generic</td>
                                            <td>$0.00</td>
                                        </tr>
                                        <tr>
                                            <td>BUSPIRONE TAB 5MG</td>
                                            <td>Anti-Anxiety</td>
                                            <td>Generic</td>
                                            <td>$0.00</td>
                                        </tr>
                                        
                                        
                                    </tbody>
                                </table>
                                <div className='pagination-wrap'>
                                    <div className='entries-wrap'>
                                        <span className='entries-text'>Show Entries</span>
                                        <div className='entries-select'>
                                            <select>
                                                <option value={"10"}>10</option>
                                                <option value={"20"}>20</option>
                                                <option value={"30"}>30</option>
                                                <option value={"40"}>40</option>
                                            </select>
                                            <button type='buttton' className='entries-btn'>
                                                <i className='entries-icon'></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='page-wrap'>
                                        <span className='page-number previous-page'><i className='previous-icon'></i></span>
                                        <span className='page-number active'>1</span>
                                        <span className='page-number'>2</span>
                                        <span className='page-number'>3</span>
                                        <span className='page-number next-page'><i className='next-icon'></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default DrugSearch;