import React, { useState, useEffect } from "react";
import CardData from "../../components/PlanPageData/CardData/CardData";
import InNetwork from "../../components/PlanPageData/InNetwork/InNetwork";
import OutNetwork from "../../components/PlanPageData/OutNetwork/OutNetwork";
import Footer from "../../components/Footer/Footer";
import DependentsSpouse from "../../components/PlanPageData/DependentsSpouse/DependentsSpouse";
import DependentsChild from "../../components/PlanPageData/DependentsChild/DependentsChild";
import "./Plans.scss";
import { cardId, dependent } from "../../store/slices/card";
import { useDispatch, useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { pdfFile } from "../../store/slices/pdf";
import { Link, useNavigate } from "react-router-dom";
import { healthCard } from "../../store/slices/healthCard";

const Plans = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [activePopup, setActivePopup] = useState(false)
  const [activeTabbing, setActiveTabbing] = useState(null)
  const [tabData, setTabData] = useState(null);
  const cardDetails = useSelector((state) => state.cardSlice?.cards);
  const depend = useSelector((state) => state.cardSlice?.dependents);
  const pdfDetails = useSelector((state) => state.pdfSlice?.filesPdf);

  /**Function for showing ID card details and psf files */
  useEffect(() => {
    setIsLoading(true);
    dispatch(pdfFile(setIsLoading));
    dispatch(cardId(setIsLoading));
    dispatch(dependent(setIsLoading));
    dispatch(healthCard(setIsLoading))
  }, []);

  useEffect(()=>{
    if(depend?.dependent_details){
      let tempTab = false;
      let tempId = null
      depend?.dependent_sequence?.forEach((item)=>{
        if(depend?.dependent_details?.[item]?.length>0){
          if(tempId === null){
            tempId = item
          }
          tempTab = true;
        }
      })
      setTabData(tempId)
      setActiveTabbing(tempTab);
    }
  },[depend])

  const handleBack = () => {
    navigate(-1);
  };


  return (
    <>
      {isLoading ? (
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#240151"
          wrapperClass="loading-spin"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          // wrapperClass=""
        />
      ) : (
        <div className="plan-wrap">
          <div className="card-headings">
            <div className="container">
              <span className="back-heading" onClick={()=>window.history.back()}>
                <i className="card-back-icon"></i>
              </span>
              <span className="card-head-text">ID Card</span>
              <span className="back-heading back-dots-wrap" onClick={()=>setActivePopup(prev=>!prev)}>
                <span className="heading-dot"></span>
                <span className="heading-dot"></span>
                <span className="heading-dot"></span>
              </span>
              {activePopup && <div className="header-popup">
                <h2>Downloads</h2>
                {pdfDetails?.documents?.length > 0 ? (
                pdfDetails?.documents?.map((item, index) => {
                  return (
                    <a  key={index} href={item?.file_url} target="_blank" className="popup-text">
                    <i className="pdf-icon"/>
                    {item?.file_name}
                  </a>
                  );
                })
              ) : (
                <div className="no-docs-text mob">No documents</div>
              )}
              </div>}
              <button
                type="button"
                className="medium-card-back"
                onClick={handleBack}
              >
                <i className="medium-card-icon"></i>
                Back
              </button>
            </div>
          </div>
          <div className="container">
            <div className="plans-massege-wrap">
              <CardData />
              <div className="network-wrps">
                <div className="data-network">
                    <h3>Dependents</h3>
                    <div className="tab-wrp">
                      <div className="tab-head tabbing-wrapping">
                        {
                          depend?.dependent_sequence?.map((item)=>{
                            return depend?.dependent_details?.[item]?.length>0 ? <button
                              onClick={() => setTabData(item)}
                              key={item}
                              className={`tab-btn ${
                                tabData === item ? "active" : ""
                              }`}
                            >
                              {item}
                            </button>:null
                          })
                        }
                      </div>
                      {
                        depend?.dependent_sequence?.map((item)=>{
                          return <div key={item} className={`out ${tabData === item ? "active" : ""}`}>
                            <DependentsChild dependent={depend?.dependent_details?.[item]} />
                          </div>
                        })
                      }
                      {
                        activeTabbing === false && <div className="no-data-new">No data found</div>
                      }
                    </div>
                  </div>
                <div className="network-provider-wrap">
                  <h2>Plan Name</h2>
                  <p>{cardDetails?.plan_name}</p>
                  <h2>Provider Networks</h2>
                  {
                          cardDetails?.provider_networks_details?.map((item, index)=>{
                            return item?.network_url ? <Link to={item?.network_url} target={"_blank"} className="logo-wrap" key={index}>
                            <div className="logo-head">
                              <img
                                src={
                                  item?.image_url
                                }
                                alt="logo"
                              ></img>
                            </div>
                            <div className="logo-body">
                              {
                                item?.networks_name
                              }
                            </div>
                          </Link>:
                          <div className="logo-wrap" key={index}>
                          <div className="logo-head">
                            <img
                              src={
                                item?.image_url
                              }
                              alt="logo"
                            ></img>
                          </div>
                          <div className="logo-body">
                            {
                              item?.networks_name
                            }
                          </div>
                        </div>
                          })
                        }
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Plans;
