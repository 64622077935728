import React, { useEffect, useState } from "react";

import "../MemberScreen/MemberScreen.scss";
import Footer from "../../components/Footer/Footer";
import axiosInstance from "../../api/axios";
import endPoints from "../../api/endpoints";
import { decryption } from "../../Encrypt";
import { ThreeDots } from "react-loader-spinner";
import HeaderCms from "../../components/Header/HeaderCms";

const AboutUs = () => {
  const [content, setContent] = useState(null);
  const [isLoader, setIsLoader] = useState(true);
  useEffect(() => {
    axiosInstance
      .get(endPoints.genericResources)
      .then(async (response) => {
        setIsLoader(false);
        let decrypt = await decryption(response?.data?.response);
        let parsedData = JSON.parse(decrypt);
        setContent(parsedData?.about_us?.content);
      })
      .catch((error) => {
        setIsLoader(false);
      });
  }, []);

  if (isLoader) {
    return (
      <ThreeDots
        visible={true}
        height="80"
        width="80"
        color="#240151"
        wrapperClass="loading-spin"
        radius="9"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        // wrapperClass=""
      />
    );
  }

  return (
    <>
      {!localStorage.getItem("accessToken") && <HeaderCms />}
      <div className="member-screen cms-page">
        <div className="header-banner">
          <div className="container">
            <h1>About Us</h1>
          </div>
        </div>
        <div className="card-headings">
          <div className="container">
            {localStorage.getItem("accessToken") && <span
              className="back-heading"
              onClick={() => window.history.back()}
            >
              <i className="card-back-icon"></i>
            </span>}
            <span className="card-head-text">About Us</span>
          </div>
        </div>
        <div className="container">
          <div className="member-content-card">
            <div
              className="member-content cms-page-content"
              dangerouslySetInnerHTML={{ __html: content }}
            ></div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
