import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance, axiosInstanceAuth } from "../../api/axios";
import endPoints from "../../api/endpoints";
import { encryption, decryption } from "../../Encrypt";
import { notify } from "../../NotifyToast";
import { refreshToken } from "./refrshToken";

const initialState = { state: "check" };

/**API for displaying benefits list */
export const benefitsList = createAsyncThunk(
  "benefitsList",
  async ({ payload, setIsLoading }, thunkApi) => {
    const response = axiosInstance
      .get(
        endPoints.benifits +
          "?pageNumber=" +
          payload?.pageNumber +
          "&recordsPerPage=" +
          payload?.recordsPerPage
      )
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          setIsLoading(false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
        let decrypt = await decryption(response?.data?.response);
        let parsedData = JSON.parse(decrypt);
        if (response?.data?.code === 200) {
          setIsLoading(false);
          return thunkApi.fulfillWithValue({
            totalRecords: response?.data?.totalRecords,
            parsedData,
          });
        }
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          setIsLoading(false);
          // localStorage.removeItem("accessToken");
          // localStorage.removeItem("refreshToken");
          // localStorage.removeItem("idToken");
          // localStorage.removeItem("expiresIn");
          // sessionStorage.clear();
          // window.location = "/";
          thunkApi.dispatch(refreshToken());
        }
        setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

/**API for displaying Co-payment details */
export const copayment = createAsyncThunk("copayment", async (_, thunkApi) => {
  const response = axiosInstance
    .get(endPoints.copayment)
    .then(async (response) => {
      if (response?.data?.code === 401 || response?.data?.code === 403) {
        // return;
        localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
      }
      let decrypt = await decryption(response?.data?.response);
      let parsedData = JSON.parse(decrypt);
      if (response?.data?.code === 200) {
        //   setIsLoading(false);
        return thunkApi.fulfillWithValue(parsedData);
      }
    })
    .catch((error) => {

      if (
        error?.response?.status === 401 ||
        error?.response?.status === 403
      ) {
        //   setIsLoading(false);
        // localStorage.removeItem("accessToken");
        //   localStorage.removeItem("refreshToken");
        //   localStorage.removeItem("idToken");
        //   localStorage.removeItem("expiresIn");
        //   sessionStorage.clear();
        //   window.location = "/";
        thunkApi.dispatch(refreshToken());
      }
      // setIsLoading(false);
      return thunkApi.rejectWithValue(error);
    });
  return response;
});

/**APi for benefit list search  */
export const benefitsSearch = createAsyncThunk(
  "benefitsSearch",
  async ({ payload, setIsLoading, apiPage, recordsPerPage }, thunkApi) => {
    const response = axiosInstance
      .get(endPoints.benefitSearch, {
        params: {
          product_label: payload,
          pageNumber: apiPage,
          recordsPerPage: recordsPerPage??10,
        },
      })
      .then(async (response) => {
        if (response?.data?.code === 401 || response?.data?.code === 403) {
          setIsLoading(false);
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("idToken");
          localStorage.removeItem("expiresIn");
          localStorage.removeItem("new_token");
          sessionStorage.clear();
          window.location = "/";
        }
        let decrypt = await decryption(response?.data?.response);
        let parsedData = JSON.parse(decrypt);
        if (response?.data?.code === 200) {
          setIsLoading(false);
          return thunkApi.fulfillWithValue({
            totalRecords: response?.data?.totalRecords,
            parsedData,
          });
        }
      })
      .catch((error) => {

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          setIsLoading(false);
          // localStorage.removeItem("accessToken");
          // localStorage.removeItem("refreshToken");
          // localStorage.removeItem("idToken");
          // localStorage.removeItem("expiresIn");
          // sessionStorage.clear();
          // window.location = "/";
          thunkApi.dispatch(refreshToken());
        }
        setIsLoading(false);
        return thunkApi.rejectWithValue(error);
      });
    return response;
  }
);

const benefitSlice = createSlice({
  name: "benefitSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(benefitsList.pending, (state, action) => {
    });
    builder.addCase(benefitsList.fulfilled, (state, action) => {
      state.listBenefit = action.payload;
    });
    builder.addCase(benefitsList.rejected, (state, action) => {
    });
    builder.addCase(copayment.pending, (state, action) => {
    });
    builder.addCase(copayment.fulfilled, (state, action) => {
      state.summaryCo = action.payload;
    });
    builder.addCase(copayment.rejected, (state, action) => {
    });
    builder.addCase(benefitsSearch.pending, (state, action) => {
    });
    builder.addCase(benefitsSearch.fulfilled, (state, action) => {
      state.searchResult = action.payload;
    });
    builder.addCase(benefitsSearch.rejected, (state, action) => {
    });
  },
});

export default benefitSlice.reducer;
