import React, { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { encryptionObj } from "../../../Encrypt";
import { accntDelete } from "../../../store/slices/profile";
import "./Popup.scss";

export const ConfirmDelete = ({ setDeleteConfirm, setDeleteAccount }) => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [resetPass, setResetPass] = useState("");
  const [passValid, setPassValid] = useState(false);
  const [resetValid, setResetValid] = useState(false);
  const [isPasswordShowing, setIsPasswordShowing] = useState(false);
  const [isCnPasswordShowing, setCnIsPasswordShowing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const togglePassword = () => {
    setIsPasswordShowing((prev) => !prev);
  };

  const toggleCnPassword = () => {
    setCnIsPasswordShowing((prev) => !prev);
  };

  const accountDelete = async (e) => {
    e.preventDefault();
    const passRegex =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    if (password?.length === 0 || !passRegex.test(password)) {
      setPassValid(true);
    } else if (resetPass?.length === 0 || resetPass !== password) {
      setResetValid(true);
    } else {
      let value = {
        current_password: password,
        confirm_password: resetPass,
      };
      let finalData = await encryptionObj(value);
      let payload = {
        data: finalData,
      };
      setIsLoading(true);
      dispatch(accntDelete({ payload, setIsLoading, setDeleteConfirm }));
    }
  };

  return (
    <>
      {isLoading && (
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="#240151"
          wrapperClass="loading-spin"
          radius="9"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
        />
      )}
      <div className="popup confirm-delete">
        <div className="popup-wrap">
          <div className="poup-content">
            <button
              className="close-popup"
              onClick={() => setDeleteConfirm(false)}
            ></button>
            <div className="auth-fail">
              <h3>Account Deletion Confirmation</h3>
              {/* <p>Please Enter Your Password</p> */}
              <div className="frm-cntrl-nw">
                <div className="form-block pass-wrp">
                  <label className="form-label">Enter Current Password</label>
                  <div className="input-block">
                    <input
                      type={isPasswordShowing ? "text" : "password"}
                      class="form-control"
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setPassValid(false);
                      }}
                    />
                    <i
                      className={`icon-eye ${
                        isPasswordShowing ? "active" : ""
                      }`}
                      onClick={togglePassword}
                    ></i>
                  </div>
                  {passValid ? (
                    <div className="error-wrap">
                      <div className="error lg-msz">
                        1. Ensure that password must contain 8 characters, both
                        upper and lower case letters
                      </div>
                      <div className="error lg-msz">
                        2. Include symbols like @ , _ ,# ,* and numbers
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="form-block pass-wrp">
                  <label className="form-label">
                    Re-Enter Current Password
                  </label>
                  <div className="input-block">
                    <input
                      type={isCnPasswordShowing ? "text" : "password"}
                      class="form-control"
                      onChange={(e) => {
                        setResetPass(e.target.value);
                        setResetValid(false);
                      }}
                    />
                    <i
                      className={`icon-eye ${
                        isCnPasswordShowing ? "active" : ""
                      }`}
                      onClick={toggleCnPassword}
                    ></i>
                  </div>
                  {resetValid ? (
                    <div className="error-wrap">
                      <div className="error lg-msz">
                        Password must be same
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="btn-wrap">
                  <button
                    class="btn yes-btn"
                    type="button"
                    onClick={() => setDeleteConfirm(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn "
                    onClick={accountDelete}
                    type="button"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
