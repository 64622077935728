import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./SecuritySeting.scss";

const SecuritySeting = ({setTabData})=>{
    return(
        <div className="security-setting-wrap">
            <div className="card-headings">
                    <div className="container">
                        <span className="back-heading" onClick={()=>setTabData(null)}>
                            <i className="card-back-icon"></i>
                        </span>
                        <span className="card-head-text">Security Settings</span>
                    </div>
            </div>
            <div className="security-setting-content">
                <div className="container">
                <button className="setting-logout" type="button">
                <i className="icon-account"></i>Change Password<i className="icon-arrow"></i></button>
                <div className="security-text-wrap">
                    <div className="security-text-block">
                        <span className="face-id-text">Face ID & PIN</span>
                        <div className="security-radio-wrap">
                        <div className="security-radio">
                            <label className="security-switch">
                                <input type="checkbox" ></input>
                                <span className="slider round"></span>
                            </label>
                        </div>
                    </div>
                    </div>
                    <div className="security-text">
                    <p>Secure access to IHP using passcode or Fingerprint you use to unlock this phone</p>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
};
export default SecuritySeting;